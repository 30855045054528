.content {
    display: flex;
    flex-direction: column;

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 24px;

        .headerText {
            padding-top: 13px;
            padding-bottom: 2px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;

        .button {
            &:first-of-type {
                margin-bottom: 14px;
                background-color: #ab2219;
            }
        }
    }
}
