@import "../../../../css/fonts";

.musterArticle {
    background-color: var(--dust-100);
    overflow-y: scroll;
    padding: 24px;
    margin: 8px;
    margin-right: 0px;
    height: calc(100vh - 140px);
    width: calc(100% - 16px);

    .article {
        margin-top: 16px;
    }

    .image {
        aspect-ratio: 228/115;
        background-position: center;
        background-size: cover;
        width: 100%;
    }
}
