@import "../buttons.module.scss";
@import "../../../css/fonts";

.button-tertiary {
    @include font-rubik(16px, var(--midnight-100), 600);
    background-color: var(--white);
    border: none;
    border-radius: 32px;
    padding: 12px;
}
