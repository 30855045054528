.supportSquadDetailHeaderControls {
    .options {
        display: flex;
        gap: 16px;
    }

    .skeleton {
        width: 100px;
        height: 16px;
        transform: translate(0);
    }
}
