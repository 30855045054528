@import "../../../../css/fonts";

.muster-recruit-invite-modal {
    max-height: 1000px;
}

.muster-recruit-invite-modal-dialog {
    max-height: 755px;
    overflow: hidden;

    &--photo-edit {
        max-height: 1000px;
        height: 90vh;
        width: 90vw;
    }
}
