@import "../../../../../../../../css/fonts";

.supportSquadDetailHeaderTitle {
    display: flex;
    flex-direction: column;

    .skeleton {
        transform: translate(0);

        &:first-child {
            margin-bottom: 16px;
        }
    }

    .title {
        @include font-rubik(24px, var(--midnight-100), false);
    }
}
