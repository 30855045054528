.musterChecklistCollection {
    &:first-child {
        margin-bottom: 24px;
    }

    .body {
        padding-top: 16px;

        .accordion {
            margin-bottom: 16px;

            &:last-of-type {
                margin-bottom: 0px;
            }

            .button {
                margin-bottom: 16px;
                padding: 11px 24px 11px 28px;
                float: right;
            }
        }
    }
}
