@import "../../../css/fonts";

.button-pill {
    @include font-rubik(14px, var(--gray-darkest), 600);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
    border: 1px solid var(--gray-neutral);
    border-radius: 50px;
    padding: 12px 0;
    background-color: transparent;
    filter: drop-shadow(0px 1px 6px rgba(0, 0, 0, 0.1));

    &--active {
        border: 2px solid var(--blue-dark-background);
        box-shadow: 0px 1px 6px rgba(52, 81, 104, 0.33);
        filter: none;
    }
}
