@import "../../css/variables";

.referrals {
    min-height: calc(100vh - 210px);
    padding: 32px;
    position: relative;
    width: 100%;

    @include phone {
        padding: 24px 24px 0 24px;
    }
}
