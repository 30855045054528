.referralsRedeemCodeModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
    }
}
