@import "../../css/fonts";

.letterPreview {
    position: relative;
    display: flex;
    gap: 24px;
    width: 100%;
    cursor: pointer;

    .content {
        display: flex;
        flex-direction: column;
        flex: 1;
    }

    .skeleton {
        border-radius: 4px;

        &--statusBar {
            margin: 16px 0;
        }
    }
}
