@import "../../../../css/fonts";
@import "../../../../css/variables";

.dashboard__promotions {
    margin-bottom: 24px;

    .carousel {
        .wrapper {
            width: 100%;
        }
    }

    .promotion {
        width: 100%;
        border-radius: 8px;
        cursor: pointer;

        &:not(:last-child) {
            margin-right: 24px;
        }
    }
}
