.buttonPhotoUpload {
    align-items: center;
    background-color: transparent;
    border: 0.5px dashed var(--pacific-blue-100);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    height: 37px;
    min-width: 55px;
    padding: 3px;
    position: relative;
    overflow: hidden;
    width: 100%;

    .imagePreview {
        border-radius: 5px;
        height: 31px;
        margin-right: 9px;
        width: 31px;
    }

    .icon {
        color: var(--pacific-blue-100);
        margin-right: 9px;
    }

    .text {
        color: var(--pacific-blue-100);
    }

    &--filled {
        background-color: var(--dust-100);
        border: 1px solid #345168;
        justify-content: flex-start;
    }

    &--locked {
        border: 0.5px dashed #a2a2a2;

        .text {
            color: #a2a2a2;
        }
    }

    &--square {
        align-items: center;
        background-color: transparent;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100px;
        min-width: unset;
        padding: 0px;
        width: 100px;

        .icon {
            margin-right: unset;
        }

        .imagePreview {
            border-radius: 10px !important;
            height: 100%;
            width: 100%;
            margin-right: 0px;
        }
    }

    &--shadow {
        box-shadow: 0px 5px 11px rgba(52, 81, 104, 0.12);
    }
}
