@import "../../fonts";
@import "../../variables";

.letters__modal {
    &__no-credits {
        &__body {
            display: flex;
            flex-direction: column;
            align-items: center;

            & > *:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        &__header {
            @include font-rubik(20px, var(--midnight-100), 700);
        }

        &__text {
            @include font-rubik(16px, var(--midnight-100), 300);
        }
    }
}
