.card {
    background-color: #c9d0d1;
    display: flex;
    flex-direction: column;
    padding: 32px 20px 0px 20px;
    border-radius: 16px;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    width: 250px;

    &:last-of-type {
        background-color: #d0cabe;
    }

    .benefits {
        height: 96px;
        margin-bottom: 16px;

        .benefit {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 7px;

            .text {
                height: 25px;
            }
        }
    }

    .button {
        margin-bottom: 15px;
        padding: 12px;
        width: 100%;
        white-space: nowrap;
    }

    .checkmark {
        margin-right: 8px;
    }

    .description {
        border-bottom: 1px solid #dedede;
        margin-bottom: 14px;
        padding-bottom: 24px;
        text-align: center;
    }

    .title {
        text-align: center;
    }
}
