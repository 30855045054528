@import "../../fonts";
@import "../../variables";

.button {
    @include font-rubik(16px, false, 600);
    border-radius: 32px;
    padding: 8px 24px;
    letter-spacing: 0;
    text-align: center;
    cursor: pointer;
    transition: all 0.15s ease-out;
    text-decoration: none !important;

    span {
        position: relative;
        top: 1px;
    }

    &__container {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;

        &.force-right {
            justify-content: flex-end;
        }

        & > .button:not(:first-child) {
            margin-left: 24px;
        }

        &--space-between,
        &--with-text {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;

            &__left,
            &-buttons {
                display: flex;
                width: 50%;

                &.force-right {
                    justify-content: flex-end;
                }

                & > .button:not(:first-child) {
                    margin-left: 24px;
                }
            }

            &__right,
            &-text {
                display: flex;
                flex-direction: column;
                font-size: 18px;
            }
        }
    }

    &--deemphasized {
        font-weight: 400;
    }

    &--invisible {
        @include font-rubik(false, var(--blue-darkerer), false);
        border: none;
        background-color: transparent;
        color: var(--midnight-100);

        &.disabled {
            color: var(--gray-neutral);
            pointer-events: none;
        }
    }

    &--primary {
        @include font-rubik(false, var(--white), false);
        border: none;
        background-color: var(--blue-darkerer);
        color: var(--white);

        .forward-arrow-icon {
            margin-left: 16px;
        }

        &:hover {
            background-color: var(--blue-dark);
        }

        &.disabled {
            color: var(--gray-darkerer);
            background-color: var(--gray-dark);
            pointer-events: none;
        }

        a {
            text-decoration: none !important;
            color: white;
        }

        &-warning {
            background-color: var(--error-100);

            &:hover {
                background-color: var(--error-100);
            }
        }
    }

    &--rounded {
        border-radius: 32px;
    }

    &--secondary {
        border: 2px solid var(--blue-darkerer);
        background-color: transparent;
        color: var(--blue-darkerer);

        &[disabled] {
            border: 2px solid var(--gray-light) !important;
            color: var(--gray-light) !important;
            cursor: not-allowed !important;
        }

        &--filled {
            background-color: var(--blue-darkerer);
            color: var(--white);
        }
    }

    &--size {
        &-big {
            height: 64px;
            padding: 16px 0;
        }

        &-short {
            padding: 8px 24px;

            &-wide {
                padding: 8px 48px;
            }
        }

        &-small {
            height: 45px;
            width: 45px;
            border-radius: 6px;
            padding: 0 0 0 9px;
        }
    }

    &--transparent {
        border: 1px solid var(--white);
        background-color: rgba(transparent, 0);
        color: var(--white);
    }
}
