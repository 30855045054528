@import "../../../../css/fonts";

.cartHelp {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-bottom: 16px;
    font-size: 14px;

    .link {
        @include font-rubik(false, var(--pacific-blue-100), 700);
        text-decoration: underline;
        cursor: pointer;
    }
}
