@import "../../../../../css/fonts";

.sign-up-phone {
    .disclaimer {
        @include font-rubik(10px, var(--midnight-100), 300);
        text-align: center;
    }

    .form {
        .button {
            margin-bottom: 8px;
        }

        .input {
            margin-bottom: 24px;
        }
    }

    .header {
        margin-bottom: 80px !important;
    }
}
