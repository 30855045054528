.wallet-gift-cards-purchase {
    .braintree-sheet__header {
        display: none;
    }

    .braintree-sheet__container.braintree-sheet--active {
        background-color: var(--dust-100);
    }

    .braintree-card.braintree-form.braintree-sheet {
        background-color: transparent;
    }
}
