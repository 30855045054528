@import "variables";

.container > .navbar-collapse {
    margin-left: 10px;
    margin-right: 10px;
}

.jumbotron {
    padding-top: 20%;
    background: var(--white);
    min-height: 80%;
}

.navbar-brand {
    margin-left: 1px !important;
}

.carousel-indicators li {
    visibility: hidden;
}

.row {
    margin-left: 0;
    margin-right: 0;
}

.error-logo {
    min-height: 300px;
    margin: 0 auto;
}

.form-control,
.input-lg {
    height: 50px;
    border-radius: 0;
    border-color: rgba(153, 157, 167, 0.5) !important;
}
.form-control,
select {
    height: 50px;
    border-radius: 0;
    border-color: rgba(153, 157, 167, 0.5) !important;
}

.carousel-control.right,
.carousel-control.left {
    background-image: none;
}

.dropdown-menu {
    border-radius: 0;
}

.dropdown-menu > li > a {
    font-size: 18px;
    font-weight: 300;
}

.dropdown-menu > li > a strong {
    font-weight: bold;
    font-size: 18px;
}

.has-warning .form-control {
    border-color: rgba(153, 157, 167, 0.5);
}

.has-success .form-control {
    border-color: rgba(153, 157, 167, 0.5);
}

.is-focused:not(.is-open) > .Select-control {
    border: none;
    box-shadow: none;
}

#split-button-pull-left {
    background-color: var(--white);
    color: var(--gray-darkerer);
}

.tab-content {
    position: relative;
    padding-top: 15px;

    &:before,
    &:after {
        position: absolute;
        top: -1px;
        left: -15px;
        width: 15px;
        height: 1px;
        background-color: rgb(221, 221, 221);
        content: "";
    }

    &:after {
        left: 100%;
    }
}

/* Column mods */

.col-no-padding {
    padding: 0;
}
