@import "../../../../css/fonts";

.musterRecruitDetailModal {
    border-radius: 10px;
    display: flex;
    flex-direction: column;

    .body {
        padding: 0 22px;
        padding-top: 10px;

        .modal-content {
            height: 720px;
        }
    }

    .details {
        margin-bottom: 150px;

        .lineItem {
            display: flex;
            justify-content: space-between;
            padding: 16px 0;

            .icon {
                height: 20px;
                margin-right: 14px;
                width: 20px;

                &.letterIcon {
                    height: 15px;
                }
            }

            .label {
                align-items: center;
                display: flex;
                justify-content: center;
            }

            .label,
            .value {
                margin-bottom: 0px;
            }

            &:not(:last-of-type) {
                border-bottom: 1px solid var(--gray-dark-background);
            }
        }
    }

    .header {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 40px;

        .avatar {
            height: 100px;
            font-size: 36px;
            margin-bottom: 10px;
            width: 100px;
        }

        .cta {
            margin-top: 16px;
            width: 100%;
        }
    }
}
