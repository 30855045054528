.cartConfirmation {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 0 16px;

    .description,
    .header,
    .orderNumber,
    .orderPlaced {
        width: 100%;
    }

    .header,
    .orderNumber,
    .orderPlaced {
        margin-bottom: 16px;
    }

    .description {
        margin-bottom: 24px;
    }

    .header {
        display: flex;
        align-items: center;
        font-size: 22px;

        span {
            margin-bottom: 0;
        }

        .icon {
            color: var(--success-100);
            margin-right: 8px;
        }
    }

    .orderNumber,
    .orderPlaced {
        display: flex;
        flex-direction: column;
    }
}
