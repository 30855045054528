.supportSquadSettingsModalMembers {
    .userList {
        margin-bottom: 0;

        .items > * {
            &:after {
                background-color: transparent;
                content: "";
            }
        }
    }
}
