@import "../../../../css/responsive";

.letterPreviewImage {
    aspect-ratio: 1;
    position: relative;
    background-color: var(--gray-light);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    position: relative;
    max-height: 150px;
    max-width: 150px;
    border-radius: 4px;

    @include responsive($max-width: $desktop-small-max-width) {
        display: none;
    }

    .icon {
        position: absolute;
        right: 5px;
        top: 5px;
    }

    &--empty {
        background-size: 30% 30%;
    }
}
