@import "../../../../css/fonts";

.cartItems {
    margin-bottom: 16px;

    .header {
        @include font-rubik(14px, var(--midnight-80), 500);
        margin-bottom: 8px;
    }
}
