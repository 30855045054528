@import "../../css/fonts";

.slider {
    position: relative;

    .arrows {
        display: flex;
        justify-content: space-between;
        position: absolute;
        width: 100%;

        .arrow {
            cursor: pointer;
            position: absolute;
            transition: opacity 0.25s ease-out;

            &.left {
                left: 0px;
            }

            &.right {
                right: 0px;
            }

            &--disabled {
                opacity: 0.2;
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        text-align: center;

        .title {
            @include font-rubik(20px, var(--midnight-100), false);
        }

        .subtitle {
            @include font-rubik(16px, var(--midnight-100), false);
        }
    }

    .image {
        aspect-ratio: 3.5;
        border-radius: 16px;
        background-size: cover;
        background-position: center;
        margin-bottom: 24px;
        transition: background-image 0.2s ease-in-out;
        width: 100%;
    }

    .markers {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 40px;

        .marker {
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: var(--gray-dark-background);
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 16px;
            }

            &--active {
                width: 12px;
                height: 12px;
                background-color: var(--gray-darkest);
            }
        }
    }
}
