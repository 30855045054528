@import "../../css/fonts";

.addressFormatter {
    padding-top: 24px;

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .confirmation {
        .paragraph {
            color: var(--teal-darker) !important;
            margin-bottom: 0 !important;
        }
    }

    .error {
        width: 100%;
        color: var(--error-100);
    }
}
