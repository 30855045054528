.cartMain {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    height: 100%;

    .buttons {
        .button {
            margin-bottom: 16px;
            width: 100%;
        }
    }
}
