.progressBarCircle {
    height: 36px;
    width: 36px;
    border-radius: 90px;

    &--complete {
        border: 2px solid var(--success-100);
        background: var(--success-100) !important;
    }

    &--empty {
        border: 1px solid #d0cbbf;
        background: var(--dust-100) !important;
    }

    .successIcon {
        height: 36px;
        width: 36px;
        margin-left: -3px;
        margin-top: -3px;
    }
}
