@import "../../../css/fonts";

.modal-prompt {
    width: 294px;

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;

            .button {
                align-self: center;
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }

        .icon {
            margin-bottom: 16px;
        }

        .header,
        .text {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
        }

        .header {
            & > * {
                @include font-rubik(20px, var(--midnight-100), 700);
                margin-bottom: 16px;
            }
        }

        .text {
            & > * {
                @include font-rubik(16px, var(--midnight-100), 300);
                margin-bottom: 24px;
            }
        }
    }
}
