@import "../../../../../../css/fonts.scss";

.letterPreviewStatusBarNode {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 16px;

    &--empty {
        color: var(--midnight-60);

        .icon {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 29px;

            & > div {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--midnight-60);
                transform: translateY(-50%);
            }
        }
    }

    &--error {
        color: var(--error-100);
    }

    &--info {
        color: var(--informative-100);
    }

    &--success {
        color: var(--success-100);
    }

    .text {
        @include font-rubik(10px, false, 700);
        position: absolute;
        top: 100%;
        transform: translateY(-150%);
        white-space: nowrap;
    }
}
