@import "../../fonts";
@import "../../variables";

.progress-bar {
    &-container {
        width: 100%;
    }

    &-display {
        &-container {
            width: 100%;
            height: 10px;
            margin-bottom: 10px;
            border: 1px solid var(--gray-lighter);
            border-radius: 5px;
        }

        &-filled {
            height: 100%;
            border-radius: 5px;
            background-color: var(--blue-neutral);
            transition: all 0.5s ease-out;
        }
    }

    &-preface {
        &-container {
            margin-bottom: 24px;
            color: var(--gray-darkerer);
        }
    }

    &-states {
        @include font-rubik(false, false, 700);
        display: flex;
        justify-content: space-around;
    }
}
