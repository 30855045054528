@import "../../css/fonts";
@import "../../css/variables";

.panel {
    background-color: var(--dust-100);
    border-radius: 8px;
    height: auto;
    margin-bottom: 24px;
    padding: 24px;

    .input,
    .select {
        background-color: transparent;
        border-radius: 4px;
    }

    .form {
        margin-bottom: 0;

        input[type="mask"] {
            border: 1px solid var(--gray-darkerer);
        }
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        padding-bottom: 16px;

        & > *:first-child {
            margin-right: 8px;
        }

        &--space-between {
            justify-content: space-between;

            & > *:first-child {
                display: flex;
                align-items: center;
            }
        }

        &__button {
            @include font-rubik(16px, var(--mission-orange-100), 400);
            background-color: var(--dust-100);
            border: none;
            cursor: pointer;
        }

        &__chevron {
            display: flex;
            align-items: center;

            &__icon {
                transform: translate(0, -1px);
            }

            &__text {
                @include font-rubik(14px, var(--gray-darkest), 500);
                margin-right: 16px;
            }
        }

        &__chip {
            @include font-rubik(12px, var(--white), bold);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            margin-left: 5px;
            border-radius: 2px;
            padding: 2px 5px;
            background-color: var(--mission-orange-100);

            span {
                transform: translateY(1px);
            }
        }

        &__link {
            @include font-rubik(16px, var(--mission-orange-100), 400);
            cursor: pointer;
        }

        &__text {
            @include font-rubik(16px, var(--gray-darkest), bold);
            display: inline-block;
        }
    }

    &__paragraph {
        @include font-rubik(16px, var(--gray-darkest));
        margin-bottom: 0;
    }

    .textarea {
        @include font-rubik(16px, var(--midnight-100), 400);
        background-color: #f3f2ef;
        border: 1px solid var(--gray-lighter);
        padding: 24px;
        width: 100%;
    }

    &--theme-blue {
        background-color: var(--blue-darkerer);
        color: var(--white);

        .button {
            &--primary {
                background-color: var(--mission-orange-100);

                &:hover {
                    background-color: var(--orange-light);
                }
            }
        }

        .panel__header {
            &__text {
                color: var(--white);
            }
        }
    }

    &--theme-white {
        background-color: var(--white);
    }
}
