@import "../../../../../../../../css/fonts";

.supportSquadSettingsModalCreateName {
    .buttons {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    .card {
        border-radius: 8px;

        .body {
            display: flex;
            flex-direction: column;
            padding: 16px;
            background-color: var(--white);
            border-radius: 0 0 8px 8px;
            margin-bottom: 24px;

            .createdBy {
                display: flex;
                flex-direction: column;

                .label {
                    @include font-rubik(14px, var(--midnight-60), 600);
                }

                .name {
                    font-size: 18px;
                }
            }

            .input {
                margin-bottom: 16px;
            }
        }

        .image {
            aspect-ratio: 1 / 1;
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            padding: 16px;
            border-radius: 8px 8px 0 0;
            background-color: var(--white);
            background-size: cover;

            .input {
                visibility: hidden;
            }

            .button {
                background-color: var(--midnight-100);
                border-color: var(--midnight-100);
                padding: 4px 12px;
            }
        }
    }
}
