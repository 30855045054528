.memoryBookIneligible {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    padding: 24px;
    width: 50%;

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
        text-align: center;

        & > *:not(:last-child) {
            margin-bottom: 16px;
        }
    }
}
