@import "../../css/fonts";

.tabs {
    display: flex;
    flex-direction: column;
    width: 100%;

    .highlight {
        position: relative;
        width: 100%;
        height: 2px;
        background-color: transparent;

        .bar {
            position: relative;
            left: 0;
            width: 50%;
            height: 100%;
            background-color: var(--blue-darkerer);
            transition: left 0.25s ease-out;

            &--single {
                width: 100%;
            }
        }
    }

    .items {
        display: flex;
        width: 100%;

        .tab {
            @include font-rubik(14px, var(--gray-darker), 400);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50%;
            padding: 12px 0;
            cursor: pointer;

            &--active {
                @include font-rubik(14px, var(--blue-darkerer), 500);
            }

            &--single {
                width: 100%;
            }
        }
    }
}
