@import "../../../../css/fonts";
@import "../../../../css/variables";

.navBarItem {
    @include font-rubik(16px, var(--gray-dark), 500);
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px 0px 16px 42px;
    cursor: pointer;

    &:hover,
    &--selected {
        background-color: #cdd2d4;
        color: var(--blue-dark-background) !important;
        text-decoration: none !important;

        .icon {
            position: relative !important;
            color: var(--blue-dark-background) !important;
        }

        .iconLogout {
            fill: var(--blue-dark-background) !important;
        }

        .text {
            color: var(--blue-dark-background) !important;
            text-decoration: none !important;
        }
    }

    &:hover:not(&--selected) {
        path {
            stroke: var(--blue-dark-background) !important;
        }

        .iconLogout {
            path {
                stroke: none !important;
            }
        }
    }

    &--selected {
        .text {
            font-weight: 600;
        }
    }

    &--subnav {
        background-color: var(--dust-100) !important;
    }

    @include tablet {
        padding: 16px;
    }

    @include phone {
        padding: 16px;
    }

    .content {
        position: relative;
        display: flex;
        width: 100%;

        &--unread {
            &:before {
                background-color: var(--orange-accent);
                border-radius: 50%;
                content: "";
                height: 8px;
                left: calc(100% - 32px);
                position: absolute;
                top: 50%;
                width: 8px;
                transform: translate(0, -50%);
            }
        }

        .label {
            display: flex;
            align-items: center;
        }

        &--subnav {
            flex-direction: column;
            width: 90%;
        }
    }

    .icon {
        position: relative;
    }

    .iconLogout {
        fill: #b3b3b3 !important;
    }

    .text {
        text-decoration: none !important;
        color: var(--gray-dark);
        margin-left: 14px;

        &:hover {
            color: var(--mission-orange-100);
        }
    }
}

.subnavigation {
    display: flex;
    flex-direction: column;
    margin-left: 60px;
    border-left: 1px solid #dedede;
    padding: 0px 12px;

    .item {
        @include font-rubik(16px, var(--gray-dark), 500);
        position: relative;
        margin-top: 8px;
        border-radius: 5px;
        padding: 10px;
        text-decoration: none !important;

        &:hover,
        &--selected {
            background-color: rgba(52, 81, 104, 0.2);
            color: var(--blue-darkerer) !important;
        }

        &--selected {
            background-color: rgba(52, 81, 104, 0.2);
            color: var(--blue-darkerer) !important;
            font-weight: 600;
        }

        &--unread {
            &:before {
                position: absolute;
                left: calc(100% - 20px);
                top: 50%;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--orange-accent);
                transform: translate(0, -50%);
                content: "";
            }
        }

        &:first-child {
            margin-top: none;
        }
    }
}
