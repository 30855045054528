@import "../../../../css/fonts";

.dashboardHeader {
    background-color: var(--dust-100);
    padding: 26px 32px;

    .stats {
        display: flex;

        .item {
            &:not(:last-child) {
                margin-right: 24px;
            }

            .icon {
                display: inline-block;
                cursor: pointer;
            }

            .number {
                @include font-rubik(14px, var(--midnight-100), 500);
                margin-right: 4px;
            }

            .text {
                @include font-rubik(14px, var(--midnight-100), 400);
                margin-right: 8px;
            }
        }
    }

    .status {
        .item {
            span {
                @include font-rubik(14px, var(--midnight-100), 400);
            }
        }
    }

    .welcome {
        display: flex;
        flex-direction: column;

        .text {
            @include font-rubik(24px, var(--midnight-100), 500);
        }
    }
}
