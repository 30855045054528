.letterPreviewStatusBar {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: 0 16px;

    &--loading {
        padding: 0 32px;
    }

    .bar {
        position: absolute;
        top: 12px;
        left: 0;
        width: calc(100% - 34px);
        height: 2px;
        background-color: var(--midnight-60);
        transform: translate(16px, -2px);

        .progress {
            background-color: var(--success-100);
            height: 100%;
        }
    }

    .skeleton {
        width: 100%;
        margin-bottom: 24px;
        padding: 0;

        &--node {
            position: absolute;
            border-radius: 50%;
            width: 20px;
            height: 20px;

            &--left {
                left: 12px;
            }

            &--right {
                left: calc(100% - 32px);
            }
        }
    }
}
