@import "../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-learn-more {
    display: flex;
    flex-direction: column;

    .about,
    .terms-conditions {
        display: flex;
        flex-direction: column;

        .header {
            margin-bottom: 24px;
        }
    }

    .about {
        margin-bottom: 32px;
        border-bottom: 1px solid var(--gray-darkerer);
        padding-bottom: 8px;

        .body {
            .list {
                padding-left: 16px;

                .item {
                    @include font-rubik(14px, var(--gray-darker), 300);
                    margin-bottom: 24px;
                }
            }
        }
    }

    .terms-conditions {
        .body {
            .text {
                @include font-rubik(16px, var(--gray-darkerer), 300);

                a {
                    color: var(--gray-darkerer) !important;
                    text-decoration: underline !important;
                }
            }
        }
    }
}
