.wallet-gift-cards-landing-lead {
    padding-top: 48px;

    .buttons {
        display: flex;
        justify-content: center;

        & > *:not(:last-child) {
            margin-right: 24px;
        }
    }
}
