@import "../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-review {
    & > *:not(:last-child) {
        margin-bottom: 24px;
    }

    .buttons {
        display: flex;
        gap: 16px;

        & > * {
            width: 50%;
        }
    }

    .disclaimer {
        text-align: right;

        p {
            @include font-rubik(null, var(--blue-dark-background), null);
        }
    }
}
