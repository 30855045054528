.progressBar {
    display: flex;
    flex-direction: column;

    .background {
        width: 100%;
        height: 3px;
        background-color: #d5dde1;

        .active {
            background-color: var(--pacific-blue-100);
            border-radius: 200px;
            height: 3px;
        }
    }

    .text {
        color: #4c5155;
        margin-bottom: 3px;
    }
}
