@import "../../../../css/fonts";
@import "../../../../css/variables";

.referralsLists {
    @include font-rubik(false, false, 400);
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: repeat(2, 1fr);
    width: 100%;

    @include phone {
        display: flex;
        flex-direction: column;
    }
}
