@import "../../../../css/fonts";

.dashboardLetters {
    .header {
        margin-bottom: 1rem;
    }

    .stats {
        margin-bottom: 1rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }
}
