@import "../../css/fonts";
@import "../../css/responsive";

.memoryBook {
    display: flex;
    width: 100%;
    padding: 32px;
    min-height: calc(100vh - 100px);

    .panel {
        display: flex;
        width: 100%;
        padding: 0;

        @include responsive($max-width: $desktop-small-min-width) {
            flex-direction: column-reverse;
        }

        .body,
        .image {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        .body {
            padding: 32px;
            width: 30%;

            @include responsive($max-width: $desktop-small-min-width) {
                width: 100%;
            }

            .buttons {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .button {
                    justify-content: space-between;
                    gap: 24px;
                    margin-bottom: 8px;
                }

                .disclaimer {
                    font-size: 12px;
                }
            }

            .content {
                display: flex;
                flex-direction: column;
                margin-bottom: 32px;
            }

            .items,
            .quantity {
                margin-bottom: 16px;

                .item {
                    display: flex;
                    justify-content: space-between;
                    gap: 8px;
                    width: 100%;

                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }

                    .label {
                        display: flex;
                        flex-direction: column;
                    }

                    .value {
                        @include font-rubik(18px, false, 600);
                        display: flex;
                        flex-direction: column;
                        text-align: right;

                        .subtotal {
                            &--hasDiscount {
                                text-decoration: line-through;
                            }
                        }

                        .total {
                            color: var(--teal-darkest);
                        }
                    }

                    .subtitle {
                        @include font-rubik(14px, false, 400);
                    }

                    .title {
                        @include font-rubik(18px, false, 600);
                    }
                }
            }
        }

        .image {
            width: 70%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
            background-color: #1d2533;
            border-radius: 0 8px 8px 0;

            @include responsive($max-width: $desktop-small-min-width) {
                width: 100%;
                flex-grow: 1;
                border-radius: 8px 8px 0 0;
            }

            img {
                width: 100%;
                border-radius: 0 8px 8px 0;
            }
        }
    }
}
