@import "../../fonts";
@import "../../variables";

.input,
.select {
    @include font-rubik(14px, var(--midnight-100), 300);
    background-color: transparent;
    position: relative;
    margin-bottom: 16px;
    border: 1px solid var(--gray-darkerer) !important;
    border-radius: 4px;
    padding: 8px 16px !important;
    letter-spacing: 0;

    &:disabled {
        border: 1px solid var(--gray-neutral) !important;
        background-color: #dedede;
        color: var(--gray-dark);
    }

    &::placeholder {
        color: var(--gray-darkerer) !important;
    }

    &-header {
        &-container {
            @include font-rubik(18px, var(--gray-darkest), 700);
            display: flex;
            justify-content: flex-start !important;
            margin-bottom: 8px;
        }

        &-asterisk {
            position: relative;
            height: 5px;
            width: 5px;
            border-radius: 50%;
            background-color: var(--mission-orange-100);
            transform: translate(2px, -10px);
        }

        &-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;

            &-link {
                margin-bottom: 10px;
            }
        }
    }

    &-validatable {
        width: 100%;
        height: 100%;
        margin-bottom: 0;

        &-container {
            position: relative;
            margin-bottom: 24px;
            border-color: var(--error-100);
        }

        &-checkmark {
            @include font-rubik(14px, var(--white), 700);
            position: absolute;
            top: 50%;
            left: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: var(--teal-neutral);
            transform: translate(calc(-100% - 16px), -50%);

            span {
                color: var(--white) !important;
                font-weight: 700 !important;
                transform: translate(-0.5px, -0.5px) scaleX(-1) rotate(-43deg);
            }
        }

        &-error {
            border-color: var(--error-100) !important;
        }

        &-invalid {
            position: absolute;
            top: 50%;
            left: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            transform: translate(calc(-100% - 16px), -50%);
        }
    }

    &-verification-code {
        $char-width: 2ch;
        $char-gap: 0.4 * $char-width;
        $letter-spacing: 2.75 * $char-gap;
        $num-char: 4;
        $input-width: $num-char * ($char-width + $char-gap);
        $input-translate-x: 0.5 * $char-width;

        display: block;
        width: $input-width;
        border: none !important;
        border-radius: 0;
        padding: 0 !important;
        background: repeating-linear-gradient(
                90deg,
                var(--gray-neutral) 0,
                var(--gray-neutral) $char-width,
                transparent 0,
                transparent $char-width + $char-gap
            )
            0 100% / #{$input-width - $char-gap} 2px no-repeat;
        color: var(--midnight-100);
        font-family: droid sans mono, sans-serif;
        font-size: 72px;
        font-weight: bold;
        letter-spacing: $letter-spacing;
        transform: translateX($input-translate-x);

        &:focus {
            outline: none;
        }
    }
}

.label {
    @include font-rubik(14px, var(--gray-dark), 700);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0;

    & > .input,
    & > .select {
        width: 100%;
    }

    & > span {
        margin-bottom: 12px;
    }
}

/* Select */

.select {
    @include font-rubik(16px, var(--midnight-100), 300);
    height: 40px !important;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAvUlEQVRIS+2TsQ3CMBBFXyrqiE0YAdGmhFVoKJEYJhXQwgqMAj0V+ihIp8jEd0EpkOzKls7v2f/siolHNTGfIsgmXCL6KaI5sAV2wPMLaQbsgQNwT9UM9eAKLIEjsElIBG+BBlDtKipYABegTkgs/NHBb1GB6q3kBKzh/Tk/Jx+EC+B5plZy7k6pWLJwr6B/E61d8IjASjRXQ5OZ9/vgicjuUVwaLnj0BtlPNeYVjYLaTdGIwsIiyEb2/xG9AAlzHhmgw6wZAAAAAElFTkSuQmCC)
        no-repeat;
    background-position: right 4px top 6px; /* Change the px value here to change the distance */
    appearance: none;

    option {
        &[disabled] {
            span {
                color: var(--gray-lightest) !important;
            }
        }
    }
}
