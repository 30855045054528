@import "../../fonts";
@import "../../variables";

.user-list {
    width: 100%;
    margin-bottom: 24px;
    border-radius: 4px;
    padding: 0 !important;
    box-shadow: none;

    .panel__header {
        padding: 16px 24px 12px 24px;
    }

    &__items {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-radius: 4px;
    }

    &__item {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        padding: 20px 0;
        background-color: var(--dust-100);

        &:after {
            position: absolute;
            top: 100%;
            left: 12px;
            width: calc(100% - 24px);
            height: 1px;
            background-color: var(--gray-light);
            content: "";
            z-index: 999;
        }

        &:last-child::after {
            background-color: transparent;
            content: "";
        }

        &__avatar {
            margin-left: 12px;
            margin-right: 24px;
        }

        &__icon {
            color: var(--gray-darkerer);
            height: 35px;
            left: calc(100% - 48px);
            position: absolute;
            top: calc(50% - 34px);
            transform: translateY(50%);
            width: 35px;
        }

        &__info {
            display: flex;
            flex-direction: column;

            &__primary {
                @include font-rubik(20px, var(--midnight-100), 300);

                &--rounded {
                    @include font-rubik(20px, var(--midnight-100), 300);
                }

                &--selected {
                    @include font-rubik(20px, var(--white), 600);
                }
            }

            &__secondary {
                @include font-rubik(12px, var(--midnight-100), 300);

                &--rounded {
                    @include font-rubik(12px, var(--midnight-100), 300);
                }

                &--selected {
                    @include font-rubik(12px, var(--white), 600);
                }
            }
        }

        &--selected {
            background-color: #345168;
        }

        &--clickable {
            cursor: pointer;
        }

        &--rounded {
            border: thin solid #345168;
            border-radius: 10px;
        }
    }

    &--loading {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100%;
    }

    &__message {
        @include font-rubik(18px, var(--gray-darkerer), 400);
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        &-text {
            display: inline-block;
            margin-bottom: 16px;
        }
    }

    &__search {
        @include font-rubik(18px, var(--midnight-100), 700);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 24px;
        border-top: 1px solid var(--blue-neutral);
        border-radius: 0 0 4px 4px;
        background-color: var(--white);
        cursor: pointer;

        &--empty {
            border-top: none !important;
        }

        &:hover {
            .user-list-search-text {
                text-decoration: underline;
            }
        }

        &__left,
        &__right {
            display: flex;
            align-items: center;
        }

        &__left {
            @include tablet {
                transform: translateX(-16px);
            }
        }

        &__icon {
            margin-right: 45px;
            color: var(--blue-neutral);
            font-size: 30px;
            transform: translateX(10px);

            @include tablet {
                margin-right: 32px;
            }
        }

        &__text {
            display: flex;
            flex-direction: column;

            &--secondary {
                @include font-rubik(var(--gray-darkerer), false, 400);
            }
        }
    }

    &__selected {
        margin-bottom: 24px;

        &__info {
            transform: translateY(2.5px);
        }
    }
}
