@import "../../css/fonts";

.gradMarker {
    display: flex;
    height: 5px;
    width: 20px;
    background-color: #dedede;
    margin-right: 2px;
    flex-grow: 1;
    border-radius: 5px;

    &--filled {
        background-color: var(--blue-darkerer);
    }
}

.graduationCountdown {
    width: 100%;
    padding: 10px 25px;

    .headerText {
        @include font-rubik(16px, var(--midnight-100), 300);
    }
}

.markerContainer {
    display: flex;
    margin-top: 8px;
}
