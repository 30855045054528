.cartShippingAddressLoading {
    display: flex;
    justify-self: space-between;
    align-items: center;
    min-height: 84px;
    padding: 16px;

    .address {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
        font-size: 14px;
    }
}
