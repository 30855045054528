@import "../../../../css/fonts";
@import "../../../../css/responsive";

.signInWelcome {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    max-height: 100%;

    @include responsive($max-width: $desktop-small-min-width) {
        display: none;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        transition: opacity 1s ease-in-out;
        z-index: 100;

        &--fadeIn {
            opacity: 1;
        }

        &--fadeOut {
            opacity: 0;
        }
    }

    .logo {
        margin-bottom: 16px;
        filter: brightness(0) invert(1);
        transform: scale(1.5);
        z-index: 101;
    }

    .textContainer {
        display: flex;
        align-items: center;
        z-index: 101;

        .cursor {
            display: inline-block;
            width: 16px;
            height: 30px;
            margin-left: 8px;
            background-color: var(--mission-orange-100);
            color: var(--mission-orange-100);
            animation: blink 1s infinite;
            z-index: 101;
        }

        .text {
            @include font-rubik(32px, var(--white), 500);
        }
    }
}

@keyframes blink {
    0%,
    50%,
    100% {
        opacity: 1;
    }
    25%,
    75% {
        opacity: 0;
    }
}
