@import "../variables";

.marker {
    font-size: 24px;

    &-base {
        color: var(--mission-orange-100);
    }

    &-hotel {
        color: var(--blue-neutral);
    }
}
