@import "../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-sender {
    display: flex;
    flex-direction: column;

    .buttons {
        display: flex;
        gap: 16px;

        & > * {
            width: 50%;
        }
    }

    .header {
        margin-bottom: 24px;
    }

    .sender-info {
        border: 1px solid var(--gray-neutral);
        border-radius: 10px;
        padding: 16px;
        margin-bottom: 32px;

        .form {
            width: 100%;

            .item {
                width: 100%;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                & > * {
                    width: 100%;
                }

                .header {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 12px;

                    & > * {
                        width: 50%;
                    }

                    .character-counter {
                        text-align: right;
                    }

                    .label {
                        @include font-rubik(16px, var(--gray-darkerer), 300);
                    }
                }

                input {
                    @include font-rubik(16px, var(--midnight-100), 300);
                }

                .textarea {
                    @include font-rubik(16px, var(--midnight-100), 300);
                    border-radius: 4px;
                    border: 1px solid var(--gray-darkerer);
                }
            }
        }
    }
}
