@import "../../fonts";
@import "../../variables";

#tooltip {
    @include font-rubik(false, false, 700);

    & > .tooltip-arrow {
        border-bottom-color: #2b526a;
    }

    & > .tooltip-inner {
        background-color: #2b526a;
    }
}
