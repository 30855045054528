.orderHistory {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 32px;

    .button {
        grid-column: 1;
    }

    .empty {
        grid-column: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .icon {
            margin-bottom: 8px;
            color: var(--pacific-blue-100);
            font-size: 32px;
        }
    }

    .items {
        grid-column: 1;
        width: 100%;
    }
}
