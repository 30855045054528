@import "../../../css/fonts";
@import "../../../css/variables";

.search-field {
    background-color: transparent;
    border: 1px solid #757575;
    border-radius: 4px;
    padding: 12px 8px;
    position: relative;
    width: 100%;

    &__placeholder {
        @include font-rubik(16px, var(--gray-darkerer), 300);
        position: absolute;
        top: 0;
        left: 8px;
        display: flex;
        align-items: center;
        pointer-events: none;
        transform: translate(0, 8px);

        .material-icons {
            margin-right: 4px;
            transform: translate(0, 25%);
        }
    }
}
