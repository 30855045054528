@import "../../../../css/fonts";

.musterRecruitsModal {
    .avatar {
        margin-left: 0px !important;
    }

    .container {
        border: 1px solid var(--gray-dark);
        border-radius: 5px;
        max-height: 500px;
        padding: 31px 40px;
        padding-bottom: 0;
        overflow-y: auto;
    }

    .emptySearch {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 18px;

        .header {
            @include font-rubik(14px, var(--gray-darkerer), 600);
        }

        .text {
            @include font-rubik(12px, var(--gray-darkerer), 300);
        }
    }

    .group {
        .label {
            @include font-rubik(12px, #1a3545, 600);
            text-transform: uppercase;
        }
    }

    .headline {
        margin-bottom: 16px !important;
    }

    .modalBody {
        padding: 0px !important;
        margin-bottom: 32px;
    }

    .userList {
        padding: 0px !important;
    }

    .search {
        margin-bottom: 16px;
        position: relative;
    }
}
