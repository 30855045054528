.purchase-modal-bundle-highlight {
    .content {
        display: flex;
        width: 100%;

        .right {
            padding: 36px 32px 36px 36px;
            width: 100%;

            .cards {
                display: flex;
                justify-content: space-evenly;
                margin: 48px 0px;
            }

            .header {
                align-items: center;
                display: flex;
                justify-content: space-between;
                padding-bottom: 16px;

                .text {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    width: 100%;

                    .headline {
                        margin-bottom: 14px !important;
                    }
                }
            }
        }
    }

    .image {
        width: auto;
        border-radius: 16px 0 0 16px;
    }
}
