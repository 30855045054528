@import "../../../../../../../../css/fonts";

.supportSquadSettingsModalCreateInviteSuccess {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
    }

    .buttons {
        display: flex;
        flex-direction: column;
        width: 100%;

        .button {
            width: 100%;
            margin-bottom: 8px;
        }
    }
}
