@import "../../../variables";

.letters-compose {
    &-container {
        position: relative;
        background-color: var(--dust-100);

        .page__body {
            padding: 8px;
        }
    }
}
