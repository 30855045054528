.memoryBookPromoBanner {
    margin-bottom: 16px;

    .banner {
        border-radius: 8px;
        border: 3px solid var(--mission-orange-100);
        border-radius: 8px;
        background-color: var(--mission-orange-20);
    }

    .content {
        display: flex;
        align-items: center;
        gap: 8px;
        font-weight: 600;

        .icon {
            color: var(--mission-orange-100);
        }
    }
}
