@import "../../../../css/variables";

.dashboardMessages {
    @include hideScrollBar;
    display: flex;
    width: 100%;
    gap: 24px;
    margin-bottom: 24px;
    overflow-x: hidden;

    .carousel {
        max-width: 100%;
        width: 100%;

        .item {
            width: 40%;
            max-width: 40%;
        }
    }
}
