@import "../../../css/fonts";

.input-validatable-container {
    position: relative;
    margin-bottom: 24px;

    .errorIcon {
        position: absolute;
        top: 50%;
        left: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(calc(-100% - 16px), -50%);
    }

    .input-validatable {
        width: 100%;
        height: 100%;
        margin-bottom: 0;

        &--error {
            border-color: var(--error-100) !important;
        }
    }

    .successIcon {
        @include font-rubik(14px, var(--white), 700);
        position: absolute;
        top: 50%;
        left: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        background-color: var(--teal-neutral);
        transform: translate(calc(-100% - 16px), -50%);

        span {
            color: var(--white) !important;
            font-weight: 700 !important;
            transform: translate(-0.5px, -0.5px) scaleX(-1) rotate(-43deg);
        }
    }
}
