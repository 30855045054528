@import "../../../../../../css/fonts";
@import "../../../../../../css/variables";

.support-squad-donate-tokens-modal-success {
    display: flex;
    flex-direction: column;
    align-items: center;

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .button {
            width: 75%;
        }
    }

    .checkmarkSuccess {
        margin-bottom: 18px;
    }

    .text {
        @include font-rubik(16px, var(--midnight-100), 300);
        margin-bottom: 40px;
        text-align: center;
    }
}
