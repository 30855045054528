@import "../../../../css/fonts";
@import "../../../../css/variables";

.referralsList {
    .avatar {
        margin-left: 0 !important;
    }

    .empty {
        margin-top: 16px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .loading {
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--gray-light);
        padding: 20px 0;

        .circle {
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }

        .rectangle {
            width: 150px;
            height: 16px;
        }
    }

    .userList {
        margin-bottom: 0;
    }
}
