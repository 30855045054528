.letters-photo-manage-modal {
    .content {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column;

        .buttons {
            display: flex;
            justify-content: space-between;
            margin-bottom: 24px;
            width: 100%;

            button {
                position: relative;
            }
        }

        .text {
            color: #345168 !important;
            font-style: italic;
        }

        .preview-image {
            position: relative;
            width: 100%;
            height: 426px;
            margin-bottom: 16px;
            border: 1px solid #345168;
            border-radius: 8px;
            background-size: contain;

            .empty {
                align-items: center;
                background-color: rgba(33, 33, 33, 0.05);
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;

                .description,
                .headline {
                    color: var(--gray-darkest);
                    opacity: 0.5;
                }
            }

            .deletePhoto {
                position: absolute;
                bottom: 0;
                right: 0;
                display: flex;
                justify-content: center;
                align-items: center;
                border-radius: 8px 0 8px 0;
                padding: 8px;
                background-color: var(--midnight-100);
                cursor: pointer;

                .icon {
                    color: var(--white);
                }
            }

            &--empty {
                border: 1px dashed var(--gray-darker);
            }
        }

        .submitButton {
            margin-top: 24px;
            width: 100%;
        }
    }
}
