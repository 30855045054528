@import "../../css/fonts";
@import "../../css/variables";

.headerBar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 32px;
    background-color: var(--dust-100);

    .left,
    .right {
        display: inline-flex;
        align-items: center;
    }

    .left {
        gap: 16px;

        .back {
            color: var(--midnight-100);
            text-decoration: none !important;
            cursor: pointer;

            &:hover {
                color: var(--midnight-100);
            }
        }

        .content {
            display: inline-flex;
            flex-direction: column;
            justify-content: center;

            .text {
                @include font-rubik(24px, var(--midnight-100), 700);
            }

            .textSecondary {
                @include font-rubik(16px, var(--midnight-100), 300);
            }
        }
    }
}
