@import "../../css/fonts";

.weeklyUpdates {
    .empty {
        display: flex;
        flex-direction: column;
        align-items: center;

        .animation {
            width: 160px;
        }

        .header {
            @include font-rubik(18px, var(--midnight-100), 700);

            margin-bottom: 8px;
        }

        .text {
            @include font-rubik(16px, var(--midnight-100), 400);
            margin-bottom: 16px;
        }
    }
}
