@import "../../../css/fonts";

.checkbox {
    width: 100%;

    .container {
        display: inline-block;
        vertical-align: middle;

        .display {
            width: 16px;
            height: 16px;
            margin-right: 6px;
            border: 1px solid var(--gray-darkerer);
            border-radius: 4px;
            background: transparent;
            transition: all 150ms;

            &--checked {
                border: 1px solid var(--blue-darkerer);
                background: var(--blue-darkerer);
            }

            .icon {
                fill: none;
                stroke: white;
                stroke-width: 4px;
                visibility: hidden;

                &--checked {
                    visibility: visible;
                    vertical-align: top;
                }
            }
        }

        .input {
            position: absolute;
            width: 1px;
            height: 1px;
            margin: -1px;
            border: 0;
            padding: 0;
            clip: rect(0 0 0 0);
            clip-path: inset(50%);
            overflow: hidden;
            white-space: nowrap;

            &:focus + & {
                box-shadow: 0 0 0 3px var(--blue-darker);
            }

            &:focus + .display {
                outline: blue solid 1px;
            }
        }
    }

    .text {
        @include font-rubik(14px, var(--gray-darkerer), 300);
        display: inline-block;
    }

    &--inline {
        display: flex;

        .container {
            padding-top: 5px;
        }
    }

    &--warning {
        .container {
            .display {
                align-items: center;
                border-radius: 50%;
                display: flex;
                height: 20px;
                justify-content: center;
                outline: none;
                width: 20px;

                &--checked {
                    background-color: var(--error-100);
                    border: none;
                }
            }
        }
    }
}
