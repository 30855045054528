@import "../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-recipient {
    .buttons {
        display: flex;
        gap: 16px;

        & > * {
            width: 50%;
        }
    }

    .divider,
    .new-recipient {
        margin-bottom: 24px;

        h4 {
            display: flex;
            align-items: center;
            margin-bottom: 0;
        }
    }

    .contacts {
        border: 1px solid var(--gray-neutral);
        border-radius: 10px;
        padding: 16px;

        &--active {
            border: 2px solid var(--blue-dark-background);
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 0;
            cursor: pointer;
        }
    }

    .header {
        margin-bottom: 24px;
    }

    .new-recipient {
        margin-bottom: 158px;

        .form {
            display: grid;
            grid-column-gap: 24px;
            grid-template-columns: repeat(4, 1fr);
            margin-bottom: 0;

            .label {
                grid-column: span 2;

                &[for="city"],
                &[for="line1"],
                &[for="line2"] {
                    grid-column: 1 / span 4;
                }

                span {
                    @include font-rubik(16px, var(--gray-darkerer), 300);
                }
            }
        }
    }
}
