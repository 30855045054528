@import "../../css/fonts";

.dateSelectorHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;

    .button {
        background-color: transparent;
        border: none;
        padding: 0px;
        display: flex;
    }

    .selector {
        @include font-rubik(14px, #49454f, 600);
        background-color: transparent;
        border: none;
        display: flex;
        padding-left: 14px 28px;
    }
}

.footer {
    display: flex;
    height: 60px;
    width: 100%;
    justify-content: flex-end;

    .buttons {
        display: flex;

        .button {
            @include font-rubik(16px, var(--pacific-blue-100), 400);
            border: none;
            background-color: transparent;
            padding: 8px 16px;
        }
    }
}
