@import "../../../css/fonts";
@import "../../../css/variables";

.item {
    position: relative;
    display: flex;
    width: 100%;
    padding: 16px;
    cursor: pointer;
    transition: background-color 0.25s ease-out;

    &:focus,
    &:hover,
    &--selected {
        background-color: var(--blue-lightest);
    }

    &--loading {
        cursor: default;

        &:hover {
            background-color: transparent;
        }

        .image {
            & > * {
                transform: scale(1);
            }
        }
    }

    &:not(:last-child) {
        .divider {
            position: absolute;
            top: 100%;
            left: 16px;
            width: calc(100% - 32px);
            height: 1px;
            background-color: var(--gray-light);
        }
    }

    .image {
        width: 0;
        height: 70px;
        margin-right: 16px;
        background-position: center;
        background-size: cover;
    }

    .info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1;

        .header {
            position: relative;
            display: flex;
            justify-content: space-between;

            .date {
                @include font-rubik(12px, var(--gray-darkerer), 400);
                opacity: 0;
                pointer-events: none;
                transition: opacity 0.25s ease-out;

                &--visible {
                    pointer-events: auto;
                    opacity: 1;
                }
            }

            .delete {
                position: absolute;
                top: 0;
                left: 100%;
                opacity: 0;
                transform: translate(-100%, 0);
                transition: opacity 0.25s ease-out;
                cursor: pointer;
                pointer-events: none;

                &:focus,
                &--visible {
                    pointer-events: auto;
                    opacity: 1;
                }
            }

            .title {
                @include font-rubik(14px, var(--midnight-100), 700);
            }
        }

        .body {
            position: relative;
            display: flex;
            align-items: center;
            flex: 1;

            .preview {
                @include font-rubik(14px, var(--midnight-100), 400);
            }

            .unread {
                position: absolute;
                top: 50%;
                left: 100%;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: var(--mission-orange-100);
                opacity: 0;
                transform: translate(-100%, -50%);
                transition: opacity 0.25s ease-out;

                &--visible {
                    opacity: 1;
                }
            }
        }
    }
}
