.musterOverviewActiveRecruitsPanel {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;
    width: 100%;

    .cards {
        display: flex;
        justify-content: space-between;
        gap: 24px;
        width: 100%;

        .card {
            align-items: center;
            background-color: var(--dust-100);
            border-radius: 10px;
            cursor: pointer;
            display: flex;
            height: 120px;
            flex: 1;
            flex-direction: column;
            justify-content: center;

            .label {
                text-align: center;
            }

            .text {
                align-items: center;
                display: flex;
                justify-content: center;
                flex-direction: column;
                width: 120px;
            }
        }
    }

    .header {
        margin-bottom: 12px !important;
        margin-left: 12px !important;
    }
}
