@import "../../../../../../css/fonts";

.support-squad-settings-modal-manage {
    .headerText {
        display: flex;
        flex-direction: column;
        width: 90%;

        .secondaryText {
            @include font-rubik(16px, var(--midnight-100), 300);
        }
    }
}
