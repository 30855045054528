@import "../../../../css/fonts";
@import "../../../../css/variables";

.referralsShareLink {
    container-type: inline-size;
    container-name: referrals-share-link;
    position: relative;
    display: flex;

    .count {
        position: absolute;
        top: 24px;
        left: 24px;
        display: flex;
        flex-direction: column;

        .amount,
        .description {
            font-size: 24px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        width: 50%;

        .link {
            width: 100%;
            min-width: 500px;
            z-index: 100;

            .wrapper {
                position: relative;
                display: flex;
                justify-content: space-between;

                .button {
                    top: 50;
                    left: 100%;
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 36px;
                    font-size: 16px;
                    width: 82px;
                    padding: 14px 24px !important;
                    text-align: center;
                    transform: translate(calc(-100% - 20px), 14px);
                }

                .input {
                    width: 100%;
                    border: 1px solid var(--gray-dark-background) !important;
                    padding: 20px !important;
                    font-size: 16px;
                    background-color: var(--white);
                }
            }
        }

        .text {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 32px;

            @include phone {
                margin-bottom: 8px;
            }

            .body {
                line-height: 28px;

                @include phone {
                    max-width: 100%;
                }
            }

            .header {
                display: flex;
                flex-direction: column;
                margin-bottom: 8px;
                font-size: 48px;
                font-weight: 600;
                line-height: 56px;
            }
        }
    }

    .image {
        display: flex;
        justify-content: center;
        width: 50%;

        .illustration {
            transform: translateY(24px);
        }
    }

    @container referrals-share-link (width < 750px) {
        .image {
            visibility: hidden;
        }
    }
}
