@import "../../../../../../../../css/fonts";

.supportSquadDetailHeaderMetrics {
    display: flex;
    gap: 16px;

    .metric {
        aspect-ratio: 1 / 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: var(--pacific-blue-20);
        border-radius: 8px;
        padding: 8px;

        &--action {
            cursor: pointer;
        }

        .label {
            @include font-rubik(16px, var(--midnight-100), 300);
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .skeleton {
            width: 32px;
            height: 32px;
            margin: 12px 0;
            transform: translate(0);
        }

        .value {
            @include font-rubik(36px, var(--blue-darkerer), 700);
        }
    }
}
