@import "../../../../css/fonts";

.letterPreviewInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 16px;

    .date {
        @include font-rubik(10px, var(--midnight-100), 300);
        margin-bottom: 16px;
    }

    .name {
        @include font-rubik(12px, var(--midnight-100), 500);
        margin-bottom: 4px;
    }

    .skeleton {
        &--date {
            width: 96px;
            height: 10px;
            margin-bottom: 12px;
        }

        &--name {
            width: 128px;
            height: 12px;
            margin-bottom: 12px;
        }

        &--tag {
            width: 64px;
            margin-bottom: 16px;
        }
    }

    .tag {
        @include font-rubik(8px, var(--midnight-100), 700);
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 32px;
        padding: 4px 16px;
        align-self: start;
        background-color: var(--desert-tan-40);
    }
}
