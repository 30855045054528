@import "../../fonts";
@import "../../variables";

.letters-add-ons-panel {
    position: relative;

    .banner {
        margin-bottom: 16px;

        .text {
            @include font-rubik(false, var(--blue-dark-background), 500);

            .link {
                border-bottom: 1px solid var(--blue-dark-background);
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        &__icon {
            cursor: pointer;
        }

        &__text {
            @include font-rubik(14px, var(--midnight-100), 700);
            display: inline-block;
            margin-right: 8px;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 16px !important;
    }

    .link {
        @include font-rubik(14px, false, false);
    }
}

.letters-add-ons-panel-list-item {
    display: flex;
    width: 100%;

    &:not(:last-child) {
        padding-bottom: 24px;
    }

    .imageWrapper {
        width: 20%;
        margin-right: 16px;

        .image {
            width: 100%;
            border-radius: 8px;
        }
    }

    .info {
        display: flex;
        flex-direction: column;
        width: 80%;

        .description {
            @include font-rubik(14px, var(--midnight-100), 300);
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            width: 100%;

            &:last-of-type {
                margin-bottom: 16px;
            }

            .price {
                @include font-rubik(false, false, 700);
            }
        }

        .remove {
            @include font-rubik(14px);
        }

        .title {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 8px;

            .link {
                @include font-rubik(14px);
            }

            .text {
                @include font-rubik(false, false, 700);
            }
        }
    }
}
