.memoryBookDescription {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    .description {
        margin-bottom: 16px;
    }

    .divider {
        background-color: var(--dust-120);
    }

    .title {
        margin-bottom: 8px;
    }
}
