.alert {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 4px;
    border-radius: 8px;
    padding: 24px;
    background-color: var(--burnt-orange-40);
    transition: opacity 0.3s ease;
    opacity: 1;

    &--fadeOut {
        opacity: 0;
    }

    .close {
        position: absolute;
        top: 0;
        left: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transform: translate(calc(-100% - 16px), 16px);
    }
}
