@import "../../css/fonts";

// Featured
.custom-story-group-container {
    border: 4px solid #ff5522;
    border-radius: 12px;
    cursor: pointer;
    padding: 4px;
    margin-right: 24px;

    &.basics {
        height: 190px;
        width: 190px;
    }

    &.benefits {
        height: 292px;
        width: 190px;
    }

    &.featured {
        height: 276px;
        width: 422px;
    }

    &.prepare {
        height: 292px;
        width: 190px;
    }

    &.dashboard,
    &.support-squad {
        background-color: #fafaf9;
        border: none;
        height: 88px;
        width: 266px;
    }

    &.storyly__story-group-seen {
        border-color: #d0cbbf;

        .custom-story-group .storyly__story-group-cover {
            &.dashboard,
            &.support-squad {
                border-color: #d5dde1;
            }
        }
    }
}

.custom-story-group-container .custom-story-group {
    border-radius: 10px;
    cursor: pointer;
    height: 100%;
    position: relative;
    overflow: hidden;
    width: 100%;

    &.dashboard,
    &.support-squad {
        align-items: center;
        display: flex;
        justify-content: center;
        padding: 12px 24px 12px 16px;
    }
}

.custom-story-group .storyly__story-group-cover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100%;

    &.prepare {
        width: unset;
        height: 100%;
    }

    &.dashboard,
    &.support-squad {
        border-radius: 50%;
        border: 3px solid #557588;
        height: 64px;
        padding: 2px;
        position: relative;
        width: 64px;
    }
}

.custom-story-group .storyly__story-group-title {
    @include font-rubik(16px, var(--dust-100), 400);
    position: absolute;
    z-index: 1;
    bottom: 0px;
    left: 0px;
    color: white;
    background: rgba(54, 54, 54, 0.6);
    backdrop-filter: blur(4.40525px);
    width: 100%;
    padding: 15px 7px;

    &.dashboard,
    &.support-squad {
        @include font-rubik(16px, var(--midnight-100), 400);
        background: none;
        backdrop-filter: none;
        position: relative;
    }
}
