@import "../../../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-recipient-new-recipient {
    margin-bottom: 158px;

    h4 {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .form {
        display: grid;
        grid-column-gap: 24px;
        grid-template-columns: repeat(4, 1fr);
        margin-bottom: 0;

        .label {
            grid-column: span 2;

            span {
                @include font-rubik(16px, var(--gray-darkerer), 300);
            }

            .select {
                @include font-rubik(14px, var(--midnight-100), 300);
                height: 35px !important;
            }
        }
    }
}
