@import "../../fonts";
@import "../../variables";

.user-search {
    width: 100%;

    &--letters {
        .panel {
            margin-bottom: 8px;
        }

        .searchField {
            width: 100%;
        }
    }

    &__banner {
        margin-top: 16px;
    }

    &__form {
        position: relative;
        margin-bottom: 16px !important;
    }

    &__header {
        display: flex;
        align-items: flex-start;
        margin-bottom: 16px;

        &__text {
            padding-left: 16px;

            &__primary {
                margin: 0 0 8px 0;
            }

            &__secondary {
                @include font-rubik(14px, var(--midnight-100), 300);
            }
        }
    }

    &__new {
        @include font-rubik(16px, var(--midnight-100), 400);
        cursor: pointer;

        &:focus {
            border: 2px solid lightblue;
            outline: none;
        }

        .material-icons {
            margin-right: 4px;
            transform: translate(0, 25%);
        }
    }

    &__results {
        height: 100%;
        margin-top: unset;

        &--none {
            &-image {
                width: 100%;
                height: auto;
            }
        }
    }

    .user-list-message-text {
        margin-top: 16px;
    }
}
