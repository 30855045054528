@import "../../../../css/fonts";

.profile-ship-date-modal {
    .select {
        background-color: var(--dust-100);
    }

    .select-date {
        display: flex;
        flex-direction: column;
        margin-top: 16px;

        .inputs {
            display: grid;
            grid-column-gap: 8px;
            grid-template-columns: repeat(3, calc(33.33% - 5.333px));
        }

        .submit-button {
            margin-top: 24px;
        }

        .text {
            @include font-rubik(20px, var(--gray-darkest), 300);
            margin-bottom: 16px;
        }
    }
}
