@import "../../../../../../../../css/fonts";

.support-squad-manage-user-list-item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 26px 0px;
    width: 100%;

    .checkbox {
        cursor: pointer;
        width: unset;

        .container {
            .display {
                border-radius: 50%;
                height: 20px;
                width: 20px;

                &--checked {
                    border: 1px solid var(--error-100);
                    background: var(--white);
                }
            }
        }
    }

    .details {
        display: flex;
        align-items: center;

        .avatar {
            background-color: lightblue;
            border-radius: 50%;
            width: 40px;
            height: 40px;
            margin-right: 16px;
        }

        .name {
            @include font-rubik(20px, var(--midnight-100), 300);
        }
    }

    &:not(:last-child) {
        border-bottom: thin solid #dedede;
    }

    &:focus {
        border: 1px solid blue;
    }
}
