.grid {
    display: grid;
    width: 100%;

    &-1-1 {
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 16px;
    }

    &-1-1-1 {
        grid-template-columns: 1fr 1fr 1fr;
        grid-column-gap: 16px;
    }
}
