@import "../../css/fonts";

.react-datepicker {
    background-color: #f3f2ef;
    border-radius: 16px;
    padding: 0px 14px;

    &__children-container {
        padding: 0px;
        margin: 0px;
        width: 100%;
    }

    &__day {
        @include font-rubik(12px, #1c1b1f, 400);
        margin-top: 5px;
        width: 40px;
        height: 40px;
        line-height: 40px;

        &--disabled {
            color: var(--gray-dark) !important;
        }

        &:focus {
            border-radius: 100px;
        }

        &--keyboard-selected {
            border-radius: 100px;

            &:hover {
                border-radius: 100px;
                color: white;
            }
        }

        &--selected {
            background-color: var(--pacific-blue-100);
            color: white;
            border-radius: 100px;

            &:hover {
                border-radius: 100px;
            }
        }

        &--outside-month {
            @include font-rubik(12px, var(--gray-darker), 400);
        }
    }

    &__day-name {
        @include font-rubik(14px, #1c1b1f, 400);
        width: 40px;
        height: 40px;
    }

    &__day-names {
        display: flex;
        justify-content: space-between;
        height: 40px;
    }

    &__header {
        background-color: transparent;
        border: none;
    }

    &__month {
        margin: 0px;
    }

    &__week {
        display: flex;
        justify-content: space-between;
        height: 40px;
    }
}
