@import "../../../../css/fonts";
@import "../../../../css/variables";

.profileAddressBook {
    box-shadow: none !important;

    .form {
        position: relative;
    }

    .item {
        @include font-rubik(16px, var(--midnight-100), 400);
        display: flex;
        align-items: center;
        border-bottom: 1px solid var(--gray-light);
        padding: 20px 0;
        cursor: pointer;

        &--empty {
            border-bottom: none;
            padding-bottom: 0;
        }

        & > * {
            margin-right: 16px;
        }
    }

    .userList {
        margin-bottom: 0;

        .items {
            max-height: 70vh;
            padding: 0;
            overflow-y: scroll;

            & > *:first-child {
                padding-top: 20px;
            }
        }
    }
}
