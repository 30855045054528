@import "../../../../../../../../css/fonts";

.supportSquadDetailActivityItem {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 16px;

    .icon {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 45px;
        height: 45px;
        border-radius: 90px;
        background-color: var(--pacific-blue-20);
    }

    .message {
        @include font-rubik(12px, var(--midnight-100), 300);
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-grow: 1;
    }

    .skeleton {
        transform: translate(0);
    }

    .time {
        @include font-rubik(10px, var(--gray-darkerer), 300);
        text-align: right;
    }
}
