@import "../../../css/fonts";

.letters-photo-delete-modal {
    .buttons {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 8px;
    }
    .content {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        flex-direction: column;
    }

    .dialog {
        width: 285px;
    }

    .text {
        margin-bottom: 35px;
        text-align: center;
    }

    .icon {
        height: 40px;
        margin-bottom: 16px;
        width: 40px;
    }

    .button-secondary {
        border-color: var(--error-100);
        color: var(--error-100);
    }
}
