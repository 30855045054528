@import "../../../../../../../../css/fonts";
@import "../../../../../../../../css/responsive";

.purchaseModalBundleOptionsSandboxxPlus {
    display: flex;
    flex-direction: column;

    .headline {
        margin-bottom: 16px;

        .badge {
            span {
                font-size: 24px;
            }
        }

        .title {
            display: flex;
            align-items: center;
            gap: 8px;

            .pill {
                border-radius: 4px;
                padding: 2px 4px;
                font-weight: 500;

                &:not(:last-child) {
                    margin-right: 8px;
                }
            }
        }
    }
}
