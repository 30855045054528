@import "../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-billing-details {
    & > * {
        &:not(:last-child) {
            margin-bottom: 32px;
        }
    }

    .add-payment-method,
    .saved-payment-methods {
        border: 1px solid var(--gray-neutral);
        border-radius: 10px;
        padding: 16px;

        .header {
            margin-bottom: 16px;

            h4 {
                margin-bottom: 0;
            }
        }
    }

    .add-payment-method {
        .braintree-container {
            margin-bottom: 0;

            .form {
                margin-bottom: 0;
            }
        }
    }

    .buttons {
        display: flex;
        gap: 16px;

        & > * {
            width: 50%;
            margin: 0 !important;
        }
    }

    .header {
        margin-bottom: 24px;
    }
}
