@import "../../fonts";
@import "../../variables";

.modal {
    &-content {
        background-color: var(--dust-100);
        border-radius: 16px;
        padding: 0px 36px;
    }

    &__body {
        width: auto;
        padding: 24px 0px;
    }

    &__dialog {
        &--default-size {
            max-width: 600px;
        }
    }

    &__header {
        @include font-rubik(24px, var(--midnight-100), 700);
        border-bottom: 1px solid var(--gray-light);
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        border-radius: 4px 4px 0 0;
        padding: 24px 0px;

        &--borderless {
            border-bottom: none;
        }

        &--warning-text {
            color: var(--error-100);
        }

        &--centered {
            text-align: center;
        }

        &__close {
            color: var(--gray-darker);
            cursor: pointer;
            transition: all 0.25s ease-out;

            &:hover {
                color: var(--gray-darkest);
            }
        }

        &__text-wrapper {
            display: flex;
            flex-direction: column;
            flex: 1;

            .headerText {
                @include font-rubik(24px, var(--midnight-100), 700);
                display: flex;
                align-items: center;
            }

            .headerSubText {
                @include font-rubik(16px, var(--midnight-100), 300);
                margin-bottom: 0px;
                margin-top: 8px;
            }
        }

        &--filled {
            background-color: var(--blue-neutral);
            color: var(--white);

            .modal-header-close {
                color: var(--white);
            }
        }
    }

    &__separator {
        width: 90%;
        margin: 30px 5% 30px;
        border-bottom: 1px solid #dedede;
        color: var(--gray-darker);
        line-height: 1.5px;
        text-align: center;

        span {
            padding: 0 10px;
            background-color: var(--dust-100);
            color: var(--gray-darker);
        }
    }
}

.logout-modal {
    &-body {
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-body {
            color: var(--gray-darkerer);
            font-size: 18px;
        }

        &-header {
            margin-bottom: 8px;
            font-size: 24px;
        }
    }

    &-button {
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 16px;

            & > *:not(:last-child) {
                margin-bottom: 8px;
            }
        }

        &-secondary {
            font-size: 16px;
        }
    }
}

.new-modal {
    &-body {
        &-container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &-image {
            margin-bottom: 24px;
        }

        &-header {
            @include font-rubik(24px, false, 700);
            margin-bottom: 24px;
        }

        &-text {
            @include font-rubik(18px, false, 400);
            margin-bottom: 24px;
        }
    }

    &-button-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-header {
        @include font-rubik(24px, var(--midnight-100), 400);
        display: block;
        margin-bottom: 24px;
    }

    &-form {
        margin-bottom: 24px;
    }

    &-input {
        border: 1px solid var(--gray-neutral);
        padding: 24px;
        color: var(--gray-darkerer);
        box-sizing: border-box;

        &::placeholder {
            color: var(--gray-neutral) !important;
        }
    }

    &-text-divider {
        position: relative;
        color: var(--gray-neutral);

        &.vertical {
            height: 100%;

            &:before,
            &:after {
                position: absolute;
                width: 1px;
                height: 100%;
                background-color: var(--gray-neutral);
                content: "";
            }
        }

        &.horizontal {
            width: 100%;
            margin-bottom: 24px;
            text-align: center;

            &:before,
            &:after {
                position: absolute;
                top: 50%;
                left: 0;
                width: 45%;
                height: 1px;
                background-color: var(--gray-neutral);
                content: "";
            }

            &:after {
                left: 100%;
                transform: translateX(-100%);
            }
        }
    }

    &-loading {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
