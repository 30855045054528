@import "./fonts";
@import "./variables";

.navbar {
    margin-bottom: 0;
}

.navbar-default {
    background-image: none;
    background-color: #ffffff;
    border-color: #e7e7e7;
    border-radius: 0;
    font-size: 18px;
    font-weight: 300;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

/* title */

.nav-thin {
    @include font-rubik(16px, var(--mission-orange-100), 400);
}

.navbar-default .navbar-brand:hover,
.navbar-default .navbar-brand:focus {
    color: var(--mission-orange-100);
}

/* link */
.navbar-default .navbar-nav > li > a {
    color: var(--mission-orange-100);
}

.navbar-default .navbar-nav > li > a:hover,
.navbar-default .navbar-nav > li > a:focus {
    color: #333;
}

.navbar-default .navbar-nav > .active > a,
.navbar-default .navbar-nav > .active > a:hover,
.navbar-default .navbar-nav > .active > a:focus {
    color: #555;
    background-color: #eeeeee;
}

.navbar-default .navbar-nav > .open > a,
.navbar-default .navbar-nav > .open > a:hover,
.navbar-default .navbar-nav > .open > a:focus {
    color: #555;
    background-color: #d5d5d5;
}

/* caret */

.navbar-default .navbar-nav > .dropdown > a .caret {
    border-top-color: var(--mission-orange-100);
    border-bottom-color: var(--mission-orange-100);
}

.navbar-default .navbar-nav > .dropdown > a:hover .caret,
.navbar-default .navbar-nav > .dropdown > a:focus .caret {
    border-top-color: #333;
    border-bottom-color: #333;
}

.navbar-default .navbar-nav > .open > a .caret,
.navbar-default .navbar-nav > .open > a:hover .caret,
.navbar-default .navbar-nav > .open > a:focus .caret {
    border-top-color: #555;
    border-bottom-color: #555;
}

/* mobile version */
.navbar-default .navbar-toggle {
    border: none;
    border-radius: 0;
}

.navbar-default .navbar-toggle:hover,
.navbar-default .navbar-toggle:focus {
    background-color: #eeeeee;
}

.navbar-default .navbar-nav > .active > a {
    background-image: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
}

.navbar-default .navbar-toggle .icon-bar {
    background-color: var(--mission-orange-100);
}

@media (max-width: 767px) {
    .navbar-default .navbar-nav .open .dropdown-menu > li > a {
        color: #777;
    }

    .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover,
    .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
        color: #333;
    }
}

@keyframes shake {
    from,
    to {
        transform: translate3d(1, 2, 0);
    }

    1%,
    3%,
    5%,
    6%,
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
        transform: translate3d(10px, 0, 0);
    }
}

.shake {
    animation-name: shake;
}
