@import "../../../css/fonts";
@import "../../../css/variables";

.notification-corner {
    position: fixed;
    top: 100%;
    left: 100%;
    width: 480px;
    height: auto;
    padding: 48px 24px 24px 24px;
    background-color: var(--white);
    box-shadow: 0px 2px 36px 0px rgba(0, 0, 0, 0.11);
    z-index: 1000;

    &__close {
        position: absolute;
        top: 0;
        left: 100%;
        transform: translate(calc(-100% - 8px), 8px);
        cursor: pointer;
    }
}
