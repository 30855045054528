@import "../../../css/fonts";
@import "../../../css/variables";

.confirm-verification-code-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .label {
        margin-bottom: 16px;
    }

    .inputs {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        input {
            height: 72px;
            width: 72px;
            margin: 0 8px;
            border: none;
            border-bottom: 2px solid #4f5b66;
            padding-bottom: 8px;
            background-image: none;
            background-color: transparent;
            color: var(--blue-darkerer);
            font-size: 72px;
            text-align: center;
            box-shadow: none;
            -webkit-box-shadow: none;
            -moz-box-shadow: none;

            &.error {
                border-bottom: 2px solid var(--error-100);
                color: var(--error-100);
            }

            &.small {
                width: 40px;
                height: 40px;
                font-size: 24px;
                font-weight: 600;
            }

            &:focus {
                outline: none;
            }
        }

        &.small {
            margin: 0 auto;
        }
    }
}
