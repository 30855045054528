@import "../../../../../../css/fonts";

.dashboardLettersTimeToQualify {
    border: 2px solid var(--dust-120);
    border-radius: 0.5rem;
    padding: 1rem;
    background-color: var(--dust-100);

    .description {
        display: flex;
        flex-direction: column;
        gap: 0.5rem;

        a {
            font-weight: 600;
        }

        .text {
            @include font-rubik(14px, var(--midnight-100), 400);
        }
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 0.375rem;
    }

    .icon {
        width: 20px !important;
        height: 20px !important;
    }

    .label {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.375rem;
        font-size: 1rem;

        svg {
            width: 1rem;
            height: 1rem;
        }
    }

    .value {
        font-size: 1rem;
    }
}
