.imageCropModal {
    max-height: 90vh;

    .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .buttons {
        width: 100%;

        .button {
            width: 100%;
        }
    }

    .cropper {
        max-width: 100%;
        max-height: 60vh;
        margin: 30px 0 54px 0;

        .image {
            max-height: 60vh;
            width: auto;
        }
    }
}
