.wallet-gift-cards-purchase-recipient-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    min-width: 25%;
    border: 1px solid var(--gray-neutral);
    border-radius: 10px;
    padding: 16px;
    cursor: pointer;

    &:not(:last-child) {
        margin-right: 8px;
    }

    &--active {
        border: 2px solid var(--gray-darkest);
        background-color: var(--gray-lighter);
        box-shadow: 0px 3px 11px rgba(52, 81, 104, 0.35);
    }
}
