@import "../../css/fonts";

.accordion {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-dark-background);
    border-radius: 10px;
    background-color: transparent;
    box-sizing: border-box;

    .item {
        .content {
            overflow: hidden;
            transition: max-height 250ms ease-out;
            padding: 0 16px;
            box-sizing: border-box;
        }

        .header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 16px;
            cursor: pointer;

            .chevronHolder {
                height: 35px;
                width: 35px;
                border-radius: 8px;
                background-color: rgba(43, 83, 106, 0.2);
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .leftHandContent {
                display: flex;
                align-items: center;

                .iconHolder {
                    margin-right: 20px;
                }
            }

            .title {
                @include font-rubik(false, false, 500);
                display: block;
            }

            h4 {
                margin-bottom: 0;
            }
        }
    }
}
