.cartShippingOptionsSelect {
    display: flex;

    .select {
        width: 100%;
        background-color: var(--white);
    }

    .skeleton {
        transform: translateX(0);
    }
}

.option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 16px;
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    font-size: 14px;
    overflow: hidden;

    .title {
        width: 75%;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}
