@import "../../../../css/fonts";

.cartShippingAddress {
    margin-bottom: 16px;

    .header {
        @include font-rubik(14px, var(--midnight-80), 500);
        margin-bottom: 8px;
    }

    .panel {
        border-radius: 8px;
        background-color: var(--white);
    }
}
