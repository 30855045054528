@import "../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-select-option {
    display: flex;
    flex-direction: column;
    padding-right: 48px;

    .buttons {
        display: flex;
        flex-direction: column;

        .checkbox {
            margin-bottom: 12px;
            z-index: 1000;

            a.link {
                @include font-rubik(false, var(--gray-darkerer), false);
                text-decoration: underline !important;
            }
        }
    }

    .choose-value,
    .images {
        margin-bottom: 40px;
    }

    .choose-value {
        .header {
            margin-bottom: 24px;
        }

        .options {
            display: flex;
            justify-content: space-between;
            gap: 10px;
        }
    }

    .delivery-method {
        margin-bottom: 120px;

        .disclaimer {
            @include font-rubik(12px, var(--gray-darker), 300);
        }

        .header {
            display: flex;
            align-items: center;

            .icon {
                display: flex;
                align-items: center;
                margin-left: 10px;
                cursor: pointer;
            }
        }
    }

    .images {
        position: relative;
        display: flex;
        padding-top: 60px;

        .image {
            aspect-ratio: 1.58;
            width: 52%;

            img {
                width: 100%;
            }

            &--back {
                position: absolute;
                top: 0;
                left: 100%;
                transform: translateX(-100%);
                z-index: 1;
            }

            &--front {
                z-index: 2;
                -webkit-filter: drop-shadow(
                    0px 6.76313px 13.5263px rgba(0, 0, 0, 0.2)
                );
                filter: drop-shadow(0px 6.76313px 13.5263px rgba(0, 0, 0, 0.2));
            }
        }
    }
}
