.musterChecklistItem {
    width: 100%;
    height: 55px;
    display: flex;
    justify-content: space-between;
    background-color: rgba(43, 83, 106, 0.05);
    align-items: center;
    padding: 16px;
    border-radius: 8px;
    margin-bottom: 8px;

    &:last-of-type {
        margin-bottom: 16px;
    }

    .icons {
        float: right;
        width: 44px;
        display: flex;
        justify-content: space-between;

        .iconButton {
            background-color: transparent;
            border: none;

            .icon {
                width: 16px;
                height: 16px;
                color: var(--pacific-blue-100);
                cursor: pointer;
            }
        }
    }
    .content {
        display: flex;

        .checkbox {
            cursor: pointer;
            height: unset;
            padding-right: 19px;
            width: unset;
        }

        .textContainer {
            display: flex;
            align-items: center;
        }
    }
}
