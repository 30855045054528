@import "../../../../css/fonts";

.container {
    .buttons-container {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        margin-top: 50px;
        max-width: 400px;

        .submit-code {
            margin-bottom: 15px;
        }
    }
}

.success-confirmation,
.error-confirmation {
    align-items: center;
    display: flex;
    flex-direction: column;

    .enter-new-code {
        margin-bottom: 8px;
    }

    .message {
        @include font-rubik(16px, var(--midnight-100), 300);
        margin-bottom: 25px;
    }

    .success-confirmation-continue-button {
        margin-top: 24px;
    }

    .success-icon {
        max-width: 42px;
    }
}
