.listIcon {
    .item {
        display: flex;
        align-items: center;

        &:not(:last-child) {
            margin-bottom: 16px;
        }

        .text {
            display: flex;
            flex-direction: column;
        }
    }
}
