@import "../../../../../../../../css/fonts";

.support-squad-settings-modal-manage-options {
    .header {
        @include font-rubik(12px, var(--midnight-100), 600);
    }

    .membershipOptions,
    .squadOptions {
        .option {
            @include font-rubik(16px, var(--midnight-100), 300);
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid var(--gray-light);
            line-height: 44px;
            cursor: pointer;

            &:first-of-type {
                margin-top: 10px;
            }

            &:last-of-type {
                border-bottom: none;
            }

            &--red {
                color: var(--error-100);
            }

            .chevron {
                font-size: 35px;
                color: var(--gray-darker);
            }
        }
    }

    .membershipOptions {
        margin-bottom: 5px;
    }

    .squadOptions {
        margin-bottom: 30px;
    }
}
