@import "../../../../../../css/fonts";

.supportSquadDetailActivity {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
    flex-basis: 0;
    margin-bottom: 0;
    overflow: auto;

    .feed {
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow-y: auto;
    }

    .header {
        @include font-rubik(20px, var(--midnight-100), 600);
    }
}
