@import "../../../../css/fonts";

.personModalAddressFormatter {
    .addressFormatter {
        .buttons {
            display: flex;
            justify-content: space-between !important;
            margin-top: 24px;
        }

        .panel {
            padding: 0 !important;
            box-shadow: none;
        }
    }

    .baseSelect {
        margin-bottom: 24px;
        z-index: 100;
    }
}
