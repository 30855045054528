@import "../../../../../css/fonts";
@import "../../../../../css/responsive";

.lettersComposeConfirmation {
    padding: 32px !important;

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 32px 33.33%;

        @include responsive($max-width: $desktop-small-max-width) {
            padding: 32px 25%;
        }

        @include responsive($max-width: $desktop-small-min-width) {
            padding: 32px 15%;
        }

        @include responsive($max-width: $tablet-landscape-min-width) {
            padding: 32px;
        }
    }

    .checkmark {
        width: 160px;
        height: 160px;
        margin-bottom: 16px;
    }

    .header {
        margin-bottom: 32px;
        text-align: center;
    }

    .listIcon {
        margin-bottom: 32px;

        .icon {
            color: var(--blue-darkerer);
            margin-right: 16px;

            @include responsive($max-width: $mobile-landscape-max-width) {
                display: none;
            }
        }

        .title {
            @include font-rubik(20px, false, 700);
        }
    }
}
