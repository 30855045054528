@import "../../../../../css/fonts";

.muster-recruit-invite-code-failure {
    .body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .failureText {
            @include font-rubik(16px, var(--midnight-100), 300);
            margin-top: 10px;
            margin-bottom: 35px;
            text-align: center;
        }

        .failureTextHeader {
            @include font-rubik(20px, var(--midnight-100), 600);
            margin-top: 17px;
            text-align: center;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        max-width: 400px;

        .button {
            margin-bottom: 8px;
        }
    }
}
