@import "../../../../../css/fonts";

.sign-up-confirm-phone {
    .form {
        .buttons {
            display: flex;
            flex-direction: column;
            width: 100%;

            .button {
                width: 100%;

                &:not(:last-of-type) {
                    margin-bottom: 8px;
                }
            }
        }

        .input {
            margin-bottom: 24px;
        }
    }

    .header {
        margin-bottom: 80px !important;
    }
}
