@import "../../css/fonts";
@import "../../css/variables";

.inbox {
    display: grid;
    grid-column-gap: 24px;
    grid-template-columns: 4fr 6fr;

    .empty {
        .icon {
            margin-bottom: 24px;
        }
    }

    .list {
        position: relative;
        align-self: flex-start;
        background-color: var(--dust-100);

        .empty {
            background-color: var(--dust-100);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 64px 72px;

            &__description {
                @include font-rubik(14px, var(--midnight-100), 400);
                text-align: center;
            }

            &__header {
                @include font-rubik(20px, var(--midnight-100), 700);
                margin-bottom: 8px;
                text-align: center;
            }
        }
    }
}
