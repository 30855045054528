@import "../../../css/fonts";
@import "../../../css/variables";

.detail {
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: var(--dust-100);

    .body {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 36px;

        .button {
            align-self: flex-start;
        }

        .date {
            @include font-rubik(12px, var(--gray-darkerer), 400);
            margin-bottom: 12px;
        }

        .text {
            @include font-rubik(16px, var(--midnight-100), 400);
            margin-bottom: 36px;
        }

        .title {
            @include font-rubik(24px, var(--midnight-100), 700);
            margin-bottom: 12px;
        }

        .videoWrapper {
            position: relative;
            height: 0;
            margin-bottom: 36px;
            padding-bottom: 56.25%; /* 16:9 */

            & iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }

    .empty {
        display: flex;
        justify-content: center;
        width: 100%;
        padding: 136px 0;
    }

    .image {
        aspect-ratio: 16 / 9;
        width: 100%;
        background-position: center;
        background-size: cover;
    }
}
