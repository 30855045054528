.muster-recruit-join-modal {
    max-height: 1000px;
}

.muster-recruit-join-modal-dialog {
    max-height: 755px;
    overflow: hidden;

    &--photo-edit {
        max-height: 1000px;
        height: 90vh;
        width: 90vw;
    }
}
