@import "../../../../css/responsive";

.memoryBookImage {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 70%;
    height: 100%;
    max-height: 100%;
    border-radius: 0 8px 8px 0;

    @include responsive($max-width: $desktop-small-min-width) {
        width: 100%;
        border-radius: 8px 8px 0 0;
    }

    .background {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 0 8px 8px 0;
        background-size: cover;
        background-position: center;
        transition: opacity 1s ease-in-out;
        z-index: 100;

        @include responsive($max-width: $desktop-small-min-width) {
            border-radius: 8px 8px 0 0;
        }

        &--fadeIn {
            opacity: 1;
        }

        &--fadeOut {
            opacity: 0;
        }
    }

    .skeleton {
        width: 100%;
        height: 100%;
        transform: translate(0, 0);
    }
}
