/** MIXINS **/

/* Browser Queries */

@mixin edge {
    @supports (-ms-accelerator: true) {
        @content;
    }
    @supports (-ms-ime-align: auto) {
        @content;
    }
}

@mixin firefox {
    @supports (-moz-appearance: none) {
        @content;
    }
}

@mixin safari {
    @media not all and (min-resolution: 0.001dpcm) {
        @content;
    }
}

/* Text Queries */

@mixin limitNumberOfLines($numberOfLines) {
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: $numberOfLines;
}

@mixin preventTextSelect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Media Queries [LEGACY] */

$breakpoints: (
    "phone": 400px,
    "phone-wide": 480px,
    "phablet": 560px,
    "tablet-small": 640px,
    "tablet": 768px,
    "tablet-wide": 1024px,
    "desktop-base": 1248px,
    "desktop-small": 1440px,
    "desktop-medium": 1680px,
    "desktop-large": 1920px,
);

@mixin mq($width, $type: min) {
    @if map_has_key($breakpoints, $width) {
        $width: map_get($breakpoints, $width);
        @if $type == max {
            $width: $width - 1px;
        }
        @media only screen and (#{$type}-width: $width) {
            @content;
        }
    }
}

$tablet-width: 768px;
$desktop-width: 1028px;
$desktop-base: 1248px;
$desktop-small: 1440px;
$desktop-medium: 1680px;
$desktop-large: 1920px;

@mixin desktop-small {
    @media only screen and (min-width: #{$desktop-base}) and (max-width: #{$desktop-medium - 1px}) {
        @content;
    }
}

@mixin mobile {
    @media only screen and (max-width: map_get($breakpoints, "desktop")) {
        @content;
    }
}

@mixin phone {
    @media only screen and (max-width: #{$tablet-width - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

@mixin tablet {
    @media only screen and (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}

/** Other Variables **/

$navigation-width: 244px;

/* Placeholders */

@mixin input-placeholder {
    &.placeholder {
        @content;
    }

    &:-moz-placeholder {
        @content;
    }

    &::-moz-placeholder {
        @content;
    }

    &::-webkit-input-placeholder {
        @content;
    }
}

/* Scroll */

@mixin hideScrollBar {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
