@import "../../../css/fonts";

.sandboxxPlusBadge {
    display: flex;
    align-items: center;

    .icon {
        margin-left: 4px;
    }

    .text {
        @include font-rubik(20px, var(--midnight-100), 500);
    }
}
