@import "../../../../../../../../css/fonts";
@import "../../../../../../../../css/variables";

.button {
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    &:last-child {
        margin-top: 64px;
    }
}

.form {
    display: flex;
    flex-direction: column;

    .banner {
        margin-top: 10px;
    }

    .label {
        display: flex;
        flex-direction: column;
        margin-bottom: 14px;

        &[for="branchId"] {
            margin-top: 40px;
        }

        .text {
            @include font-rubik(16px, var(--midnight-100), 600);
        }

        .text-secondary {
            @include font-rubik(14px, var(--gray-darkest), 300);
        }
    }
}
