.cartPaymentMethodSelect {
    display: flex;

    .select {
        width: 100%;
        background-color: var(--white);
    }

    .skeleton {
        transform: translateX(0);
    }
}

.option {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    font-size: 14px;
    overflow: hidden;

    .expirationDate,
    .number {
        width: 20%;
    }

    .expirationDate {
        color: var(--dust-140);
    }

    .icon {
        width: 15%;

        img {
            width: 75%;
        }
    }
}
