/**
* Styling to preserve Youtube aspect ratio and
* prevent iframe from expanding beyond container width
**/
.wp-block-embed__wrapper {
    position: relative;
    max-width: 100%;
    padding-bottom: 56.25%;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
