@import "../../css/fonts";
@import "../../css/variables";

.lineItem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 0;
    cursor: pointer;

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }

    &:not(:last-child) {
        border-bottom: 1px solid var(--gray-light);
    }

    .left {
        display: flex;
        align-items: center;

        .icon {
            display: flex;
            align-items: center;
            margin-right: 16px;
        }
    }
}
