@import "../../../../css/fonts";

.support-squad-no-tokens-modal {
    display: flex;
    flex-direction: column;
    align-items: center;

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;

        .button {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 8px;
            }
        }
    }

    .header {
        @include font-rubik(20px, var(--midnight-100), 600);
        margin-bottom: 10px;
        text-align: center;
    }

    .icon {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 18px;
    }

    .text {
        @include font-rubik(16px, var(--midnight-100), 300);
        margin-bottom: 40px;
        text-align: center;
    }
}
