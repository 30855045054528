:root {
    /**
    * Core Colors
    **/

    --deep-sea-20: #d1d7da;
    --deep-sea-40: #a3aeb5;
    --deep-sea-60: #76868f;
    --deep-sea-80: #485d6a;
    --deep-sea-100: #1a3545;
    --deep-sea-120: #152a37;
    --deep-sea-140: #102029;
    --deep-sea-160: #0a151c;
    --deep-sea-180: #050b0e;

    --desert-tan-20: #f6f5f2;
    --desert-tan-40: #eceae5;
    --desert-tan-60: #e3e0d9;
    --desert-tan-80: #d9d5cc;
    --desert-tan-100: #d0cbbf;
    --desert-tan-120: #a6a299;
    --desert-tan-140: #7d7a73;
    --desert-tan-160: #53514c;
    --desert-tan-180: #2a2926;

    --dust-20: #fdfcfc;
    --dust-40: #fafaf9;
    --dust-60: #f8f7f5;
    --dust-80: #f5f5f2;
    --dust-100: #f3f2ef;
    --dust-120: #c2c2bf;
    --dust-140: #92918f;
    --dust-160: #616160;
    --dust-180: #313130;

    --midnight-20: #d2d4d4;
    --midnight-40: #a5a8aa;
    --midnight-60: #797d7f;
    --midnight-80: #4c5155;
    --midnight-100: #1f262a;
    --midnight-120: #191e22;
    --midnight-140: #131719;
    --midnight-160: #0c0f11;
    --midnight-180: #06080b;

    --mission-orange-20: #ffddd3;
    --mission-orange-40: #ffbba7;
    --mission-orange-60: #ff997a;
    --mission-orange-80: #ff774e;
    --mission-orange-100: #ff5522;
    --mission-orange-120: #cc441b;
    --mission-orange-140: #993314;
    --mission-orange-160: #66220e;
    --mission-orange-180: #331107;

    --pacific-blue-20: #d5dde1;
    --pacific-blue-40: #aabac3;
    --pacific-blue-60: #8098a6;
    --pacific-blue-80: #557688;
    --pacific-blue-100: #2b536a;
    --pacific-blue-120: #224255;
    --pacific-blue-140: #1a3240;
    --pacific-blue-160: #11212a;
    --pacific-blue-180: #091115;

    /**
    * Secondary Colors
    **/

    --burnt-orange-20: #ecd7d3;
    --burnt-orange-40: #daafa7;
    --burnt-orange-60: #c7887c;
    --burnt-orange-80: #b56050;
    --burnt-orange-100: #a23824;
    --burnt-orange-120: #822d1d;
    --burnt-orange-140: #612216;
    --burnt-orange-160: #41160e;
    --burnt-orange-180: #200b07;

    --moss-20: #e9e9e5;
    --moss-40: #d3d4cb;
    --moss-60: #bebeb0;
    --moss-80: #a8a996;
    --moss-100: #92937c;
    --moss-120: #757663;
    --moss-140: #58584a;
    --moss-160: #3a3b32;
    --moss-180: #1d1d19;

    --od-green-20: #e1e0da;
    --od-green-40: #c3c0b5;
    --od-green-60: #a5a191;
    --od-green-80: #87816c;
    --od-green-100: #696247;
    --od-green-120: #544e39;
    --od-green-140: #3f3b2b;
    --od-green-160: #2a271c;
    --od-green-180: #15140e;

    --steel-orange-20: #fde9e3;
    --steel-orange-40: #fbd3c7;
    --steel-orange-60: #fabdac;
    --steel-orange-80: #f8a790;
    --steel-orange-100: #f69174;
    --steel-orange-120: #c5745d;
    --steel-orange-140: #945746;
    --steel-orange-160: #623a2e;
    --steel-orange-180: #311d17;

    --steel-blue-20: #edf1f3;
    --steel-blue-40: #dce3e7;
    --steel-blue-60: #cad5da;
    --steel-blue-80: #b9c7ce;
    --steel-blue-100: #a7b9c2;
    --steel-blue-120: #86949b;
    --steel-blue-140: #646f74;
    --steel-blue-160: #434a4e;
    --steel-blue-180: #212527;

    --woods-20: #d9d9d5;
    --woods-40: #b4b2ab;
    --woods-60: #8e8c82;
    --woods-80: #696558;
    --woods-100: #444f2e;
    --woods-120: #363225;
    --woods-140: #28261c;
    --woods-160: #1b1912;
    --woods-180: #0d0d09;

    /**
    * Feedback Colors
    **/

    --error-40: #dda7a3;
    --error-100: #ab2219;

    --informative-40: #a3aeb5;
    --informative-100: #1a3545;

    --success-40: #a9bda9;
    --success-100: #285929;

    --warning-40: #e9d59b;
    --warning-100: #c99604;

    /**
    * Other
    **/

    --black: #000000;
    --white: #ffffff;

    /**
    * Deprecated Colors
    **/

    --blue-dark-background: #345168;
    --blue-darkerer: #2a526a;
    --blue-darker: #3b6f8e;
    --blue-dark: #4f8fb6;
    --blue-neutral: #7abad5;
    --blue-light: #bee1f0;
    --blue-lighter: #e2f0f7;
    --blue-lightest: #f1fafd;
    --blue-link: #4b97f2;

    --gray-dark-background: #dedede;
    --gray-darkest: #4a4a4a;
    --gray-darkerer: #757575;
    --gray-darker: #9b9b9b;
    --gray-dark: #b3b3b3;
    --gray-neutral: #d1d1d1;
    --gray-light: #e6e6e6;
    --gray-lighter: #f0f0f0;
    --gray-lightest: #fafafa;

    --green: #abdb9a;
    --green-light: #b5daa0;

    --orange-darkest: #992400;
    --orange-dark: #d63706;
    --orange-light: #ff9373;
    --orange-lighter: #f7c4b5;
    --orange-lightest: #fff1ed;
    --orange-accent: #ec6237;

    --teal-darkest: #005c5c;
    --teal-darker: #069191;
    --teal-dark: #4eadad;
    --teal-neutral: #79c6c6;

    --yellow-neutral: #fce699;
    --yellow-dark: #594503;
}
