.dashboardLettersGraduation {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .editButton {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.875rem;
        gap: 0.25rem;
        border: none;
        padding: 0;
        cursor: pointer;

        svg {
            width: 0.75rem;
            height: 0.75rem;
        }
    }
}
