@import "../../../../../css/fonts";

.signUpSuccess {
    .buttons {
        margin-top: 80px;

        .button {
            width: 100%;
        }
    }

    .items {
        .item {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-bottom: 16px;
            }

            .icon {
                margin-right: 16px;
                color: var(--mission-orange-100);
                font-size: 32px;
            }

            .title {
                @include font-rubik(24px, var(--midnight-100), 600);
            }
        }
    }

    .header {
        margin-bottom: 32px;
    }
}
