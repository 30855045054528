@import "../../../../../css/fonts";
@import "../../../../../css/variables";

.purchaseModalBundleTile {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 72px;
    padding: 21px 12px;
    border: 1px solid var(--midnight-20);
    border-radius: 8px;
    background: var(--dust-100);
    box-sizing: border-box;
    cursor: pointer;
    overflow: hidden;

    &:not(:last-child) {
        margin-bottom: 14px;
    }

    &--sandboxxPlus {
        align-items: flex-start;
        height: unset;
        outline: 2px solid transparent;

        .gradient {
            display: flex !important;
        }
    }

    &--sandboxxPlusSelected {
        border: 3px solid #0000 !important;
        border-radius: 8px;
        background: linear-gradient(var(--dust-100), var(--dust-100))
                padding-box,
            linear-gradient(
                    var(--angle),
                    var(--mission-orange-100),
                    var(--mission-orange-40)
                )
                border-box;
        animation: rotate 4s ease infinite;

        .gradient {
            display: none !important;
        }
    }

    &--selected {
        border: 3px solid var(--pacific-blue-100);

        .gradient {
            display: none !important;
        }
    }

    .banner {
        color: var(--mission-orange-100);
        text-align: left;
    }

    .benefit {
        color: var(--pacific-blue-100);
        text-align: left;
    }

    .button {
        width: 100%;
    }

    .gradient {
        position: absolute;
        display: none;
        top: 100%;
        left: 0;
        width: 100%;
        height: 3px;
        background: repeating-linear-gradient(
            to right,
            var(--mission-orange-100) 0%,
            var(--mission-orange-40) 50%,
            var(--mission-orange-100) 100%
        );
        background-size: 200% auto;
        background-position: 0 100%;
        animation: gradient 2s infinite;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        transform: translateY(-100%);
    }

    .left {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .letterCountText {
            display: flex;
            height: 30px;
        }
    }

    .number {
        @include font-rubik(20px, var(--blue-dark-background), 700);
    }

    .price {
        display: flex;
        justify-content: center;
        font-size: 20px;

        .current {
            @include font-rubik(false, var(--pacific-blue-100), 400);
        }
    }

    .title {
        @include font-rubik(20px, var(--midnight-100), 600);
        height: 48px;
        margin-bottom: 8px;
        line-height: 24px;
        text-align: center;
    }

    .units {
        @include font-rubik(20px, var(--pacific-blue-100), 700);
        line-height: 30px;
    }
}

@keyframes gradient {
    0% {
        background-position: 0 0;
    }
    100% {
        background-position: -200% 0;
    }
}

@keyframes rotate {
    to {
        --angle: 360deg;
    }
}

@property --angle {
    syntax: "<angle>";
    initial-value: 0deg;
    inherits: false;
}
