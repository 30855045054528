@import "../../../../../../../../../css/fonts";

.wallet-gift-cards-landing-features {
    .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 40px;

        h2 {
            text-align: left;
        }

        .description {
            @include font-rubik(16px, var(--midnight-100), 300);
        }
    }

    .features {
        display: flex;
        flex-direction: row;
        gap: 40px;

        .feature {
            display: flex;
            flex-direction: column;
            flex: 1 1 0;

            .image {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-bottom: 16px;
            }

            .text {
                text-align: center;
            }
        }
    }
}
