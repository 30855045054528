@import "../../../../../../css/fonts";
@import "../../../../../../css/variables";

.wallet-manage-list {
    display: flex;
    flex-direction: column;
    height: 100%;

    .body {
        &--empty {
            display: flex;
            justify-content: center;

            .text {
                @include font-rubik(14px, var(--gray-darker), 300);
                margin: 56px;
                text-align: center;
            }
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
    }

    .panel {
        &--add {
            margin-bottom: 0;

            .button {
                width: 100%;
            }
        }

        &--list {
            flex: 1;
            height: 100%;
        }
    }
}
