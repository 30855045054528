@import "../../../../css/fonts";

.personModalConfirm {
    .buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .contact {
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: var(--white);

        .stamp {
            position: absolute;
            top: 0;
            left: 100%;
            width: 64px;
            transform: translate(-80%, -20%);
        }
    }

    .text {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 24px;
    }
}
