@import "../../../../../../css/fonts";

.cartItemsItem {
    display: flex;
    align-items: center;
    border-radius: 8px;
    padding: 8px;
    background-color: var(--white);

    &:not(:last-child) {
        margin-bottom: 16px;
    }

    .content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-grow: 1;

        .label,
        .value {
            display: flex;
            flex-direction: column;

            .title {
                font-size: 14px;
            }

            .quantity {
                font-size: 12px;
            }
        }

        .value {
            align-items: flex-end;

            .subtotal {
                &--hasDiscount {
                    text-decoration: line-through;
                }
            }

            .total {
                @include font-rubik(16px, var(--teal-darkest), false);
            }
        }
    }

    .image {
        width: 20%;
        aspect-ratio: 1/1;
        margin-right: 8px;
        border-radius: 8px;
        background-size: cover;
        background-position: center;
        transform: translateX(0);
    }
}
