@import "../../../../../../css/fonts";

.wallet-gift-cards-landing {
    padding: 16px;

    .panel {
        display: flex;
        flex-direction: column;
        align-items: center;

        section {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 64px 0;

            &:nth-of-type(1) {
                border-bottom: 1px solid var(--gray-neutral);
            }

            header {
                width: 100%;
                margin-bottom: 24px;
                text-align: center;
            }
        }
    }
}
