@import "../../../css/fonts";
@import "../../../css/variables";

.contact {
    width: 100%;
    max-width: 800px;

    .address {
        .header {
            margin-bottom: 16px;
        }

        .item {
            @include font-rubik(16px);
            display: flex;
            align-items: center;
            margin-bottom: 16px;
            border-bottom: 1px solid var(--gray-light);
            padding-bottom: 16px;

            &--action {
                display: flex;
                align-items: center;
                cursor: pointer;

                .icon {
                    margin-right: 4px;
                }

                .text {
                    transform: translateY(1px);
                }
            }

            &--empty {
                @include font-rubik(false, var(--gray-dark), 400);
            }
        }
    }

    .button {
        margin-bottom: 16px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 32px;

        /** LAYOUT **/

        .left,
        .right {
            display: flex;
            width: 50%;
        }

        .left {
            align-items: center;
        }

        .right {
            justify-content: flex-end;
        }

        /** END LAYOUT **/

        .avatarContainer {
            width: 100px;
            height: 100px;
            margin-right: 24px;

            .avatar {
                @include font-rubik(32px);
                width: 100%;
                height: 100%;
            }
        }

        .info {
            display: flex;
            flex-direction: column;

            .name {
                margin-bottom: 8px;
            }
        }
    }
}
