@import "../../css/fonts";

.storylyGroup {
    width: 100%;

    .header {
        @include font-rubik(20px, var(--midnight-100), 600);
        display: block;
        margin-bottom: 10px;
    }
}
