@import "../../css/fonts";
@import "../../css/variables";

.banner {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background-color: var(--blue-light);

    &--active {
        cursor: pointer;
    }

    &--expand-text {
        .text {
            width: 100%;
        }
    }

    &--hidden {
        display: none;
    }

    &--orange {
        background-color: var(--orange-lighter);

        .chevron {
            color: var(--orange-darkest);
        }

        .text {
            color: var(--orange-darkest) !important;
        }
    }

    &--secondary {
        background-color: var(--desert-tan-60);

        .text {
            color: var(--midnight-100) !important;
        }
    }

    &--yellow {
        background-color: var(--warning-40);
    }

    .chevron,
    .close {
        flex: 1;
        display: flex;
        justify-content: flex-end;
        align-items: flex-start;
        cursor: pointer;
    }

    .icon {
        margin-right: 16px;
    }

    .text {
        @include font-rubik(14px, var(--midnight-100), false);
        display: flex;
        flex-direction: column;
    }
}
