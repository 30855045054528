@import "../../../../../../css/fonts";
@import "../../../../../../css/variables";

.wallet-manage-detail {
    height: 100%;

    .panel {
        display: flex;
        flex-direction: column;
        height: 100%;
        padding: 36px;
    }

    .top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid var(--gray-neutral);
        padding-bottom: 36px;

        .barcode {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 300px;
            border-radius: 16px;
            padding: 24px 40px;
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.3);

            .image {
                width: 100%;
                margin-bottom: 24px;
                min-height: 88px;
            }

            .pin {
                @include font-rubik(16px, var(--midnight-100), 300);
                display: flex;

                & > *:not(:last-child) {
                    margin-right: 8px;
                }

                .show-pin {
                    @include font-rubik(false, var(--blue-dark), false);
                }
            }

            .skeleton {
                width: 100%;
                height: 88px;
                margin-bottom: 24px;
                border-radius: 16px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            min-width: 240px;

            .balance {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .amount {
                    @include font-rubik(30px, var(--midnight-100), 600);
                }

                .description {
                    @include font-rubik(20px, var(--midnight-100), 300);
                    display: flex;
                    flex-direction: column;
                }

                .divider {
                    width: 1px;
                    height: 56px;
                    margin: 0 24px;
                    background-color: var(--midnight-100);
                }
            }

            .button {
                margin-bottom: 36px;
            }
        }
    }
}
