@import "../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-order-progress {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--gray-neutral);
    border-radius: 10px;
    padding: 16px;
    box-shadow: 0px 5.5px 11px rgba(0, 0, 0, 0.2);

    .body {
        width: 100%;
        display: flex;
        flex-direction: column;

        .section {
            padding: 16px 0;

            &:first-of-type {
                padding-top: 0;
            }

            &:last-of-type {
                padding-bottom: 0;
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--gray-dark);
            }

            .item {
                display: flex;
                justify-content: space-between;

                &:not(:last-child) {
                    margin-bottom: 16px;
                }

                &--column {
                    flex-direction: column;
                    align-items: flex-start;
                }

                .key {
                    @include font-rubik(16px, var(--midnight-100), 600);
                }

                .value {
                    @include font-rubik(16px, var(--midnight-100), 300);
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    text-align: right;
                    word-break: break-all;

                    .card {
                        height: auto;

                        * {
                            @include font-rubik(16px, var(--midnight-100), 300);
                        }

                        .cardNumber {
                            margin: 0;
                        }

                        .expirationDate {
                            display: none;
                        }

                        .icon {
                            margin-right: 8px;
                        }
                    }
                }
            }
        }
    }

    .image {
        width: 100%;
        margin-bottom: 24px;

        img {
            width: 100%;
        }
    }

    &--full-width {
        flex-direction: row;
        gap: 40px;
        box-shadow: none;

        .body {
            width: 78%;
        }

        .image {
            width: 22%;
            margin-bottom: 0;

            img {
                width: 100%;
            }
        }
    }
}
