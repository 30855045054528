@import "../../css/fonts";
@import "../../css/variables";

.navbar {
    background-color: var(--dust-100);
    border-right: 2px solid var(--gray-lighter);
    border-radius: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    position: fixed;
    overflow-y: auto;
    width: $navigation-width;

    .button {
        position: absolute;
        bottom: 24px;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    .header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 35px;
        padding: 33px 24px;
        width: 100%;

        .image {
            margin: 0 auto 20px auto;
            width: 60px;
            height: auto;
        }

        .logo {
            &:active,
            &:focus,
            &:hover,
            &:visited {
                text-decoration: none;
            }

            .text {
                @include font-rubik(24px, var(--mission-orange-100), 700);
                text-decoration: none;
            }
        }

        .text {
            @include font-rubik(32px, var(--white), 300);

            @include tablet {
                display: none;
            }
        }
    }

    .logout-spacer {
        flex-grow: 0.95;
    }

    .menu {
        display: flex;
        flex-direction: column;
    }
}
