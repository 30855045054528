.personas {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &--grid {
        display: grid;
        grid-column-gap: 16px;
        grid-template-columns: 1fr 1fr;
    }
}
