.wallet-gift-cards-purchase-recipient-contacts {
    margin-bottom: 24px;
    border: 1px solid var(--gray-neutral);
    border-radius: 10px;
    padding: 16px;

    &--active {
        border: 2px solid var(--blue-dark-background);
    }

    h4 {
        display: flex;
        align-items: center;
        margin-bottom: 0;
    }

    .cards {
        display: flex;
        margin-top: 16px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 0;
        cursor: pointer;
    }
}
