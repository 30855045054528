.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        max-height: 530px;

        .connections {
            margin-bottom: 24px;
            margin-top: 14px;

            .list {
                max-height: 335px;

                .connection {
                    margin-bottom: 24px;
                }
            }

            &--scroll {
                border: 1px solid var(--gray-darker);
                border-radius: 10px;
                padding: 16px;

                .list {
                    padding-right: 16px;
                    overflow-y: auto;

                    &::-webkit-scrollbar {
                        -webkit-appearance: none;
                        width: 4px;
                        margin-left: -10px;
                        padding-top: 10px;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 20px;
                        background-color: #d1d1d1;
                    }
                }
            }
        }

        .connectionsHeader {
            margin-bottom: 4px !important;
        }

        .button {
            margin-top: 50px;
        }
    }

    .header {
        display: flex;
        flex-direction: column;
        margin-bottom: 56px;

        .headline {
            margin-bottom: 7px !important;
        }

        .subHeader {
            line-height: 21px;
        }
    }
}
