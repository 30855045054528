@import "../../../../css/fonts";

.referralsInstructions {
    display: flex;
    flex-direction: column;

    & > *:not(:last-child) {
        margin-bottom: 24px;
    }

    .steps {
        container: inline-size;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        gap: 24px;

        .step {
            display: flex;
            align-items: center;

            &:not(:last-child) {
                margin-right: 24px;
            }

            .number {
                @include font-rubik(28px, var(--dust-100), 600);
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 16px;
                margin-right: 16px;
                width: 56px;
                min-width: 56px;
                height: 56px;
                border-radius: 50%;
                background-color: var(--pacific-blue-100);
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                gap: 4px;

                h3 {
                    @include font-rubik(20px, var(--midnight-100), 500);
                    margin-bottom: 0;
                }
            }
        }
    }
}
