@import "../../../../../../../../css/fonts";

.members-list {
    max-height: 500px;
    margin-bottom: 25px;
    overflow-y: auto;
}

.no-members {
    display: flex;
    flex-direction: column;

    .text {
        @include font-rubik(16px, #9b9b9b, 300);
        margin-bottom: 50px;
        margin-top: 50px;
        text-align: center;
        padding: 0px 75px;
    }
}

.header {
    display: flex;
    justify-content: space-between;

    .label {
        @include font-rubik(16px, var(--midnight-100), 600);
    }

    .editOption {
        @include font-rubik(16px, var(--midnight-100), 300);
        cursor: pointer;
    }
}
