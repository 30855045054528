@import "../../../../css/fonts";

.profile-account-delete-modal {
    .body {
        padding-top: 0;

        .form {
            .button {
                width: 100%;
            }

            .input {
                width: 100%;
                border: 1px solid #757575;
                background-color: transparent;
                margin-bottom: 24px;

                &:last-of-type {
                    margin-bottom: 36px;
                }
            }

            .phone-submit {
                width: 100%;
                margin-top: 16px;
            }

            .label {
                font-size: 16px;
                line-height: 20px;
                color: #757575;
                font-weight: lighter;
            }
        }

        .paragraph-info {
            margin-bottom: 26px;
            text-align: center;
        }

        .warning-text {
            color: var(--error-100);
            margin-top: 24px;
            text-align: center;
        }
    }

    .confirm {
        .buttons {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .checkbox-container {
            display: flex;
            margin-bottom: 25px;

            .checkbox {
                margin: 4px 0 0;
                transform: scale(1.25);
            }

            .text {
                @include font-rubik(14px, var(--gray-darkest), 300);
                margin-left: 12px;
                margin-top: 20px;
            }
        }

        .deleted-items {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 20px;

            span {
                @include font-rubik(20px, var(--midnight-100), 600);
                margin-bottom: 8px;
            }
        }

        .header {
            .text {
                color: var(--error-100);
            }
        }
    }

    .confirm-dialog {
        max-width: 400px;
    }

    .header {
        .border {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding-bottom: 14px;
            border-bottom: 1px solid #dedede;
        }

        .icon-holder {
            border: none;
            background-color: transparent;
            height: 24px;
            display: flex;
            align-items: center;
            padding: 0;
        }

        .text {
            @include font-rubik(false, false, 600);
        }
    }

    .verify {
        .body {
            margin-bottom: 24px;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }

    .phone-verify-code {
        .form {
            input {
                height: 55px;
                width: 55px;
                margin-bottom: 25px;
                font-size: 60px;
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;

            .buttons-row {
                display: flex;
                justify-content: center;
            }
        }
    }

    p {
        @include font-rubik(16px, var(--midnight-100), 300);
        line-height: 20px;
    }
}
