@import "../../../fonts";
@import "../../../variables";

.letters-compose__recipient {
    &__search {
        &__grid {
            display: flex;
            flex-direction: column;
            height: 100%;

            .user-search {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 100%;

                .panel {
                    margin-bottom: 8px;
                }

                &__results {
                    flex: 1;
                    max-height: 790px;
                    overflow-y: auto;

                    .loading-image {
                        margin-bottom: 24px;
                    }

                    &--loading {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }

            &__user-lists {
                padding: 8px;

                &--hidden {
                    display: none;
                }
            }
        }

        &__lists {
            display: flex;
            justify-content: center;
            height: calc(100vh - 275px);
            overflow-y: scroll;

            .empty {
                align-items: center;
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 60px;
                width: 80%;

                .icon {
                    height: 41px;
                    margin-bottom: 10px;
                    width: 48px;
                }

                .text {
                    color: var(--gray-darker);
                    text-align: center;
                    width: 100%;
                }
            }

            &__tabs {
                margin-bottom: 3px;
            }

            & > * {
                width: 50%;
                align-self: flex-start;
            }
        }
    }
}
