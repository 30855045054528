@import "../../../../../../css/fonts";
@import "../../../../../../css/variables";

.support-squad-donate-tokens-modal-enter-amount {
    .form {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 14px;

        .buttons {
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            margin-top: 32px;

            .button {
                width: 75%;

                &:first-child {
                    margin-bottom: 8px;
                }
            }
        }

        .errors {
            @include font-rubik(12px, var(--error-100), 300);
            height: 42px;
            width: 289px;
            text-align: center;
            visibility: hidden;

            &--active {
                visibility: visible;
            }

            .number {
                @include font-rubik(false, false, 500);
            }
        }

        .input {
            @include font-rubik(50px, #345168, 700);
            width: 289px;
            margin-bottom: 8px;
            border: none;
            border-bottom: 1px solid var(--gray-darkerer);
            background: transparent;
            text-align: center;

            &--invalid {
                @include font-rubik(false, var(--error-100), false);
            }
        }

        .label {
            @include font-rubik(16px, var(--midnight-100), 300);
            margin-bottom: 8px;
        }

        .subText {
            @include font-rubik(12px, var(--gray-darkerer), 300);
            margin-bottom: 12px;

            .number {
                @include font-rubik(false, false, 500);
            }
        }
    }
}
