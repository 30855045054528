@import "../../../css/fonts";

.buttonBlock {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    border: 1px solid var(--dust-120);
    border-radius: 10px;
    padding: 20px 16px;
    background-color: var(--dust-100);

    .bodyText {
        @include font-rubik(16px, var(--midnight-100), 300);
        line-height: 21px;
        text-align: left;
    }

    .headerText {
        @include font-rubik(20px, var(--midnight-100), 600);
        line-height: 24px;
        text-align: left;
    }

    &--filled {
        background-color: var(--blue-dark-background);

        .bodyText {
            color: white;
        }

        .headerText {
            color: white;
        }
    }
}
