@import "../../../../../css/fonts";

.muster-recruit-invite-code-modal {
    .options {
        .buttons {
            display: flex;
            flex-direction: column;
            margin: 0 auto;
            margin-top: 50px;
            max-width: 400px;

            .submit-code {
                margin-bottom: 15px;
            }
        }
    }
}
