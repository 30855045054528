@import "../../../../../../css/fonts";
@import "../../../../../../css/responsive";

.supportSquadListItem {
    position: relative;
    display: flex;
    border-radius: 8px;
    cursor: pointer;
    box-sizing: border-box;

    @include responsive($max-width: $tablet-landscape-max-width) {
        flex-direction: column;
    }

    &--loading {
        background-color: none !important;
    }

    &--selected {
        &::before {
            content: "";
            position: absolute;
            top: 0px;
            right: 0px;
            bottom: 0px;
            left: 0px;
            border: 4px solid var(--blue-darkerer);
            border-radius: 8px;
            box-sizing: border-box;
            z-index: 10;
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-radius: 0 8px 8px 0;
        padding: 16px;
        background-color: var(--dust-20);
        width: 70%;

        @include responsive($max-width: $desktop-medium-max-width) {
            width: 60%;
        }

        @include responsive($max-width: $desktop-small-max-width) {
            width: 50%;
        }

        @include responsive($max-width: $tablet-landscape-max-width) {
            width: 100%;
            border-radius: 0 0 8px 8px;
        }

        .header {
            display: flex;
            justify-content: space-between;
            gap: 16px;
            align-items: center;
            border-bottom: 1px solid var(--dust-120);
            padding-bottom: 16px;

            @include responsive($max-width: $desktop-small-max-width) {
                flex-direction: column;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 0;
            }

            .description {
                display: flex;
                flex-direction: column;
                flex: 1;

                .memberCount {
                    font-size: 14px;
                }
            }

            .icon {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                gap: 4px;
                flex: 1;

                @include responsive($max-width: $desktop-small-max-width) {
                    margin-top: 8px;
                }

                svg {
                    color: var(--midnight-100);
                }

                .role {
                    font-size: 14px;
                }
            }
        }

        .createdBy {
            display: flex;
            flex-direction: column;
            padding-top: 16px;

            .label {
                @include font-rubik(12px, var(--midnight-60), 400);
            }

            .value {
                font-size: 14px;
            }
        }
    }

    .image {
        aspect-ratio: 1 / 1;
        width: 30%;
        border-radius: 8px 0 0 8px;
        background-color: var(--dust-20);
        background-size: cover;
        transform: translate(0, 0);

        @include responsive($max-width: $desktop-medium-max-width) {
            width: 40%;
        }

        @include responsive($max-width: $desktop-small-max-width) {
            width: 50%;
        }

        @include responsive($max-width: $tablet-landscape-max-width) {
            width: 100%;
            border-radius: 8px 8px 0 0;
        }
    }
}
