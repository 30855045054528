@import "../../../../css/fonts";

.cartPaymentMethod {
    .header {
        @include font-rubik(14px, var(--midnight-80), 500);
        margin-bottom: 8px;
    }

    .link {
        @include font-rubik(12px, false, 400);
        margin-top: 4px;
        display: block;
        text-align: right;
    }
}
