@import "../../../../css/fonts";

.addressFormatterInput {
    margin-bottom: 0;

    .form {
        display: flex;
        flex-direction: column;
        height: 100%;

        .field {
            margin-bottom: 24px;

            &:not(:last-child) {
                margin-bottom: 24px !important;
            }

            .input {
                border-color: var(--gray-darkerer) !important;
                border-radius: 4px;
                margin-bottom: 0 !important;
                width: 100%;
            }
        }
    }
}
