.button {
    border: 1px solid #2a526a;
    border-radius: 5px;
}

.headerBarRightContent {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    :hover {
        cursor: pointer;
    }

    &:hover h4 {
        color: var(--pacific-blue-100);
    }

    &:hover p {
        color: var(--pacific-blue-100);
    }

    .header {
        margin-bottom: 2px;
        font-weight: 400;
    }
}
