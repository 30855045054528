@import "../../../../css/fonts";

.muster-recruit-join-modal {
    max-height: 1000px;

    .dialog {
        max-height: 755px;
        overflow: hidden;

        &--photo-edit {
            max-height: 1000px;
            height: 90vh;
            width: 90vw;
        }
    }
}

.muster-recruit-invite-code-success {
    display: flex;
    flex-direction: column;
    align-items: center;

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .header {
            @include font-rubik(20px, var(--midnight-100), 600);
            margin-top: 17px;
        }

        .image {
            width: 100%;
            height: 125px;
            object-fit: cover;
        }

        .text {
            @include font-rubik(16px, var(--midnight-100), 300);
            margin-top: 10px;
            margin-bottom: 35px;
            text-align: center;
        }
    }

    .buttons {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        width: 100%;

        .button {
            width: 100%;
            margin-bottom: 8px;
        }
    }
}
