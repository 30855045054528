@import "../../css/fonts";
@import "../../css/variables";

.dep-modal {
    .modal-body {
        padding-bottom: 32px;
    }

    .modal-content {
        max-width: 580px;
    }

    .modal-dialog {
        max-width: 580px;
    }

    &__description {
        @include font-rubik(16px, var(--midnight-100), 300);
        display: inline-block;
        margin-bottom: 16px;
    }

    &__header {
        @include font-rubik(24px, var(--midnight-100), 700);
        margin-bottom: 16px !important;
    }

    &__locations {
        border: 1px solid var(--gray-dark);
        border-radius: 10px;
        padding: 16px;
        height: 455px;

        &__scrollbar {
            height: 423px;
            display: flex;
            flex-direction: column;
            overflow-y: scroll;
            padding-right: 32px;
        }

        &__scrollbar::-webkit-scrollbar {
            width: 3px;
            background: var(--gray-dark-background);
            -webkit-border-radius: 10px;
        }

        &__scrollbar::-webkit-scrollbar-thumb {
            background-color: var(--blue-dark-background);
            -webkit-border-radius: 1ex;
            height: 50px;
        }

        &__location {
            @include font-rubik(16px, var(--midnight-100), 300);
            padding: 11.5px 0;
            cursor: pointer;

            &:hover {
                @include font-rubik(false, var(--mission-orange-100), false);
            }

            &:last-child {
                margin-bottom: 16px;
            }

            &:not(:last-child) {
                border-bottom: 1px solid var(--gray-light);
            }
        }

        &__region {
            @include font-rubik(10px, var(--midnight-100), 600);
            text-transform: uppercase;
        }
    }

    &__search {
        position: relative;
        margin-bottom: 32px;
    }
}
