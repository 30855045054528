@import "../../../../css/fonts";

.cartDiscount {
    margin-bottom: 16px;

    .activeCodes {
        display: flex;
        flex-wrap: wrap;

        .code {
            @include font-rubik(14px, var(--midnight-100), 400);
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 4px;
            padding: 2px 4px;
            background-color: var(--teal-neutral);

            .icon {
                color: var(--teal-darkest);
            }
        }
    }

    .form {
        display: flex;
        align-items: center;
        width: 100%;
        gap: 16px;
        margin-bottom: 8px;

        .button {
            display: flex;
            justify-content: center;
            align-items: center;

            &--disabled {
                border: 2px solid var(--gray-neutral) !important;
                color: var(--gray-neutral) !important;
            }
        }

        .input {
            flex-grow: 1;

            input {
                background-color: var(--white);
            }
        }
    }

    .header {
        @include font-rubik(14px, var(--midnight-80), 500);
        margin-bottom: 8px;
    }
}
