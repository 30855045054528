@import "../../../../../../../../css/fonts";

.supportSquadSettingsModalManageMembers {
    display: flex;
    flex-direction: column;

    .header {
        display: flex;
        justify-content: space-between;

        .label {
            @include font-rubik(16px, var(--midnight-100), 600);
        }

        .editOption {
            @include font-rubik(16px, var(--midnight-100), 300);
            cursor: pointer;
        }
    }

    .members-list {
        max-height: 500px;
        margin-bottom: 25px;
        overflow-y: auto;
    }

    .remove-button {
        margin: 0 auto;
        max-width: 345px;
        width: 100%;
    }
}

.confirmation {
    display: flex;
    flex-direction: column;
    align-items: center;

    .text {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 50px;

        .header {
            @include font-rubik(20px, var(--midnight-100), 600);
            margin-bottom: 15px;
        }

        .description {
            text-align: center;
            max-width: 300px;
        }
    }

    .warning-icon {
        width: 45px;
        margin-bottom: 25px;
    }

    .confirmation-buttons {
        display: flex;
        flex-direction: column;
        width: 232px;

        .confirm-remove-button {
            width: 100%;
        }

        .confirm-cancel-button {
            width: 100%;
        }
    }
}
