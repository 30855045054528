@import "../../../../../../css/fonts";

.muster-recruit-invite-modal-success {
    display: flex;
    flex-direction: column;
    align-items: center;

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image {
            width: 100%;
            height: 125px;
            object-fit: cover;
        }

        .successText {
            @include font-rubik(16px, var(--midnight-100), 300);
            margin-top: 17px;
            margin-bottom: 35px;
            text-align: center;
        }
    }

    .buttons {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        width: 100%;

        .button {
            width: 100%;
            margin-bottom: 8px;
        }
    }
}
