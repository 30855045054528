@import "../../../../css/fonts";

.letterPreviewDescription {
    @include font-rubik(10px, var(--midnight-100), 400);

    &--loading {
        margin-top: auto;

        .skeleton {
            width: 100%;
            height: 6px;
        }
    }
}
