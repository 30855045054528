@import "../../../../css/fonts";
@import "../../../../css/variables";

.purchaseModalConfirmation {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .checkmark {
        margin-bottom: 15px;
    }

    .button {
        width: 100%;
    }

    .creditsText {
        margin-bottom: 30px;
        text-align: center;
    }

    .successText {
        margin-bottom: 8px !important;
        text-align: center;
    }
}
