@import "../../../fonts";
@import "../../../variables";

.letters-purchase-modal {
    &--no-padding {
        .modal-content,
        .modal-body {
            padding: 0px;
        }
    }

    &--wide {
        width: 1246px;
    }
}

.letters-compose-add-ons,
.letters-compose-purchase {
    &-container {
        width: 100%;
    }

    /**
    * TODO: Remove once module styling for bundle screen is complete
    */
    &-bundle {
        &-description {
            &-container {
                width: 100%;
                margin-bottom: 24px;
            }

            &-empty {
                color: var(--gray-darker);
                text-align: center;
            }

            &-highlight {
                position: relative;
                width: 100%;
                padding: 8px 0;
                background-color: var(--blue-lightest);
                color: var(--blue-dark);
                font-style: italic;

                &:before,
                &:after {
                    position: absolute;
                    top: 0;
                    left: -36px;
                    width: 36px;
                    height: 100%;
                    background-color: var(--blue-lightest);
                    content: "";
                }

                &:after {
                    left: 100%;
                }
            }

            &-list {
                @include font-rubik(16px, false, 400);
                padding-left: 16px;

                & > * {
                    margin-bottom: 4px;
                    color: var(--gray-darkest);
                }
            }

            &-title {
                @include font-rubik(24px, false, 700);
                display: inline-block;
                margin-bottom: 4px;
            }
        }

        &-single {
            width: 100%;
            margin-bottom: 24px;
            font-size: 20px;
            text-align: center;
        }

        &-tile {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            min-height: 148px;
            border: 1px solid var(--gray-lighter);
            border-radius: 4px;
            box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
            margin-bottom: 16px;
            text-align: center;
            cursor: pointer;

            &--has-banner {
                border-radius: 0 0 4px 4px;
            }

            &--has-second-row-promotion {
                margin-bottom: 48px;
            }

            &--selected {
                border: 1px solid var(--blue-neutral);
            }

            &-banner {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 24px;
                border-radius: 4px 4px 0 0;

                &--none {
                    height: 0;
                }
            }

            &-grid {
                display: grid;
                grid-column-gap: 16px;
                grid-template-columns: repeat(3, 1fr);
                width: 100%;
                margin-bottom: 8px;

                &.second-row-promotion {
                    margin-bottom: 0;
                }
            }

            &-header {
                &-container {
                    position: absolute;
                    top: -33px;
                    left: 0;
                    width: 100%;
                    height: 32px;
                    padding: 8px;
                    background-color: var(--blue-lightest);
                    border-radius: 4px 4px 0 0;
                    color: var(--blue-darker);
                    font-size: 12px;
                    overflow: hidden;
                    text-align: center;
                    white-space: nowrap;
                }
            }

            &-letters {
                &-container {
                    display: flex;
                    flex-direction: column;
                }

                &-description {
                    @include font-rubik(20px, var(--gray-darkest), 400);
                    transform: translateY(-4px);
                }

                &-number {
                    @include font-rubik(48px, var(--gray-darkest), 700);
                }
            }

            &-price {
                &-current,
                &-original {
                    font-size: 18px;
                }

                &-current {
                    &.promotion-active {
                        color: var(--blue-dark);
                    }
                }

                &-original {
                    display: inline-block;
                    margin-right: 4px;
                    color: var(--gray-dark);
                    text-decoration: line-through;
                }
            }
        }
    }

    &-button {
        width: 100%;
        border: none;
        padding: 16px;
        background-color: var(--gray-darkerer);
        color: var(--white);
    }

    &-payment {
        &-container {
            padding-top: 0 !important;
        }
    }

    &-payment-stripe {
        &-container {
            margin-bottom: 24px;
        }

        &-inner {
            margin-bottom: 24px;
        }

        &-cardholder-name-input {
            width: 100%;
            margin-bottom: 24px;
            color: var(--gray-darker) !important;
            font-family: sans-serif !important;
            font-weight: 500;
        }

        &-save-card {
            &-container {
                margin-top: 24px;
            }

            &-input {
                display: inline-block;
                margin: 16px 16px 0 0 !important;
            }

            &-label {
                &-text-main {
                    font-size: 18px !important;
                    transform: translateY(2px);
                }
            }
        }
    }

    &-payment-saved-cards {
        &-no-cards {
            color: var(--gray-darkerer);
            font-size: 18px;
            font-style: italic;
        }
    }

    &-payment-saved-card {
        &-container {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 70px;
            cursor: pointer;

            &.selected,
            &:hover {
                color: var(--blue-dark);
            }

            &:not(:last-child):before {
                position: absolute;
                top: 100%;
                width: 100%;
                height: 1px;
                background-color: var(--gray-lighter);
                content: "";
            }
        }

        &-icon {
            width: 30px;
            height: auto;

            &-container {
                width: 10%;
            }
        }

        &-number {
            width: 40%;
            color: var(--gray-darkerer);
            letter-spacing: 1px;
            text-align: right;
        }

        &-expiration {
            width: 20%;
            color: var(--gray-darkerer);
            text-align: right;
        }

        &-name {
            width: 30%;
            color: var(--gray-darkerer);
            text-align: right;
        }
    }

    &-review {
        &__discount {
            margin-bottom: 24px;

            &__display {
                @include font-rubik(16px, false, 700);
                position: relative;
                display: flex;
                align-items: center;
                width: 50%;
                border-radius: 4px;
                padding: 12px;

                &--active {
                    background-color: var(--gray-light);
                }

                &--error {
                    background-color: var(--orange-lighter);
                }

                &__code {
                    display: flex;
                    height: 100%;
                    align-items: center;
                    transform: translateY(1px);
                }

                &__check,
                &__warning {
                    margin-right: 12px;
                    transform: scale(1.3);
                }

                &__close {
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    cursor: pointer;
                    transform: translate(calc(-100% - 12px), -50%);
                }
            }

            &__form {
                position: relative;
                display: flex;
                flex-direction: column;
                width: 100%;
                font-size: 16px;

                &__input {
                    position: relative;
                    width: 50%;
                    margin-bottom: 8px;
                }

                &__loading {
                    position: absolute;
                    top: -1px;
                    left: 50%;
                    transform: translateX(calc(-100% - 6px));

                    img {
                        height: 40px;
                    }
                }

                &__submit {
                    @include font-rubik(false, var(--blue-darker), 500);
                    margin: 0;
                    border: none;
                    padding: 0;
                    background: none;
                    text-align: left;
                }
            }

            &__prompt {
                margin-bottom: 8px;
                color: var(--gray-darkerer);
                font-size: 16px;

                &--error {
                    color: var(--error-100);
                }
            }
        }

        &-list {
            &-container {
                margin-bottom: 24px;
            }

            &-item {
                position: relative;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 16px;
                font-size: 18px;

                &-name {
                    @include font-rubik(16px, var(--midnight-100), 400);
                }

                &.savings {
                    color: var(--blue-neutral);
                }
            }

            &-total {
                padding-top: 16px;

                &:before {
                    position: absolute;
                    top: 0;
                    width: 100%;
                    height: 1px;
                    background-color: var(--gray-lighter);
                    content: "";
                }
            }
        }

        &__payment-method {
            margin-bottom: 16px;

            &__header {
                @include font-rubik(16px, var(--midnight-100), 400);
            }
        }
    }

    &-select {
        &-options {
            display: flex;
            width: 100%;
            justify-content: space-around;
            align-items: center;
        }

        &-option {
            width: 200px;
            height: 100px;
            border: 1px solid var(--gray-darkerer);
            line-height: 100px;
            text-align: center;
        }
    }
}
