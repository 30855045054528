@import "../../../fonts";
@import "../../../responsive";
@import "../../../variables";

.letters-sent {
    &__menu {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 32px;

        &__grid {
            display: flex;
            width: 100%;
            gap: 24px;

            @include responsive($max-width: $tablet-portrait-max-width) {
                flex-direction: column;
            }
        }

        &__list {
            width: 50%;

            @include responsive($max-width: $tablet-portrait-max-width) {
                width: 100%;
            }

            &--empty {
                min-height: 168px;
            }

            &__no-letters {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 16px;
            }

            &__replied-info {
                display: flex;
                flex-direction: column;
                gap: 16px;
            }

            &__show-more {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;

                &__button {
                    width: 160px;
                }
            }

            &__title {
                margin-bottom: 8px;
            }
        }
    }
}
