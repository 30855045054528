@import "../../../../../../css/fonts";
@import "../../../../../../css/responsive";

.bundleOptions {
    .body {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 24px;

        @include responsive($max-width: $tablet-landscape-max-width) {
            flex-direction: column;
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .button {
            align-self: center;
            min-width: 50%;
            margin-bottom: 8px;
        }

        .footnotes {
            margin-bottom: 28px;

            .footnote {
                @include font-rubik(12px, #1a3545, 400);
                display: block;
                text-align: center;
            }

            .link {
                text-decoration: underline;
            }
        }
    }

    .leftColumn,
    .rightColumn {
        display: flex;
        flex-direction: column;
        width: 50%;

        @include responsive($max-width: $tablet-landscape-max-width) {
            width: 100%;
        }

        .headline {
            margin-bottom: 16px;
        }

        .listIcon {
            margin-bottom: 32px;

            .subtitle {
                margin-left: 16px;
            }
        }
    }

    .leftColumn {
        border-right: 1px solid var(--dust-120);
        padding-right: 24px;

        @include responsive($max-width: $tablet-landscape-max-width) {
            border-right: none;
            padding-right: 0;
        }

        .slider {
            align-items: center;
            display: flex;
            flex-direction: column;

            .arrows {
                top: 130px;
            }

            .image {
                border-radius: 5px;
                height: 305px;
                margin-bottom: 12px;
                width: 305px;
            }

            .subText {
                max-width: 300px;
            }
        }
    }

    .rightColumn {
        padding-left: 24px;

        @include responsive($max-width: $tablet-landscape-max-width) {
            padding-left: 0;
        }

        .divider {
            margin: 48px 0;
            background-color: var(--dust-120);
            span {
                color: var(--dust-160);
            }
        }

        .headline {
            .title {
                display: flex;
                align-items: center;
                gap: 8px;

                .pill {
                    border-radius: 4px;
                    padding: 2px 4px;
                    font-weight: 500;

                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
