@import "../../css/fonts";
@import "../../css/variables";

.text {
    &.h1 {
        @include font-rubik(36px, var(--midnight-100), 700);
    }

    &.h2 {
        @include font-rubik(24px, var(--midnight-100), 600);
    }

    &.h3 {
        @include font-rubik(20px, var(--midnight-100), 300);
    }

    &.h4 {
        @include font-rubik(16px, var(--midnight-100), 300);
    }

    &.p {
        @include font-rubik(14px, var(--midnight-100), 300);
        margin-bottom: 0px;
    }

    &.body {
        @include font-rubik(16px, var(--midnight-100), 400);
        font-style: normal;
        letter-spacing: 0.75px;
        line-height: 16px;
    }

    &.caption {
        @include font-rubik(12px, var(--midnight-100), 300);
    }

    &.small {
        @include font-rubik(10px, var(--midnight-100), 300);
    }

    &--bold {
        font-weight: 600 !important;
    }

    &--centered {
        text-align: center;
    }
}
