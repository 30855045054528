.protectedPage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #e5e5e5;
}
