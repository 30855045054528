@import "../../css/fonts";

.content {
    .headerText {
        @include font-rubik(24px, var(--midnight-100), 600);
    }

    .secondaryText {
        @include font-rubik(16px, var(--midnight-100), 300);
    }

    .iconHolder {
        border: none;
        background-color: transparent;
        height: 24px;
        padding: 0;
        position: static;

        .interstitialClose {
            color: var(--gray-darker);
            cursor: pointer;
            transition: all 0.25s ease-out;

            &:hover {
                color: var(--gray-darkest);
            }
        }
    }

    .carousel {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: baseline;
        height: 385px;

        .container {
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 385px;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;

                .image {
                    height: 300px;
                    width: 327px;
                }

                .description {
                    @include font-rubik(14px, var(--midnight-100), 300);
                    margin-top: 22px;
                    text-align: center;
                    width: 340px;
                }
            }
        }

        .markers {
            display: flex;
            justify-content: center;

            .marker {
                width: 8px;
                height: 8px;
                border-radius: 50%;
                background-color: #d1d1d1;
                margin-right: 8px;
                cursor: pointer;

                &--selected {
                    background-color: var(--mission-orange-100);
                }
            }
        }
    }

    .buttons {
        display: flex;
        justify-content: center;
        margin-top: 100px;
        .button {
            width: 340px;
        }
    }
}

.dialog {
    width: 500px;
}
