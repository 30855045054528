@import "../../fonts";
@import "../../variables";

.avatar {
    width: 40px;
    height: 40px;
    border-radius: 50%;

    &-without-image {
        @include font-rubik(14px, var(--blue-dark-background), 700);
        position: relative;
        background-color: var(--gray-dark-background);
        vertical-align: middle;

        &-initial {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, calc(-50% + 1px));
        }
    }

    &--square {
        border-radius: 5px;
    }

    &--blue {
        @include font-rubik(14px, var(--blue-darker), 700);
        background-color: var(--blue-light);
    }
}
