@import "../../../../../../css/fonts";
@import "../../../../../../css/variables";

.support-squad-donate-tokens-modal-confirm {
    display: flex;
    flex-direction: column;
    align-items: center;

    .buttons {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .button {
            width: 75%;

            &:first-child {
                margin-bottom: 8px;
            }
        }
    }

    .checkbox {
        margin-bottom: 26px;

        span {
            @include font-rubik(16px, var(--midnight-100), 300);
        }
    }

    .text {
        @include font-rubik(16px, var(--midnight-100), 300);
        margin-bottom: 66px;
        text-align: center;
    }
}
