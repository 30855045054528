@import "../../../css/fonts";

.buttonRounded {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: 1px solid var(--dust-120);
    border-radius: 10px;
    color: var(--midnight-100);
    padding: 24px 16px;

    &[disabled] {
        border: 2px solid var(--gray-light) !important;
        box-shadow: none;
        color: var(--gray-light) !important;
        cursor: not-allowed !important;
    }

    &--filled {
        background-color: var(--blue-darkerer);
        box-shadow: none;
        color: var(--white);
    }
}
