@import "../../css/fonts";
@import "../../css/variables";

.carousel {
    position: relative;
    max-width: 100%;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;

        .actions {
            display: flex;
            align-items: center;

            .controller {
                display: flex;
                align-items: center;
                gap: 8px;

                .arrowContainer {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: var(--dust-20);
                    cursor: pointer;
                    transition: opacity 0.5s ease-out;

                    &--disabled {
                        opacity: 0.25;
                        cursor: not-allowed;
                    }

                    .arrow {
                        @include preventTextSelect;
                    }
                }
            }

            .links {
                margin-right: 16px;

                .link {
                    @include font-rubik(16px, var(--midnight100), 500);
                }
            }
        }

        .title {
            font-size: 16px;
        }
    }

    .wrapper {
        display: flex;
        scrollbar-width: none;
        transition: transform 0.5s ease-in-out;
        will-change: transform;
        white-space: nowrap;
        -ms-overflow-style: none;

        .item {
            display: inline-flex;
            flex: 0 0 auto;
            width: auto;
        }
    }
}
