@import "../../../../../../css/fonts";

.weeklyUpdatesListItem {
    display: flex;
    width: 100%;

    &:not(:last-child) {
        margin-bottom: 24px;
    }

    /** Layout **/

    .left,
    .right {
        position: relative;
        width: 50%;
    }

    .left {
        padding: 24px;
        background-position: center;
        background-size: cover;
        border-radius: 8px 0 0 8px;
    }

    .right {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        border-radius: 0 8px 8px 0;
        padding: 32px 64px;
        background-color: var(--white);
    }

    /** End Layout **/

    .consumedStatus {
        position: absolute;
        top: 50%;
        left: 100%;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: var(--mission-orange-100);
        transform: translate(calc(-100% - 16px), 0);
    }

    .text {
        @include font-rubik(16px, var(--midnight-100), 300);
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 200px;
        margin-bottom: 24px;
        overflow: hidden;

        &:before {
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: 64px;
            background-image: linear-gradient(
                to bottom,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 1) 90%
            );
            z-index: 1;
            transform: translate(0, -100%);
            content: "";
        }
    }

    .title {
        margin-bottom: 16px !important;
    }

    .week {
        position: absolute;
        top: 0;
        left: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 16px 24px;
        background-color: var(--blue-light);
        transform: translate(calc(-100% - 24px), 24px);

        .description {
            @include font-rubik(18px, var(--midnight-100), 300);
        }

        .number {
            @include font-rubik(48px, var(--blue-darkerer), 500);
            line-height: 40px;
        }
    }
}
