@import "../../css/fonts";
@import "../../css/variables";

.carousel {
    position: relative;
    overflow: hidden;

    &:hover {
        .nav-internal {
            .arrow {
                opacity: 1;
            }
        }
    }

    .arrows {
        display: flex;

        .arrow {
            display: inline-block;
            cursor: pointer;
            transition: opacity 0.25s ease-out;

            &:first-child {
                padding-right: 8px;
                border-right: 1px solid var(--gray-light);
            }

            &:nth-child(2) {
                padding-left: 8px;
            }

            &--disabled {
                cursor: default;
                opacity: 0.5;
            }
        }
    }

    .nav-internal {
        position: absolute;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;

        .arrow {
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: var(--mission-orange-100);
            cursor: pointer;
            opacity: 0;
            transition: background-color 0.25s ease-out, opacity 0.25s ease-out;
            z-index: 100;
            height: 36px;
            width: 36px;

            &:hover,
            &--disabled {
                background-color: var(--orange-lighter);
            }

            &:focus {
                opacity: 1;
            }

            span {
                color: var(--white);
                font-size: 36px;
            }
        }
    }

    .wrapper {
        position: relative;
        display: inline-flex;
        overflow: hidden;
    }
}
