@import "../../../../css/fonts";
@import "../../../../css/variables";

.profile-notification-corner {
    display: flex;
    flex-direction: column;

    .button {
        align-self: flex-start;
    }

    .text {
        @include font-rubik(16px, var(--midnight-100), 400);
        margin-bottom: 16px;
    }
}
