@import "../../../../../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-recipient-new-recipient-input {
    grid-column: span 2;

    &[for="city"],
    &[for="line1"],
    &[for="line2"] {
        grid-column: 1 / span 4;
    }

    span {
        @include font-rubik(16px, var(--gray-darkerer), 300);
    }

    .input {
        width: 100%;
        height: 100%;
    }

    .select {
        @include font-rubik(14px, var(--midnight-100), 300);
        height: 35px !important;
    }
}
