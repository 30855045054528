@import "../../../../../css/variables";

.sign-up-ship-date {
    .navigation-buttons {
        margin-top: 24px;
        height: unset;
    }

    .options {
        margin-top: 32px !important;
    }

    .select {
        background-color: var(--dust-100);
    }

    .top {
        margin-bottom: 48px;
    }

    .yes-button {
        margin-bottom: 16px;
    }
}
