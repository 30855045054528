.wallet-gift-cards-purchase {
    padding: 16px;

    .panel {
        padding: 40px;

        .body {
            display: flex;
            padding: 40px 0 0 0;

            .column {
                &-left {
                    width: 70%;
                    border-right: 1px solid var(--gray-darkerer);
                    padding-right: 40px;
                }

                &-right {
                    width: 30%;
                    padding-left: 40px;
                }
            }

            &--full-width {
                .column-left {
                    width: 100%;
                    border-right: none;
                    padding-right: 0;
                }

                .column-right {
                    display: none;
                    width: 0;
                    padding-left: 0;
                }
            }
        }

        .header {
            border-bottom: 1px solid var(--gray-darkerer);
            padding: 24px 40px 40px 40px;
            text-align: center;

            &--hidden {
                display: none;
            }

            p {
                margin-top: 8px;
            }
        }
    }
}
