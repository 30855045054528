@import "../../../fonts";
@import "../../../variables";

.letters-compose-add-ons {
    &__modal {
        .modal-dialog {
            width: 800px;
        }
    }

    &__select-amount {
        &__amounts {
            display: grid;
            grid-column-gap: 24px;
            grid-row-gap: 24px;
            grid-template-columns: repeat(3, 1fr);
            margin-bottom: 48px;

            &__amount {
                width: 100%;
                border-width: 2px !important;

                &--selected {
                    @include font-rubik(false, var(--mission-orange-100), 700);
                    border: 2px solid var(--mission-orange-100) !important;
                }
            }
        }

        &__design {
            display: flex;
            justify-content: center;
            margin-bottom: 48px;
        }
    }

    &__select-design {
        &__designs {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            grid-column-gap: 16px;
            justify-items: center;
            width: 100%;
            margin-bottom: 24px;

            & > * {
                margin-bottom: 16px;
            }
        }
    }

    &__select-items {
        width: 100%;

        &__list {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 0 24px 0;

            & > * {
                padding: 24px 0;
                margin: 0 !important;

                &:first-child {
                    padding-top: 0;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid var(--gray-light);
                }

                &:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }
}

.letters-compose__add-ons__carousel {
    border-top: 1px solid var(--gray-dark-background);
    padding: 36px 0;
    overflow: hidden;

    &__arrows {
        display: flex;

        &__arrow {
            display: inline-block;
            cursor: pointer;
            transition: opacity 0.25s ease-out;
            border: none;
            background-color: transparent;

            &:first-child {
                padding-right: 8px;
                border-right: 1px solid var(--gray-light);
            }

            &:nth-child(2) {
                padding-left: 8px;
            }

            &--disabled {
                cursor: default;
                opacity: 0.5;
            }
        }
    }

    &__wrapper {
        display: inline-flex;
        gap: 40px;
        position: relative;
        overflow: hidden;
        width: 100%;
    }
}

.gift-card {
    position: relative;
    width: 256px;
    height: 160px;
    border-radius: 16px;
    background-size: cover;
    cursor: pointer;

    &--selected {
        &:before {
            position: absolute;
            top: -4px;
            left: -4px;
            width: calc(100% + 8px);
            height: calc(100% + 8px);
            border: 2px solid var(--mission-orange-100);
            border-radius: 16px;
            content: "";
        }
    }
}
