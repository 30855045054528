.lettersAddOnsCarouselItem {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 50%;
    padding-right: 48px;
    overflow-x: auto;

    &--inModal {
        height: unset;
        margin-bottom: 32px;
        padding-right: 0;

        .right {
            padding-right: 0;
        }
    }

    .details {
        display: flex;

        .image {
            width: 40%;
            margin-right: 16px;
            overflow: hidden;

            img {
                aspect-ratio: 1 / 1;
                width: 100%;
                margin-bottom: 16px;
                border-radius: 8px;
            }

            .button {
                width: 100%;
                font-size: 14px;
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            width: 100%;

            .description {
                width: 90%;
                margin-bottom: 16px;
                font-size: 14px;
            }

            .subtitle {
                margin-bottom: 8px;
                font-size: 14px;
            }

            .title {
                align-items: center;
                display: flex;
                justify-content: space-between;
                font-size: 18px;

                .icon {
                    transform: translateY(-2px);
                    cursor: pointer;
                }

                .text {
                    margin-right: 8px;
                }
            }
        }
    }
}
