@import "../../fonts";
@import "../../variables";

.notification-bar {
    @include font-rubik(16px, false, 400);
    position: fixed;
    top: -60px;
    left: $navigation-width;
    display: flex;
    align-items: center;
    width: calc(100% - $navigation-width);
    height: 64px;
    padding: 16px 48px;
    opacity: 0;
    transition: all 0.25s ease-out;
    z-index: 1000000;

    @include phone {
        left: 0;
        width: 100%;
        padding: 16px 24px;
    }

    &__icon {
        margin-right: 12px;
        transform: translateY(-1px);
    }

    /* Open animation */

    &--animate-open {
        animation-name: notificationBarOpen;
        animation-fill-mode: forwards;
    }

    @keyframes notificationBarOpen {
        from {
            opacity: 0;
            transform: translate(0, 0);
        }

        1% {
            opacity: 1;
            transform: translate(0, 0);
        }

        5% {
            opacity: 1;
            transform: translate(0, 60px);
        }

        94% {
            opacity: 1;
            transform: translate(0, 60px);
        }

        95% {
            opacity: 1;
            transform: translate(0, 60px);
        }

        to {
            opacity: 0;
            transform: translate(0, 0);
        }
    }

    /* Type of notification */

    &--info {
        background-color: var(--blue-light);
        color: var(--blue-darkerer);
    }

    &--success {
        background-color: var(--green);
        color: var(--teal-darkest);
    }

    &--warning {
        background-color: var(--orange-lighter);
        color: var(--orange-darkest);
    }
}
