.cart {
    position: relative;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    padding: 16px;
    background-color: var(--dust-100);

    .currentSection {
        position: relative;
        flex-grow: 1;
        height: 100%;
    }
}

.paper {
    width: 25%;
    background-color: var(--dust-100) !important;
}
