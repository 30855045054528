@import "../../css/fonts";

.quantitySelector {
    display: flex;
    align-items: center;
    background-color: #f0f0f0;
    border-radius: 4px;

    .button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 18px;
        height: 18px;
        border: 2px solid var(--blue-darkerer);
        border-radius: 50%;
        line-height: 30px;
        text-align: center;
        background-color: transparent;
        color: #333;
        cursor: pointer;
        transition: all 0.1s ease-out;

        &:hover {
            background-color: var(--blue-darkerer);
            color: var(--white);
        }

        &--disabled {
            filter: grayscale(1);
            opacity: 0.25;
            pointer-events: none;
        }
    }

    .number {
        @include font-rubik(16px, false, 500);
        margin: 0 8px;
    }
}
