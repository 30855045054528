@import "../../../../css/fonts";
@import "../../../../css/variables";

.purchase-modal-bundle {
    display: flex;
    flex-direction: column;
    width: 100%;

    .bundles {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 16px;
        padding: 0px 20px;
        width: 100%;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 30px;
    }

    .note {
        @include font-rubik(12px, var(--midnight-100), 300);
    }
}
