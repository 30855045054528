@import "../../css/fonts";

.toggle-switch {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .switch {
        display: flex;
        align-items: center;

        .input {
            height: 0;
            width: 0;
            visibility: hidden;
        }

        .input:checked + .label .button {
            left: calc(100% - 2px);
            transform: translateX(-100%);
        }

        .label {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 52px;
            height: 32px;
            border-radius: 100px;
            background: grey;
            cursor: pointer;
            transition: background-color 0.2s;

            &--checked {
                background-color: var(--blue-dark-background);
            }

            .button {
                position: absolute;
                top: 2px;
                left: 2px;
                width: 28px;
                height: 28px;
                border-radius: 50%;
                background: #fff;
                box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
                content: "";
                transition: 0.2s;
            }
        }
    }

    .text {
        display: flex;
        align-items: center;

        span {
            @include font-rubik(16px, var(--midnight-100), 400);
        }
    }
}
