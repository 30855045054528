@import "../../../../css/fonts";

.support-squad-leave-modal {
    .buttons {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .button {
            width: 100%;
            margin-top: 8px;
        }
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .headerText {
            @include font-rubik(20px, var(--midnight-100), 600);
            margin-top: 15px;
            margin-bottom: 10px;
        }

        .bodyText {
            @include font-rubik(16px, var(--midnight-100), 300);
            text-align: center;
        }

        .membersHeader {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .title {
                @include font-rubik(16px, var(--midnight-100), 600);
            }
        }
    }
}
