@import "../../fonts";
@import "../../variables";

.checkbox,
.radio {
    &-container {
        position: relative;
        margin-bottom: 24px;

        &:not(:last-child) {
            margin-bottom: 30px;
        }
    }

    &-input {
        position: absolute;
        left: -9999px;

        /* Style label when checked */
        &:checked + label {
        }

        &:focus + label::before {
            outline: blue solid 1px;
        }
    }

    &-label {
        @include font-rubik(false, false, 400);
        position: relative;
        left: 35px;
        display: block;

        &-text {
            position: relative;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;

            &-main {
                @include font-rubik(16px, var(--gray-darkerer), 400);
                position: relative;
                transform: translateY(3px);

                &-tag {
                    @include font-rubik(12px, false, 400);
                    display: inline-block;
                    margin-left: 7px;
                    border-radius: 2px;
                    padding: 5px;
                    transform: translateY(-2.5px);

                    &.primary {
                        background-color: var(--blue-neutral);
                        color: var(--white);
                    }

                    &.secondary {
                        border: 1px solid var(--blue-neutral);
                        background-color: var(--white);
                        color: var(--blue-neutral);
                    }

                    &.sale {
                        background-color: var(--mission-orange-100);
                        color: var(--white);
                    }
                }
            }

            &-secondary {
                color: var(--gray-darkerer);
                font-weight: 300;
            }

            &-super {
                position: absolute;
                top: 0;
                left: 0;
                color: var(--blue-neutral);
                font-style: italic;
                transform: translateY(-18px);
            }
        }

        /* Style box when not checked */
        &:before {
            position: absolute;
            top: 5px;
            left: -35px;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 30px;
            border: 1px solid var(--gray-darkerer);
            border-radius: 50%;
            color: white;
            font-family: "Material Icons", sans-serif;
            font-size: 20px;
            font-weight: 100;
            line-height: 20px;
            text-align: center;
            transition: all 0.1s ease-out;
            content: "";
        }
    }
}

.checkbox {
    &-input {
        /* Style box when checked */
        &:checked + label:before {
            border: 1px solid var(--blue-darker);
            background-color: var(--blue-darker);
            content: "";
        }

        &:checked + label:after {
            position: absolute;
            top: 1px;
            left: -29px;
            color: var(--white);
            font-size: 18px;
            transform: scaleX(-1) rotate(-43deg) translate(0px, 1px);
            content: "L";
        }
    }

    &-label {
        /* Style box when not checked */
        &:before {
            position: absolute;
            top: 5px;
            left: -35px;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 30px;
            border: 1px solid var(--gray-darkerer);
            border-radius: 2px;
            color: white;
            font-family: "Material Icons", sans-serif;
            font-size: 20px;
            font-weight: 100;
            line-height: 20px;
            text-align: center;
            transition: all 0.1s ease-out;
            content: "";
        }
    }
}

.radio {
    &-input {
        /* Style box when checked */
        &:checked + label:before {
            border: 1px solid var(--blue-neutral);
            background-color: var(--white);
            content: "";
        }

        &:checked + label:after {
            position: absolute;
            top: 8.5px;
            left: -31px;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: var(--blue-neutral);
            content: "";
        }
    }

    &-label {
        /* Style box when not checked */
        &:before {
            position: absolute;
            top: 5px;
            left: -35px;
            display: inline-block;
            width: 20px;
            height: 20px;
            margin-right: 30px;
            border: 1px solid var(--gray-light);
            border-radius: 50%;
            color: white;
            font-family: "Material Icons", sans-serif;
            font-size: 20px;
            font-weight: 100;
            line-height: 20px;
            text-align: center;
            transition: all 0.1s ease-out;
            content: "";
        }
    }
}
