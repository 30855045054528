@import "../../../../../../css/fonts";

.musterOverviewHeader {
    background-color: var(--dust-100);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin-bottom: 32px;
    min-height: 370px;
    padding: 28px 28px 25px 48px;
    position: relative;
    width: 100%;

    .body {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        flex-direction: column;
    }

    .graphic {
        bottom: 0px;
        right: 0px;
        position: absolute;
        width: 50%;
    }

    .header {
        display: flex;
        justify-content: space-between;
        flex: 1;

        .editButton {
            @include font-rubik(16px, var(--pacific-blue-100), 600);
        }

        .text {
            @include font-rubik(14px, var(--midnight-100), 600);
            padding-bottom: 16px;
        }
    }

    .pencilIcon {
        margin-left: 10px;
    }

    .recruitingStationText {
        max-width: 300px;
        cursor: pointer;
    }

    .recruiterInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 40px;
        margin-top: 40px;
    }

    .recruiterHeadline {
        font-size: 48px !important;
    }

    .seeAllRecruitsButton {
        color: var(--pacific-blue-100);
        height: 55px;
    }

    .shipDateInfo {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-height: 120px;
        margin-top: 40px;

        .data {
            @include font-rubik(24px, #345168, 600);
        }

        .header {
            @include font-rubik(20px, var(--midnight-100), 300);
        }
    }
}
