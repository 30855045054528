@import "../../../../../css/fonts";

.signInMain {
    .banner {
        .text {
            display: flex;
            flex-direction: column;

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .divider {
        position: relative;
        height: 1px;
        margin: 32px 0;
        background-color: var(--gray-darker);

        span {
            @include font-rubik(16px, var(--gray-darker), 300);
            position: absolute;
            top: 50%;
            left: 50%;
            display: inline-block;
            padding: 0 8px;
            background-color: var(--dust-100);
            transform: translate(-50%, -50%);
        }
    }

    .forgot-password-link {
        color: var(--gray-darkerer);
        cursor: pointer;
        text-decoration: underline;
    }

    .form {
        position: relative;
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;

        .container {
            width: 100%;
            margin-bottom: 16px;
        }

        .input {
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }

        .submit {
            margin-top: 24px;
        }
    }

    .header {
        .text {
            margin-bottom: 0 !important;
        }
    }

    .platformButtons {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 24px 0;

        .button {
            @include font-rubik(18px, var(--white), 500);
            display: flex;
            justify-content: center;
            gap: 16px;
            width: 100%;
            margin-bottom: 16px;
            background-color: var(--mission-orange-100);
        }
    }

    .policies {
        @include font-rubik(14px, var(--gray-darkerer), 300);

        .policy {
            &:last-of-type {
                margin-bottom: 0;
            }

            &:not(:last-of-type) {
                margin-bottom: 24px;
            }

            .link {
                @include font-rubik(false, var(--gray-darkerer), 300);
                text-decoration: underline !important;
            }
        }
    }
}
