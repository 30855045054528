@import "../../fonts";
@import "../../variables";

.loading {
    &-overlay {
        &-container {
            position: absolute;
            top: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            height: 100%;
            min-height: 62px;
            background-color: rgba(var(--dust-100), 0.5);
        }

        &-image {
            width: 62px;
            height: 62px;
            transform: translateY(24px);
        }
    }

    &-page {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background-color: #e5e5e5;

        &-image {
            width: 100px;
            height: auto;
        }

        &-text {
            @include font-rubik(18px, var(--mission-orange-100), 300);
            transform: translateY(-20px);
        }
    }

    &-section {
        &-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
        }
    }

    &-sent-letter-preview {
        &-container {
            display: flex;
        }

        &-content {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 60%;

            & > * {
                height: 16px;
            }

            & > *:first-child {
                width: 100%;
            }

            & > *:nth-child(2) {
                width: 60%;
            }

            & > *:last-child {
                width: 50%;
            }
        }

        &-item {
            position: relative;
            background-color: var(--gray-lighter);

            &.image {
                width: 72px;
                height: 72px;
                margin-right: 24px;
                overflow: hidden;
                z-index: 1000;
            }
        }
    }
}

.loading {
    overflow: hidden;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(
            90deg,
            transparent,
            rgba(255, 255, 255, 0.2),
            transparent
        );
        animation: loading-skeleton 1.5s infinite;
        transform: translateX(-100%);
        content: "";
    }
}

@keyframes loading-skeleton {
    100% {
        transform: translateX(100%);
    }
}
