@import "../../../../../../../../css/fonts";

.wallet-manage-add-card-modal-manual-input {
    .button {
        width: 100%;
        margin-top: 64px;
    }

    .form {
        display: flex;
        flex-direction: column;

        .input {
            &:not(:last-child) {
                margin-bottom: 40px;
            }
        }

        .label {
            display: flex;
            flex-direction: column;
            margin-bottom: 14px;

            .text {
                @include font-rubik(16px, var(--midnight-100), 600);
            }

            .text-secondary {
                @include font-rubik(14px, var(--gray-darkest), 300);
            }
        }
    }
}
