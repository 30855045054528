.cartLineItems {
    margin-bottom: 16px;

    .item {
        display: flex;
        justify-content: space-between;
        width: 100%;
        font-size: 14px;

        &:last-child {
            margin-top: 14px;
            font-size: 16px;
        }

        &:not(:last-child) {
            margin-bottom: 2px;
        }

        &--discount {
            color: var(--teal-darkest);
        }

        .value {
            text-align: right;
        }
    }
}
