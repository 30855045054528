.cartHeader {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    border-bottom: 1px solid var(--gray-dark);
    padding-bottom: 8px;

    .icon {
        cursor: pointer;
    }
}
