.personModalAddressManual {
    .form {
        .banner {
            margin-bottom: 24px;

            .link {
                text-decoration: underline !important;
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }

        .input {
            width: 100%;
            margin-bottom: 24px;
        }
    }
}
