@import "../../../../../../css/fonts";
@import "../../../../../../css/responsive";

.supportSquadDetailHeader {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 16px;
    margin-bottom: 0;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @include responsive($max-width: $desktop-small-min-width) {
            flex-direction: column;
            gap: 8px;
        }
    }

    .body {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include responsive($max-width: $desktop-small-max-width) {
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;
        }

        .buttons {
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 16px;

            @include responsive($max-width: $desktop-small-max-width) {
                flex-direction: row;
            }
        }

        .metrics {
            display: flex;
            gap: 16px;

            .metric {
                aspect-ratio: 1 / 1;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                background-color: var(--pacific-blue-20);
                border-radius: 8px;
                padding: 16px;

                .label {
                    @include font-rubik(16px, var(--midnight-100), 300);
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                }

                .value {
                    @include font-rubik(36px, var(--blue-darkerer), 700);
                }
            }
        }
    }

    .graduationProgressBar {
        padding: 0;
    }
}
