@import "../../../../../css/fonts";
@import "../../../../../css/variables";

.sign-up-recruiting-station {
    .bottom {
        display: flex;
        justify-content: space-between;
    }

    .dep-locations {
        background-color: var(--dust-100);
        border: 1px solid var(--gray-dark);
        border-radius: 5px;
        height: 400px;
        margin-bottom: 24px;
        padding: 16px;
        overflow-y: auto;
        width: 100%;

        .region {
            @include font-rubik(10px, var(--midnight-100), 600);
            text-transform: uppercase;
        }

        .divider {
            height: 1px;
            width: 100%;
            background-color: #e1e0dd;
            margin-bottom: 16px;
            margin-top: 16px;
        }
    }

    .no-results {
        display: flex;
        justify-content: center;
        height: 100%;
        margin-top: 25px;
    }

    .search-field {
        margin-bottom: 24px;
        position: relative;

        input {
            background-color: transparent;
            border: 1px solid #757575;
            border-radius: 4px;
            padding: 12px 8px;
            position: relative;
            width: 100%;

            &__placeholder {
                @include font-rubik(16px, var(--gray-darkerer), 300);
                position: absolute;
                top: 0;
                left: 8px;
                display: flex;
                align-items: center;
                pointer-events: none;
                transform: translate(0, 8px);

                .material-icons {
                    margin-right: 4px;
                    transform: translate(0, 25%);
                }
            }
        }
    }
}
