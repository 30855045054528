.orderHistoryItem {
    display: flex;
    flex-direction: column;
    gap: 8px;
    border-radius: 8px;

    .date {
        width: 100%;
        font-size: 12px;
    }

    .products {
        display: flex;
        flex-direction: column;

        .product {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 16px;
        }
    }

    .orderNumber {
        display: flex;
        gap: 4px;
        width: 100%;
        font-size: 12px;
    }

    .paymentMethod {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 12px;

        .icon {
            width: 32px;
        }
    }
}
