@import "../../css/fonts";
@import "../../css/responsive";
@import "../../css/variables";

.sign-in {
    height: 100%;
    width: 100%;

    .grid {
        position: relative;
        display: grid;
        grid-template-columns: 50% 50%;
        width: 100%;
        height: 100%;
        background-color: var(--dust-100);

        @include responsive($max-width: $desktop-small-min-width) {
            display: flex;
            width: 100%;
        }

        & > * {
            height: 100%;
        }

        .gridContent {
            position: relative;
            display: flex;
            flex-direction: column;
            height: 100%;
            max-height: 100%;
            overflow-y: auto;
        }

        .mainHeader {
            position: relative;
            width: 100%;
            height: 75px;
            background-color: var(--dust-100);

            .logo {
                position: absolute;
                top: 32px;
                left: 32px;
                width: 200px;
                height: auto;
                z-index: 999;
            }
        }
    }

    .address-formatter {
        border-top: none;

        .panel {
            padding-left: 0;
            padding-right: 0;
            background-color: transparent;
            box-shadow: none;
        }
    }

    .carousel {
        align-items: center;
        background-color: #d0cbbf;
        display: flex;
        flex-direction: column;
        grid-column: 2;
        justify-content: center;
        position: relative;
        padding: 150px 112px;
        overflow: hidden;

        @include tablet {
            display: none;
        }

        .container {
            position: relative;
            display: flex;
            justify-content: center;
            width: 100%;
            height: 100%;
            max-height: 660px;
            margin-bottom: 32px;

            .item {
                display: flex;
                flex-direction: column;
                align-items: center;

                .image {
                    margin-bottom: 56px;
                }

                .text {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    height: 80px;
                    text-align: center;

                    .header {
                        @include font-rubik(36px, #1f262a, 700);
                    }

                    .subheader {
                        @include font-rubik(20px, #1f262a, 300);
                        max-width: 521px;
                    }
                }
            }
        }

        .markers {
            display: flex;
            justify-content: center;

            .marker {
                width: 10px;
                height: 10px;
                border-radius: 50%;
                background-color: white;
                margin-right: 16px;
                cursor: pointer;

                &--selected {
                    background-color: var(--mission-orange-100);
                }
            }
        }
    }

    .content {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-height: 100%;
        padding: 40px 200px 100px 120px;
        overflow-y: auto;

        @include desktop-small {
            padding: 40px 144px 72px 144px;
        }

        @include responsive(
            $min-width: $desktop-small-min-width,
            $max-width: $desktop-small-max-width
        ) {
            padding: 40px 64px 100px 64px;
        }

        @include responsive($max-width: $desktop-small-min-width) {
            width: 100vw;
        }

        @include responsive($max-width: $tablet-portrait-max-width) {
            padding: 40px 64px 100px 64px;
        }

        @include firefox {
            scroll-padding-bottom: 48px;
        }

        & > * {
            width: 100%;
        }

        .bottom {
            display: flex;
            flex-direction: column;

            .actions {
                display: flex;
                justify-content: center;

                span {
                    @include font-rubik(14px, var(--blue-darkerer), 600);
                }
            }

            .buttons {
                display: flex;
                justify-content: space-between !important;

                &--single {
                    justify-content: flex-start !important;

                    .button {
                        width: 100%;
                    }
                }
            }

            .card {
                cursor: pointer;
                width: 100%;
                height: 95px;
                display: flex;
                margin-top: 27px;

                .cardBodyContent {
                    width: 70%;
                    max-width: 300px;

                    .cardHeader {
                        line-height: 24px;
                        @include font-rubik(20px, var(--midnight-100), 600);
                    }

                    .cardSubText {
                        line-height: 21px;
                        @include font-rubik(16px, var(--midnight-100), 300);
                    }
                }

                .cardArrowHolder {
                    margin-left: auto;
                    margin-right: 0;
                }

                .cardIconHolder {
                    padding-right: 18px;
                }

                .icon {
                    height: 35px;
                    width: 35px;
                    margin-top: 20px;
                }

                .iconSmall {
                    height: 18px;
                    width: 18px;
                    margin-top: 30px;
                }
            }

            .card:first-of-type {
                border-bottom: 2px solid #dedede;
            }

            .text {
                @include font-rubik(16px, var(--gray-darkest), 300);
                margin-top: 16px;

                &--single {
                    text-align: center;
                }
            }
        }

        .form {
            position: relative;
            display: flex;
            flex-direction: column;

            &--verification-code {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-top: 72px;
            }

            .input {
                width: 100%;
                background-color: transparent;

                &:last-of-type {
                    margin-bottom: 0 !important;
                }

                &--has-secondary {
                    margin-bottom: 10px !important;
                }
            }

            .input-header {
                @include font-rubik(16px, var(--gray-darkerer), 300);
                margin-bottom: 8px;
            }

            .label {
                @include font-rubik(16px, var(--gray-darkerer), 300);
                margin-bottom: 8px;
            }

            .text-secondary {
                @include font-rubik(16px, var(--gray-darker), 300);

                &--phone-intl {
                    display: block;
                    margin-top: 24px !important;
                }
            }
        }

        .tiles {
            display: grid;
            width: 100%;
            grid-column-gap: 16px;
            grid-template-columns: repeat(2, calc(50% - 8px));

            & > .tile {
                margin-bottom: 16px;
            }

            &--full-width {
                display: flex;
                flex-direction: column;

                .tile {
                    width: 100%;
                    margin-bottom: 16px;
                }
            }

            .tile {
                @include font-rubik(20px, var(--blue-darkerer), 600);
                position: relative;
                display: flex;
                flex-direction: column;
                justify-content: center;
                border: 1px solid var(--blue-darkerer);
                padding: 16px;
                background-color: var(--dust-100);
                cursor: pointer;
                overflow: hidden;

                &--selected {
                    background-color: var(--blue-darkerer);

                    &:before {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: calc(100% + 8px);
                        height: calc(100% + 8px);
                        border: 1px solid var(--blue-darkerer);
                        transform: translate(-4px, -4px);
                        content: "";
                    }

                    .description,
                    .name {
                        color: var(--white) !important;
                    }
                }

                .description {
                    @include font-rubik(16px, var(--blue-darkerer), 300);
                    width: 50%;
                    margin-top: 4px;
                    text-align: left;
                }

                .image {
                    position: absolute;
                    left: 100%;
                    top: 0;
                    width: auto;
                    height: 100%;
                    margin-top: 0;
                    transform: translate(-100%, 0);
                }

                .name {
                    @include font-rubik(20px, var(--blue-darkerer), 600);
                    text-align: left;
                }
            }
        }

        .top {
            .connection {
                .header {
                    @include font-rubik(16px, var(--midnight-100), 600);
                    margin-bottom: 0;
                }

                .item {
                    width: 60%;
                    border-bottom: 1px solid var(--gray-neutral);
                    padding-bottom: 16px;
                }
            }

            .dep-location {
                display: flex;
                flex-direction: column;
                margin-top: 80px;

                .selection {
                    display: flex;
                    flex-direction: column;
                    margin-bottom: 16px;

                    .description {
                        @include font-rubik(20px, var(--gray-darkest), 300);
                    }

                    .title {
                        @include font-rubik(20px, var(--midnight-100), 600);
                    }
                }
            }

            .header {
                display: flex;
                flex-direction: column;
                margin-bottom: 36px;

                .text {
                    @include font-rubik(36px, var(--midnight-100), 700);
                    margin-bottom: 16px;
                    line-height: 42px;
                }

                .text-secondary {
                    @include font-rubik(14px, var(--gray-darkest), 300);
                    display: inline-block;
                    margin-top: 2px;
                }
            }

            .image {
                display: flex;
                justify-content: center;
                width: 100%;
                margin-top: 12px;

                img {
                    width: 100%;
                }
            }

            .link {
                @include font-rubik(16px, false, false);
            }

            .login-buttons {
                display: flex;
                flex-direction: column;
                align-items: center;
                margin-bottom: 24px;

                .button {
                    width: 100%;
                    margin-bottom: 24px;

                    &--email {
                        height: 64px;
                    }
                }

                .recaptcha {
                    @include font-rubik(false, var(--gray-darker), 300);
                    text-align: center;
                }
            }

            .options {
                display: flex;
                flex-direction: column;
                margin-top: 64px;
            }
        }
    }

    .loading {
        * {
            transform: translateX(0);
        }
    }

    .ship-date {
        .select-date {
            display: flex;
            flex-direction: column;
            margin-top: 32px;

            .inputs {
                display: grid;
                grid-column-gap: 8px;
                grid-template-columns: repeat(3, calc(33.33% - 5.333px));
            }

            .text {
                @include font-rubik(20px, var(--gray-darkest), 300);
                margin-bottom: 16px;
            }
        }
    }

    .slideshow {
        position: relative;
        grid-column: 2;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow: hidden;

        @include responsive($max-width: $desktop-small-min-width) {
            display: none;
        }

        .image {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-position: center;
            background-size: cover;
            overflow: hidden;
        }
    }
}

.skeleton {
    transform: translateX(0);
}
