@import "../../css/fonts";
@import "../../css/variables";

.chips {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 24px;
}

.chip {
    @include font-rubik(16px, var(--gray-darkest), 400);
    display: flex;
    align-items: center;
    border: 1px solid var(--gray-neutral);
    border-radius: 24px;
    padding: 8px 12px;
    background-color: var(--white);
    cursor: pointer;
    transition: all 0.5s ease-out;

    &:not(:last-child) {
        margin-right: 8px;
    }

    &--selected {
        border: 1px solid var(--blue-darkerer);
        background-color: var(--blue-light);
    }

    &__icon {
        width: 12px;
        margin-right: 8px;
    }
}
