@import "../../fonts";
@import "../../variables";

.graduation {
    &-modal {
        &__close {
            position: absolute;
            top: 0;
            left: 100%;
            color: var(--gray-dark);
            cursor: pointer;
            transform: translate(calc(-100% - 8px), 8px);
        }

        &__content {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px 48px;
        }

        &__header {
            margin-bottom: 48px;
        }

        &__selector {
            @include font-rubik(20px, var(--gray-darkest), 400);
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 48px;

            &__icon {
                @include font-rubik(24px, var(--gray-darkest), 400);
                position: absolute;
                cursor: pointer;

                &--disabled {
                    color: var(--gray-light);
                    cursor: not-allowed;
                }

                &--left {
                    left: 0;
                }

                &--right {
                    left: 100%;
                    transform: translate(-100%, 0);
                }
            }

            &__option {
                margin: 0 48px;
                width: 120px;
                text-align: center;
            }
        }
    }
}
