@import "../../../../css/fonts";

.cartShippingAddressForm {
    display: grid;
    grid-column-gap: 8px;
    grid-row-gap: 12px;
    grid-template-columns: repeat(6, 1fr);
    padding-top: 8px;

    .buttons {
        grid-column: 1 / span 6;
        display: flex;
        flex-direction: column;
        margin-top: 4px;

        .button:not(:last-child) {
            margin-bottom: 8px;
        }
    }

    .input {
        background-color: var(--white);

        &--city {
            grid-column: span 2;
        }

        &--firstName {
            grid-column: span 3;
        }

        &--lastName {
            grid-column: span 3;
        }

        &--line1 {
            grid-column: span 6;
        }

        &--line2 {
            grid-column: span 6;
        }

        &--phone {
            grid-column: span 6;

            input {
                @include font-rubik(16px, var(--black), 400);
                width: 100%;
                padding-top: 8.5px !important;
                padding-bottom: 8.5px !important;
            }
        }

        &--postalCode {
            grid-column: span 2;
        }

        &--province {
            grid-column: span 2;
        }
    }

    .populateAddressOption {
        grid-column: span 6;
        margin-bottom: 4px;
    }
}
