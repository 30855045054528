@import "../../../../../../css/fonts";

.muster-recruit-invite-modal-submit {
    .body {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        width: 100%;

        .inviteViaEmail {
            margin-top: 15px;

            .inputs {
                max-height: 360px;
                overflow-y: auto;
            }

            .material {
                @include font-rubik(16px, var(--midnight-100), 300);
                align-items: center;
                cursor: pointer;
                display: flex;

                .material-icons {
                    padding: 5px;
                }
            }
        }

        .inviteViaLink {
            .magicLink {
                display: flex;
                justify-content: space-between;

                .button {
                    padding-top: 5.5px;
                    padding-bottom: 5.5px;
                    padding-left: unset;
                    padding-right: unset;
                    height: 31px;
                    font-size: 16px;
                    line-height: 21px;
                    width: 92px;
                    text-align: center;
                }

                input {
                    height: 31px;
                    width: 400px;
                    margin-bottom: 24px;
                }
            }

            .divider {
                width: 100%;
                text-align: center;
                border-bottom: 1px solid var(--gray-darkerer);
                line-height: 0.1px;
                margin: 10px 0 20px;
                @include font-rubik(14px, var(--midnight-100), 600);

                span {
                    background-color: var(--dust-100);
                    padding: 0 8px;
                }
            }
        }

        .header {
            margin-bottom: 24px;
        }
    }

    .buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 50px;

        &--less-margin {
            margin-top: 20px;
        }

        .button {
            width: 100%;
        }
    }

    .headerText {
        display: flex;
        flex-direction: column;
        width: 90%;

        .secondaryText {
            @include font-rubik(16px, var(--midnight-100), 300);
        }
    }

    .inputContainer {
        padding-bottom: 15px;

        .input-validatable {
            margin-bottom: 0px;
            height: 43px;
        }
    }
}
