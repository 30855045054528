.addressCard {
    position: relative;
    display: flex;
    flex-direction: column;
    background-color: var(--white);

    .stamp {
        position: absolute;
        top: 0;
        left: 100%;
        width: 64px;
        transform: translate(-80%, -20%);
    }
}
