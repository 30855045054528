@import "../../../../../css/fonts";

.signUpPersonaChild {
    .navigation {
        display: flex;
        justify-content: flex-start;

        .backButton {
            @include font-rubik(14px, var(--gray-darkerer), 600);
        }
    }

    .personas {
        display: flex;
        flex-direction: column;
        gap: 16px;
        margin-bottom: 48px;

        .persona {
            background-color: var(--dust-100);
        }

        &--grid {
            display: grid;
            grid-column-gap: 16px;
            grid-template-columns: 1fr 1fr;
        }
    }
}
