@import "../../../../css/variables";

.dashboardAlerts {
    @include hideScrollBar;
    display: flex;
    width: 100%;
    gap: 24px;
    overflow: hidden;
    transition: height 0.3s ease;

    &--hasAlerts {
        margin-bottom: 24px;
    }

    .alert {
        &--inCarousel {
            width: 100%;
            text-wrap: wrap;
        }
    }

    .carousel {
        max-width: 100%;
        width: 100%;

        .item {
            width: 100%;
            max-width: 100%;
        }
    }
}
