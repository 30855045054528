@import "../../../../css/fonts";

.personModalName {
    .form {
        .buttons {
            display: flex;
            flex-direction: column;
        }

        .input {
            width: 100%;
            margin-bottom: 24px;
        }

        .preview {
            display: flex;
            flex-direction: column;
            margin-bottom: 24px;

            &--hidden {
                visibility: hidden;
            }
        }

        .rankDescription {
            margin-bottom: 24px;
        }

        .toggle {
            margin-bottom: 24px;

            &--hidden {
                visibility: hidden;
            }
        }
    }

    .header {
        margin-bottom: 24px;
    }
}
