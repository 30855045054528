.musterOverview {
    display: flex;
    flex-wrap: wrap;
    padding: 28px;
    margin-right: 0px;
    width: calc(100% - 16px);
}

.musterOverviewHeader {
    display: flex;
    height: 40%;
    width: 100%;
}

.loadingContainer {
    width: 100%;
}
