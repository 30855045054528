@import "../../../css/fonts";

.phone-number-input {
    position: relative;
    width: 100%;

    &::placeholder {
        @include font-rubik(16px, var(--gray-dark), 300);
    }

    .input {
        @include font-rubik(16px, var(--midnight-100), 300);
        border: 1px solid var(--gray-darkerer);
        border-radius: 4px;
        padding: 12px 6px 12px 52px !important;
    }

    .label {
        @include font-rubik(16px, var(--gray-darkerer), 300);
        margin-bottom: 8px;

        &--hidden {
            display: none;
        }
    }
}
