@import "../../fonts";

.stripe {
    &__saved-cards {
        &--layout-card {
            display: flex;
            overflow-x: auto;

            &--overflow {
                padding-bottom: 16px;
            }
        }

        &__card {
            position: relative;
            display: flex;
            align-items: center;
            width: 100%;
            height: 70px;
            font-size: 16px;

            &--layout-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                min-width: 25%;
                height: auto;
                border: 1px solid var(--gray-neutral);
                border-radius: 10px;
                padding: 16px;
                cursor: pointer;

                &:before {
                    content: none !important;
                }

                &:not(:last-child) {
                    margin-right: 8px;
                }

                &--active {
                    border: 2px solid var(--gray-darkest);
                    background-color: var(--gray-lighter);
                    box-shadow: 0px 3px 11px rgba(52, 81, 104, 0.35);
                }

                & > * {
                    margin-right: 0 !important;
                }

                .stripe__saved-cards__card__icon {
                    margin-bottom: 8px;

                    &__image {
                        width: 18px;
                    }
                }
                .stripe__saved-cards__card__info {
                    @include font-rubik(14px, var(--midnight-100), 300);
                    display: flex;
                    justify-content: space-between;
                    width: 100%;

                    & > * {
                        margin-right: 0;
                    }
                }
            }

            &.selected,
            &:hover {
                color: var(--blue-dark);
            }

            &:not(:last-child):before {
                position: absolute;
                top: 100%;
                width: 100%;
                height: 1px;
                background-color: var(--gray-lighter);
                content: "";
            }

            &__end-item {
                position: absolute;
                top: 50%;
                left: 100%;
                width: 50%;
                transform: translate(-100%, -50%);

                &__text {
                    display: inline-block;
                    width: 100%;
                    text-align: right;
                }
            }

            &__icon {
                margin-right: 48px;

                &__image {
                    width: 30px;
                    height: auto;
                }
            }

            &__info {
                &__expiration {
                    margin-right: 48px;
                    color: var(--gray-darkerer);
                    text-align: right;
                }

                &__number {
                    margin-right: 48px;
                    color: var(--gray-darkerer);
                    letter-spacing: 1px;
                    text-align: right;
                }
            }
        }

        &__no-cards {
            color: var(--gray-darkerer);
            font-size: 16px;
            font-style: italic;
        }
    }
}
