@import "../../../../../../../../css/fonts";
@import "../../../../../../../../css/variables";

.wallet-manage-list-card {
    position: relative;
    display: flex;
    flex-direction: column;
    border-radius: 16px;
    cursor: pointer;

    &:before {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: var(--gray-neutral);
        content: "";
    }

    &:not(:last-child) {
        margin-bottom: 32px;
    }

    .image {
        max-width: 100%;
        filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.3));

        .skeleton {
            border-radius: 16px;
        }

        img {
            width: 100%;
        }
    }

    .text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 16px;

        .balance,
        .from {
            display: flex;
            flex-direction: column;

            .amount {
                @include font-rubik(14px, var(--midnight-100), 600);
            }

            .header {
                @include font-rubik(10px, var(--midnight-100), 300);
            }

            .name {
                @include font-rubik(14px, var(--midnight-100), 300);
            }
        }

        .balance {
            align-items: flex-end;
        }
    }

    &--selected {
        background-color: rgba(42, 82, 106, 0.5);
        filter: drop-shadow(0 5px 10px rgba(0, 0, 0, 0.3));

        .image {
            filter: none;
        }

        .text {
            .balance,
            .from {
                .amount,
                .header,
                .name {
                    color: var(--white);
                }
            }
        }

        &:before {
            display: none;
        }
    }
}
