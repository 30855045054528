@import "../../../fonts";
@import "../../../variables";

.letters-compose__review {
    width: 100%;

    &__grid {
        display: flex;
        width: 100%;

        @include tablet {
            display: flex;
            flex-direction: column;
        }

        &__column-one {
            width: 65%;
            margin-right: 8px;
            align-self: flex-start;

            .panel {
                border-radius: unset;
            }

            &__recipient-sender-container {
                border-top: 1px solid var(--gray-dark-background);
                display: flex;
                padding-top: 40px;
            }
        }

        &__column-two {
            border-radius: unset;
            position: relative;
            width: 35%;
            align-self: flex-start;

            &__total {
                display: flex;
                flex-direction: column;
            }

            .panel {
                border-radius: 0px;
                padding: 30px 39px;
            }

            .place-order {
                padding: 0px 39px 16px 39px;
            }
        }
    }

    &-checkbox {
        &-container {
            grid-column: 1 / span 8;
            margin-bottom: 0 !important;
        }

        &-label {
            &-text-main {
                @include font-rubik(14px, var(--gray-darkerer), 400);
                font-size: 14px !important;
                transform: translateY(2px) !important;
            }
        }
    }

    &__header {
        display: flex;
        border-bottom: 1px solid var(--gray-light);
        margin-bottom: 18px;

        &__title {
            margin-bottom: 20px !important;
        }
    }

    &__message-preview {
        &__body {
            display: flex;
            flex-direction: column;
            padding-top: 30px;
            overflow: hidden;

            &__images {
                display: flex;
                gap: 13px;
                margin-bottom: 23px;
                width: 100%;

                .button-photo-upload {
                    max-width: 331px;
                }
            }

            &__text {
                @include font-rubik(16px, var(--midnight-100), 300);
                white-space: pre-line;
                max-height: 450px;
                overflow-y: auto;
                width: 100%;
                margin-bottom: 0;

                &__wrapper {
                    margin-bottom: 33px;
                    width: 100%;
                }
            }
        }

        &__save-message-button,
        &__edit-message-button {
            margin-bottom: 24px;
            margin-top: 24px;
            align-self: start;
        }
    }

    &__payment-method {
        &__banner {
            margin-bottom: 16px;
        }

        &__card {
            display: flex;
            width: 100%;
            align-items: center;

            & > *:not(:last-child) {
                margin-right: 16px;
            }

            &__digits {
                @include font-rubik(16px, var(--midnight-100), 300);
            }
        }
    }

    &__purchase-credits-banner {
        margin-bottom: 16px;
        cursor: pointer;
    }

    &__purchase-credits-button {
        margin-bottom: 16px;
        align-self: start;
    }

    &__purchase-add-ons-button {
        margin-bottom: 16px;
        align-self: start;
    }

    &__recipient-sender {
        @include font-rubik(false, false, 400);
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        &:last-child {
            padding-bottom: 0;
            padding-left: 40px;
            border-left: 1px solid #dedede;
        }

        &:not(:last-child) {
            padding-right: 40px;
        }

        &--editing {
            border-left: unset;
        }

        &__banner {
            margin-bottom: 16px;
        }

        &__banner-no-address {
            margin-top: 16px;
            cursor: pointer;
        }

        &__form {
            display: grid;
            grid-column-gap: 16px;
            grid-template-columns: repeat(8, calc(12.5% - 14px));
            width: 100%;

            /* Columns */

            .dropdown {
                &--country {
                    grid-column: 1 / span 8;
                }

                &--state {
                    grid-column: 5 / span 2;
                }

                & > * {
                    color: var(--midnight-100);
                    font-size: 14px;
                    font-weight: 300;
                }

                label:not(.MuiFormLabel-filled):not(.Mui-focused) {
                    top: -4px;
                    left: 2px;
                }

                select {
                    border-color: var(--gray-darkerer) !important;
                    padding: 10px 16px;
                }
            }

            .input[name="line1"],
            .input[name="line2"] {
                grid-column: 1 / span 8;
            }

            .input[name="city"] {
                grid-column: 1 / span 4;
            }

            .input[name="firstName"] {
                grid-column: 1 / span 4;
            }

            .input[name="lastName"] {
                grid-column: 5 / span 4;
            }

            .input[name="state"] {
                grid-column: 5 / span 2;
            }

            .select[name="title"] {
                grid-column: 1 / span 8;
            }

            .input[name="zipcode"] {
                grid-column: 7 / span 2;
            }
        }

        &__header {
            @include font-rubik(14px, var(--midnight-100), 700);
        }

        &-masked-address-text {
            margin-top: 8px;

            &-text {
                @include font-rubik(12px, var(--gray-darker), 400);
                font-style: italic;
                line-height: 12px;
            }
        }

        & > span {
            @include font-rubik(16px, var(--midnight-100), 300);
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        width: 100%;

        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            margin-bottom: 16px;

            &__icon {
                cursor: pointer;

                &--disabled {
                    color: var(--gray-neutral);
                    cursor: not-allowed;
                }
            }

            &__link {
                @include font-rubik(14px, false, false);
                color: #5e95eb !important;

                &.link-disabled {
                    color: var(--gray-dark) !important;
                }
            }

            &__text {
                @include font-rubik(16px, var(--midnight-100), 700);
            }
        }

        &.credits-section,
        &.add-ons-section {
            border-bottom: 1px solid var(--gray-dark-background);
            margin-bottom: 24px;
        }
    }

    &__total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 18px;

        &__amount,
        &__text {
            @include font-rubik(24px, var(--midnight-100), 700);
        }
    }
}
