@import "../../../../../../../../css/fonts";

.supportSquadSettingsModalCreateAssign {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .body {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 16px;
        width: 100%;

        .addNewContact {
            @include font-rubik(16px, var(--midnight-100), 300);
            align-items: center;
            cursor: pointer;
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .tabs {
            display: flex;
            border-top: 1px solid var(--gray-light);
            flex-direction: column;
            width: 100%;

            .highlight {
                background-color: transparent;
                height: 4px;
                position: relative;
                width: 100%;

                .bar {
                    background-color: var(--blue-darkerer);
                    height: 100%;
                    left: 0;
                    position: relative;
                    transition: left 0.25s ease-out;
                    width: 100%;
                }
            }

            .items {
                display: flex;
                width: 100%;

                .tab {
                    @include font-rubik(14px, var(--gray-darker), 400);
                    align-items: center;
                    display: flex;
                    justify-content: center;
                    padding: 12px 0;
                    width: 100%;
                }

                .active {
                    @include font-rubik(14px, var(--blue-darkerer), 500);
                }
            }
        }

        .userListContainer {
            max-height: 240px;
            min-height: 120px;
            margin-bottom: 16px;
            overflow-y: auto;

            &--empty {
                display: flex;
                justify-content: center;
                align-items: center;

                .text {
                    @include font-rubik(14px, var(--gray-darker), 300);
                    text-align: center;
                }
            }
        }
    }

    .buttons {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .headerText {
        display: flex;
        flex-direction: column;

        .secondaryText {
            @include font-rubik(16px, var(--midnight-100), 300);
        }
    }
}
