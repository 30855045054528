@import "../../../../css/fonts";
@import "../../../../css/variables";

.profile__fields {
    margin-bottom: 12px;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        &__left {
            display: flex;
            justify-content: flex-start;
            margin-bottom: 24px;

            &__text {
                font-size: 26px;
                margin: 0px;
            }
        }

        &__right {
            display: flex;
            justify-content: flex-end;
            margin-bottom: 24px;

            &__text {
                font-size: 26px;
                margin: 0px;
            }
        }
    }

    &__display {
        &__item {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            padding: 16px 0;

            &:not(:last-child) {
                border-bottom: 1px solid var(--gray-light);
            }

            &:last-child {
                padding-bottom: 0;
            }

            &__content,
            &__title {
                @include font-rubik(16px, var(--midnight-100), 400);
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                justify-content: center;
            }

            &__content {
                text-align: right;

                .link {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    justify-content: center;
                }
            }

            &__icon {
                margin-right: 16px;

                &--delete {
                    color: var(--error-100);
                }
            }

            &__left,
            &__right {
                display: flex;
                align-items: center;
            }

            &__left {
                padding-left: 0;

                &--action {
                    cursor: pointer;
                    background-color: transparent;
                    border: none;
                }
            }

            &__title {
                &--delete {
                    color: var(--error-100);
                }
            }
        }
    }

    &__edit {
        &__form {
            display: grid;
            grid-column-gap: 24px;
            grid-template-columns: repeat(2, 1fr);
            border-bottom: 1px solid var(--gray-light);
            padding: 16px 0;

            .input {
                &--birthday,
                &--branchId,
                &--gender,
                &--role {
                    margin-bottom: 16px;
                }

                &--birthday,
                &--branchId,
                &--gender,
                &--maritalStatus,
                &--role {
                    grid-column: 1 / span 2;
                }
            }
        }
    }
}
