@import "../../../../../../css/fonts";

.muster-checklist-success-modal {
    max-height: 1000px;

    .dialog {
        max-height: 755px;
        overflow: hidden;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;

        .textContainer {
            margin-top: 15px;
            margin-bottom: 24px;
            text-align: center;

            .bodyText {
                height: 24px;
            }

            .header {
                height: 36px;
            }
        }
    }

    .buttons {
        margin-top: 0;
        display: flex;
        flex-direction: column;
        width: 100%;

        .button {
            width: 100%;
            margin-bottom: 8px;
        }
    }
}
