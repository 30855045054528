@import "../../css/fonts";
@import "../../css/variables";

.phone-verification-modal {
    .body {
        padding-top: 0;
    }

    .complete-letters,
    .complete-letters-connection,
    .complete-profile,
    .phone-number,
    .verification-code {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;

        .checkmark {
            position: relative;
            width: 64px;
            height: 64px;
            margin-bottom: 24px;
            margin-top: 8px;
            border-radius: 50%;
            background-color: var(--teal-neutral);

            &:before {
                position: absolute;
                top: 50%;
                left: 50%;
                font-family: "Material Icons", sans-serif;
                font-size: 48px;
                color: var(--white);
                content: "\E5CA";
                transform: translate(-50%, -50%);
            }
        }

        .form {
            align-items: center;
            display: flex;
            flex-direction: column;
            text-align: center;

            .description {
                margin: 16px 0 32px 0;
            }

            .codeInput {
                margin-bottom: 32px;
            }

            .input {
                &--phone-intl {
                    @include font-rubik(18px, var(--gray-darkerer), 400);
                    position: relative;
                    border-radius: 4px;
                    padding: 24px 24px 24px 70px;
                    background-color: transparent;

                    &::placeholder {
                        color: var(--gray-dark) !important;
                    }
                }
            }

            .input-wrapper {
                width: 100%;
                max-width: 300px;
                margin-bottom: 16px;
            }

            .submit {
                margin-top: 24px;
            }
        }

        .header {
            margin-bottom: 8px !important;
        }

        .subheader {
            margin-bottom: 16px;
        }
    }

    .complete-letters {
        .description {
            margin-bottom: 32px;
        }

        .header {
            margin-bottom: 24px;
        }
    }

    .complete-letters-connection {
        .description {
            @include font-rubik(16px, var(--midnight-100), 300);
            margin-bottom: 8px;
        }

        .header {
            margin-bottom: 24px;
        }

        .recruit {
            @include font-rubik(16px, var(--midnight-100), 300);
            margin-bottom: 32px;
            font-weight: 700;
        }
    }

    .complete-profile {
        .header {
            margin-bottom: 24px !important;
        }
    }

    .phone-number {
        .description {
            margin: 16px 0 24px 0;
        }
    }

    .verification-code {
        .form {
            margin-bottom: 16px;
        }

        .header {
            margin-bottom: 24px !important;
        }
    }
}
