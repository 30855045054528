@import "../../../../../../../../css/fonts";

.wallet-gift-cards-purchase-confirmation {
    padding: 0 20%;

    & > *:not(:last-child) {
        margin-bottom: 48px;
    }

    .buttons {
        & > *:not(:last-child) {
            margin-bottom: 16px;
        }

        display: flex;
        flex-direction: column;
    }

    .header {
        position: relative;
        display: flex;
        flex-direction: column;

        .icon {
            position: absolute;
            top: 0;
            left: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            border: 3px solid var(--dust-100);
            background-color: var(--green-light);
            transform: translate(-50%, -50%);
        }

        .message,
        .order-number {
            display: flex;
            justify-content: center;
            text-align: center;
        }

        .message {
            flex-direction: column;
            align-items: center;
            border-radius: 10px 10px 0 0;
            padding: 45px 16px 24px 16px;
            background-color: var(--green-light);

            h4 {
                opacity: 0.5;
            }
        }

        .order-number {
            border-radius: 0 0 10px 10px;
            padding: 12px 16px;
            background-color: rgba(181, 218, 160, 0.5);

            img {
                margin-right: 10px;
            }

            p {
                opacity: 0.7;
            }
        }
    }
}
