@import "../../../../../css/fonts";

.sign-up-name {
    .form {
        .input {
            margin-bottom: 16px;

            &:last-of-type {
                margin-bottom: 32px;
            }
        }
    }
}
