@import "../../../../css/fonts";

.weeklyUpdatesDetail {
    container-type: inline-size;
    container-name: weekly-updates-detail;
    display: flex;
    flex-direction: column;

    .body {
        display: flex;
        flex-direction: row;
        width: 100%;
        border-radius: 0 0 8px 8px;
        padding: 48px 64px;
        background-color: var(--white);

        @container weekly-updates-detail (width < 900px) {
            flex-direction: column;
        }

        /** Layout **/

        .left {
            width: 70%;
            padding-right: 64px;

            @container weekly-updates-detail (width < 900px) {
                width: 100%;
                margin-bottom: 32px;
                padding-right: 0;
            }
        }

        .right {
            width: 30%;

            @container weekly-updates-detail (width < 900px) {
                width: 100%;
            }
        }

        /** End Layout **/

        .content {
            @include font-rubik(16px, var(--midnight-100), 300);
            width: 100%;
            max-width: 100%;
            margin-bottom: 48px;

            div {
                & > * {
                    margin-bottom: 16px !important;
                }
            }

            figure,
            img {
                max-width: 100%;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        .list {
            display: flex;
            flex-direction: column;

            .item {
                display: flex;
                border-bottom: 1px solid var(--gray-lighter);
                padding: 16px 0;
                cursor: pointer;

                &--active,
                &:hover {
                    .text * {
                        color: var(--mission-orange-100);
                    }
                }

                .chevron,
                .text {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                .chevron {
                    width: 3%;
                }

                .text {
                    width: 90%;

                    & * {
                        transition: color 0.1s ease-out;
                    }

                    .description {
                        @include font-rubik(14px, var(--midnight-100), 300);
                    }

                    .headerText {
                        @include font-rubik(14px, var(--midnight-100), 700);

                        span {
                            position: relative;
                        }

                        .consumedStatus {
                            position: absolute;
                            top: 50%;
                            left: 100%;
                            width: 8px;
                            height: 8px;
                            border-radius: 50%;
                            background-color: var(--mission-orange-100);
                            transform: translate(8px, calc(-50% - 1px));
                        }
                    }
                }
            }
        }

        .title {
            margin-bottom: 24px !important;
        }
    }

    .header {
        position: relative;
        height: 400px;
        width: 100%;
        border-radius: 8px 8px 0 0;
        background-position: bottom;
        background-size: cover;

        .week {
            position: absolute;
            top: 100%;
            left: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 16px 24px;
            background-color: var(--blue-darkerer);
            transform: translate(64px, calc(-100% + 24px));

            .description {
                @include font-rubik(18px, var(--white), 300);
            }

            .number {
                @include font-rubik(48px, var(--white), 500);
                line-height: 40px;
            }
        }
    }
}
