/* Media Queries */

$mobile-portrait-max-width: 576px;
$mobile-landscape-max-width: 768px;
$tablet-portrait-min-width: 769px;
$tablet-portrait-max-width: 1024px;
$tablet-landscape-min-width: 1025px;
$tablet-landscape-max-width: 1200px;
$desktop-small-min-width: 1201px;
$desktop-small-max-width: 1440px;
$desktop-medium-min-width: 1441px;
$desktop-medium-max-width: 1680px;
$desktop-large-min-width: 1681px;
$desktop-large-max-width: 1920px;

@mixin mobile-portrait {
    @media (max-width: var(--mobile-portrait-max-width)) {
        @content;
    }
}

@mixin mobile-landscape {
    @media (max-width: var(--mobile-landscape-max-width)) {
        @content;
    }
}

@mixin tablet-portrait {
    @media (min-width: var(--tablet-portrait-min-width)) and (max-width: var(--tablet-portrait-max-width)) {
        @content;
    }
}

@mixin tablet-landscape {
    @media (min-width: var(--tablet-landscape-min-width)) and (max-width: var(--tablet-landscape-max-width)) {
        @content;
    }
}

@mixin desktop-small {
    @media (min-width: var(--desktop-small-min-width)) and (max-width: var(--desktop-small-max-width)) {
        @content;
    }
}

@mixin desktop-medium {
    @media (min-width: var(--desktop-medium-min-width)) and (max-width: var(--desktop-small-max-width)) {
        @content;
    }
}

@mixin desktop-large {
    @media (min-width: var(--desktop-large-min-width)) {
        @content;
    }
}

@mixin responsive($min-width: null, $max-width: null) {
    @if $min-width != null and $max-width != null {
        @media (min-width: $min-width) and (max-width: $max-width) {
            @content;
        }
    } @else if $min-width != null {
        @media (min-width: $min-width) {
            @content;
        }
    } @else if $max-width != null {
        @media (max-width: $max-width) {
            @content;
        }
    }
}
