html,
body {
    height: 100%;
    margin: 0;
}

input[type="text"] {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

input[type="text"]::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: #757575;
}

input[type="text"]::-moz-placeholder {
    /* Firefox 19+ */
    color: #757575;
}

input[type="text"]:-ms-input-placeholder {
    /* IE 10+ */
    color: #757575;
}

input[type="text"]:-moz-placeholder {
    /* Firefox 18- */
    color: #757575;
}
