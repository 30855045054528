@import "../../../../../../css/fonts";

.musterOverviewDocumentCarousel {
    width: 100%;

    .carousel {
        display: flex;
        width: 100%;
        align-items: center;
        padding-right: 4px;

        .arrow {
            background-color: var(--white);
            border: 1px solid #d8d8d8;
            border-radius: 16px;
            height: 32px;
            padding: 0px;
            width: 32px;
            display: flex;
            justify-content: center;
            align-items: center;

            &--hidden {
                visibility: hidden;
            }

            &--left {
                margin-right: 8px;
            }

            &--right {
                margin-left: 8px;
            }
        }

        .cardHolder {
            overflow: hidden;
            display: flex;
            width: 100%;

            .scrollContainer {
                display: flex;
                transition: transform 0.3s;
                right: 0;
                left: 0;
                top: 0;

                .cardContainer {
                    margin-left: 8px;
                    margin-right: 8px;

                    .footerText {
                        color: #4c5155;
                        font-size: 12px;
                        line-height: 18px;
                    }
                }
            }
        }
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 16px;
        margin-left: 12px;

        .button {
            cursor: pointer;
        }
    }
}
