@import "../../../../../../../../css/fonts";

.lettersSentLetterResolutionModal {
    .modalBody {
        display: flex;
    }

    .exampleAddresses {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 50%;
        padding-left: 24px;

        .subtitle {
            margin-bottom: 24px;
        }
    }

    .form {
        display: grid;
        grid-column-gap: 24px;
        grid-template-columns: repeat(4, 1fr);
        width: 50%;
        border-right: 1px solid var(--dust-120);
        padding-right: 24px;

        &--noExampleAddresses {
            width: 100%;
            padding-right: 0;
            border-right: none;
        }

        .banner {
            grid-column: 1 / span 4;
            margin-bottom: 24px;
            cursor: pointer;

            .content {
                display: flex;
                gap: 4px;
            }
        }

        .buttons {
            grid-column: 1 / span 4;
            display: flex;
            flex-direction: column;
        }

        .input {
            display: flex;
            justify-content: flex-start !important;
            margin-bottom: 24px;

            &--state {
                grid-column: 1 / span 2;
            }

            &--zipcode {
                grid-column: 3 / span 2;
            }

            &--city,
            &--line1,
            &--line2,
            &--name {
                grid-column: 1 / span 4;
            }
        }
    }

    .icon {
        margin-right: 8px;
    }
}
