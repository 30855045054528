@import "../../../../../../css/fonts";
@import "../../../../../../css/responsive";

.supportSquadListEmpty {
    height: 100%;
    flex: 1;

    .header {
        position: relative;
        aspect-ratio: 1.85 / 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        width: 100%;
        margin-bottom: 24px;
        border-radius: 8px;
        padding: 24px;
        background-color: var(--midnight-100);
        background-size: cover;
        background-position: center;

        @include responsive($max-width: $desktop-small-max-width) {
            display: none;
        }

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 8px;
            background: linear-gradient(
                to right,
                rgba(0, 0, 0, 0.5) 0%,
                rgba(0, 0, 0, 0.25) 35%,
                rgba(0, 0, 0, 0) 100%
            );
            content: "";
        }

        .headline {
            @include font-rubik(24px, var(--white), false);
            color: var(--white);
            z-index: 10;
        }
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .item {
            display: flex;
            align-items: center;

            .icon {
                color: var(--mission-orange-100);
                margin-right: 16px;
            }

            .body {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
