.supportSquadDetailEmpty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    gap: 16px;

    .body {
        display: flex;
        flex-direction: column;
        .subtitle,
        .title {
            color: var(--midnight-60);
            font-size: 14px;
        }
    }

    .icon {
        transform: scale(1.5);
    }
}
