@import "fonts";
@import "variables";

body {
    font-family: Rubik, sans-serif;
    scroll-behavior: smooth;
}

a {
    text-decoration: none !important;
}

.app {
    display: grid;
    grid-template-columns: $navigation-width calc(100% - $navigation-width);
    height: 100vh;

    &.hide-nav-bar {
        grid-template-columns: 0 100%;
    }

    &-content {
        position: relative;
        width: 100%;

        @include phone {
            width: 100vw;
        }
    }

    &-mobile {
        display: grid;
    }
}

.footer {
    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        min-height: 150px;
        padding: 24px;
        background-color: var(--gray-darkerer);

        &-mobile {
            display: none;
        }
    }

    &-buttons {
        display: flex;
        justify-content: space-between;
        width: 400px;
        margin-bottom: 20px;

        &-icon {
            width: 20px;
            height: auto;
            margin-right: 10px;
        }
    }

    &-divider {
        margin: 0 5px;
    }

    &-dod {
        @include font-rubik(12px, var(--gray-light), 300);
        text-align: center;
    }

    &-links {
        @include font-rubik(12px, var(--white), 300);
        margin-bottom: 10px;

        &-link {
            text-decoration: none;
            color: var(--white);
            cursor: pointer;

            &:hover {
                text-decoration: underline;
                color: var(--white);
            }
        }
    }
}

.grecaptcha-badge {
    visibility: hidden;
}

.page {
    background-color: #e5e5e5;
    min-height: 100vh;

    &__body {
        min-height: calc(100vh - 210px);
        padding: 32px;
        background-color: #e5e5e5;
    }
}

.side-nav-bar {
    &-container {
        grid-column: 1 / 2;

        &-mobile {
            min-height: 40px;
        }
    }
}

.main-component-2 {
    grid-column: 1 / 2;
}
