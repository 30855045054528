@import "../../../../css/fonts";

.support-squad-delete-modal {
    .buttonContainer {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        width: 100%;

        .button {
            width: 100%;
            margin-top: 8px;
        }
    }

    .container {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .checkbox {
            display: flex;
            align-self: center;

            span {
                @include font-rubik(16px, var(--midnight-100), 300);
            }
        }

        .headerText {
            @include font-rubik(20px, var(--midnight-100), 600);
            margin-top: 15px;
            margin-bottom: 10px;
        }

        .headerTextRed {
            @include font-rubik(20px, var(--error-100), 600);
            margin-top: 15px;
            margin-bottom: 10px;
        }

        .itemsToBeDeleted {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-top: 35px;
            margin-bottom: 35px;

            .item {
                @include font-rubik(16px, var(--midnight-100), 600);
                margin-top: 6px;
                margin-bottom: 6px;
            }
        }

        .bodyText {
            @include font-rubik(16px, var(--midnight-100), 300);
            text-align: center;
        }

        .membersHeader {
            display: flex;
            justify-content: space-between;
            width: 100%;

            .title {
                @include font-rubik(16px, var(--midnight-100), 600);
            }
        }
    }
}
