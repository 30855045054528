@import "../../../../css/fonts";

.support-squad-settings-modal {
    // max-height: 1000px;
}

.support-squad-settings-modal-dialog {
    // max-height: 755px;
    overflow: hidden;

    &--photo-edit {
        max-height: 1000px;
        height: 90vh;
        width: 90vw;
    }
}
