@import "../../../../css/fonts";

.dashboardSentLetters {
    .empty,
    .replied {
        padding-top: 24px;

        .button {
            display: flex;
            justify-content: flex-end;
        }

        .content {
            display: flex;
            margin-bottom: 16px;

            .logo {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 16px;
            }

            .text {
                @include font-rubik(14px, var(--midnight-100), 300);
            }
        }
    }

    .loading {
        padding-top: 24px;
    }

    .list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .letterPreview {
            border-bottom: 1px solid var(--midnight-20);
            padding-bottom: 16px;
        }

        .showAll {
            @include font-rubik(16px, var(--gray-darkest), 500);
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
}
