@import "../../../../../../css/fonts";
@import "../../../../../../css/responsive";

.lettersSentLetter {
    .banner {
        margin-bottom: 16px;
        cursor: pointer;

        .content {
            display: flex;
            flex-direction: column;

            .button {
                background-color: var(--mission-orange-100);
            }
        }
    }

    .info {
        display: flex;
        justify-content: space-between;
        gap: 64px;

        @include responsive($max-width: $desktop-small-min-width) {
            flex-direction: column;
        }

        .left {
            display: flex;
            flex-direction: column;
            width: 300px;
        }

        .right {
            width: 650px;

            @include responsive($max-width: $desktop-small-min-width) {
                width: 100%;
            }
        }

        .panelHeader {
            margin-bottom: 16px;
        }

        .address,
        .giftCard,
        .letterStatus,
        .postagePaid {
            @include font-rubik(14px, var(--midnight-100), 400);
            display: flex;
            align-items: flex-start;
            margin-bottom: 8px;

            .iconWrapper {
                display: flex;
                justify-content: center;
                width: 24px;
                margin-right: 16px;

                .icon {
                    color: var(--blue-darkerer);
                }
            }

            .text {
                display: flex;
                flex-direction: column;
            }
        }

        .address {
            align-items: flex-start;
        }

        .giftCard {
            align-items: center;
        }

        .letterStatus {
            .iconWrapper {
                align-items: center;

                .icon {
                    transform: translateY(2px);
                }
            }
        }

        .postagePaid {
            align-items: center;

            .infoIcon {
                margin-left: 8px;
            }
        }

        .resolutionButton {
            margin-top: 24px;

            .button {
                width: 100%;
                background-color: var(--mission-orange-100);
            }
        }

        .image {
            width: 100%;
            height: 300px;
            margin-top: 16px;
            background-color: var(--gray-light);
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;

            &--empty {
                background-size: 20% 20%;
            }
        }

        .message {
            @include font-rubik(16px, var(--midnight-100), 400);
            width: 100%;
            height: auto;
            white-space: pre-wrap;
        }

        .replyHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            border-bottom: 1px solid var(--gray-lighter);
            padding: 24px 32px;
            background-color: var(--white);

            .left,
            .right {
                display: flex;
                align-items: center;
            }

            .icon {
                margin-right: 16px;
            }

            a {
                color: var(--mission-orange-100);
            }
        }

        .slider {
            align-items: center !important;
            display: flex !important;
            flex-direction: column !important;

            .arrows {
                top: 85px !important;
            }

            .image {
                border-radius: 5px !important;
                height: 202px !important;
                margin-bottom: 12px !important;
                width: 202px !important;
            }
        }
    }

    .map {
        .inner {
            width: 100%;
            height: 100%;

            &:first-child {
                position: relative;
                width: 100% !important;
                max-height: 600px;
            }
        }
    }

    .tracking {
        display: flex;
        justify-content: space-between;
        overflow: hidden;

        .left {
            width: 300px;
        }

        .right {
            width: 650px;
        }

        .link {
            @include font-rubik(14px, false, 300);
        }

        .checkpoints {
            position: relative;
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            &:before {
                position: absolute;
                top: 0;
                left: 15px;
                width: 2px;
                height: calc(100% - 44px);
                background-color: var(--dust-120);
                content: "";
            }

            .checkpoint {
                display: flex;

                &:first-child {
                    .left,
                    .right {
                        padding-top: 0;
                    }
                }

                &:last-child {
                    .left,
                    .right {
                        padding-bottom: 0;
                    }

                    .right {
                        border-bottom: none;
                    }
                }

                .left,
                .right {
                    padding: 16px 0;
                    width: auto;
                }

                .left {
                    margin-right: 16px;
                }

                .right {
                    display: flex;
                    flex-direction: column;
                    border-bottom: 1px solid var(--gray-lighter);
                }

                .icon {
                    position: relative;
                }

                .info {
                    @include font-rubik(14px, var(--midnight-100), 400);
                }

                .status {
                    @include font-rubik(12px, var(--midnight-100), 400);
                    margin-bottom: 8px;
                }

                .wrapper {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                    background-color: var(--white);
                    transform: translate(0, -25%);
                }
            }
        }
    }
}
