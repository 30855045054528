@import "../../css/fonts";

.divider {
    position: relative;
    height: 1px;
    background-color: var(--gray-darkerer);

    span {
        @include font-rubik(14px, var(--midnight-100), 600);
        position: absolute;
        top: 50%;
        left: 50%;
        display: inline-block;
        padding: 0 8px;
        background-color: var(--dust-100);
        transform: translate(-50%, -50%);
    }
}
