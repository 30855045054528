@import "../../../fonts";
@import "../../../variables";

.letters-compose__message {
    display: flex;
    position: relative;
    width: 100%;

    &__detail {
        display: flex;
        flex-direction: column;
        margin-left: 8px;
        width: 70%;

        .buttons {
            display: flex;
            gap: 12px;
            justify-content: flex-end;
            padding: 21px 0px;
            width: 100%;

            .next-button {
                height: 49px;
                padding: 0px;
                width: 157px;
            }

            .letters-draft-save-button {
                align-items: center;
                display: flex;
                gap: 10px;
                justify-content: center;
                padding: 0px;
                width: 157px;
            }
        }

        &.panel {
            margin-left: 8px;
        }
    }

    &__search {
        min-width: 300px;
        width: 30%;
    }

    &-grid {
        display: grid;
        grid-template-columns: 6fr 4fr;
        grid-column-gap: 15px;
        width: 100%;
    }

    &-bottom-text {
        margin-top: 48px;

        &__text {
            @include font-rubik(false, var(--gray-darkerer), 400);
            position: absolute;
            left: 50%;
            width: 100%;
            top: calc(100% - 40px);
            text-align: center;
            transform: translate(-50%);
        }
    }

    &-characters-left {
        @include font-rubik(false, var(--gray-darkerer), 400);
    }

    /* LettersComposeMessageContacts */

    &-contacts {
        &-container {
            width: 100%;
            margin-bottom: 24px;
            background-color: var(--white);
        }

        &-contact {
            &-container {
                display: block !important;
            }

            &-avatar {
                display: inline-block;
                height: 100%;
                margin-right: 24px;

                &.multi-line {
                    transform: translateY(4px);
                }
            }

            &-info {
                display: inline-flex !important;
                height: 100%;
                justify-content: center;
                transform: translateY(1px);

                &.multi-line {
                    transform: translateY(-5.5px);
                }
            }
        }

        &-list {
            &-container {
                width: 100%;
                max-height: 430px;
                overflow-y: scroll;
            }
        }

        &-no-contacts {
            @include font-rubik(18px, false, 400);
        }

        &-search-option {
            &-container {
                @include font-rubik(18px, var(--midnight-100), 700);
                display: flex;
                justify-content: space-between;
                align-items: center;
                width: 100%;
                padding: 24px;
                border-top: 1px solid var(--mission-orange-100);
                background-color: var(--white);
                cursor: pointer;

                &:hover {
                    .letters-compose-message-contacts-search-option-text {
                        text-decoration: underline;
                    }
                }

                &-left,
                &-right {
                    display: flex;
                    align-items: center;
                }
            }

            &-icon {
                margin-right: 45px;
                color: var(--mission-orange-100);
                font-size: 30px;
                transform: translateX(10px);
            }

            &-text {
                &-container {
                    display: flex;
                    flex-direction: column;
                }

                &-secondary {
                    @include font-rubik(false, var(--gray-darkerer), 400);
                }
            }
        }

        &-selected {
            &-container {
                margin-bottom: 24px;
            }

            &-icon {
                position: absolute;
                top: calc(50% - 12px);
                left: calc(100% - 45px);
                color: var(--mission-orange-100);
            }

            &-info {
                transform: translateY(2.5px);
            }
        }
    }

    /* LettersComposeMessageContent */

    &__content {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        font-size: 18px;

        &--embedded {
            & > * {
                padding: 0 !important;
            }

            .panel {
                margin-bottom: 0 !important;
                box-shadow: none;
            }
        }

        &__buttons {
            &__photo {
                position: relative;
                flex-direction: row !important;

                &__icon {
                    margin-right: 24px;
                    cursor: pointer;

                    &__disabled {
                        margin-right: 24px;
                        cursor: default;
                        opacity: 0.5;
                    }
                }

                &__input {
                    position: absolute;
                    width: 0.1px;
                    height: 0.1px;
                    opacity: 0;
                    overflow: hidden;
                    z-index: -1;

                    &__label {
                        position: absolute;
                        display: inline-block;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        z-index: 2;
                    }
                }
            }
        }

        &__characters {
            @include font-rubik(12px, var(--gray-dark), 500);
            position: absolute;
            top: 45px;
            left: 100%;
            width: fit-content;
            min-width: 100px;
            transform: translate(calc(-100% - 24px), -48px);

            &--embedded {
                top: 4px;
            }

            &--overLimit {
                color: var(--error-100);
            }
        }

        &__image-preview {
            position: relative;
            display: flex;
            top: 16px;
            margin-bottom: -52px;
            transform: translate(8px, -98px);

            &__close {
                cursor: pointer;
            }

            &__description {
                position: relative;
                display: flex;
                align-items: center;
                max-width: 272px;
                min-width: 140px;
                padding: 16px;
                background-color: var(--gray-light);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: pre-wrap;

                &__name {
                    @include font-rubik(14px, var(--midnight-100), 400);
                    @include limitNumberOfLines(1);
                    width: 80%;
                }

                .material-icons {
                    position: absolute;
                    top: 50%;
                    left: 100%;
                    transform: translate(calc(-100% - 8px), -50%);
                }
            }

            &__image {
                min-width: 52px;
                height: 52px;
                background-size: cover;
            }

            &:not(:last-of-type) {
                margin-right: 24px;
            }
        }

        &__panel {
            padding-top: 36px;
            position: relative;
            width: 100%;
            border-radius: 0 0 4px 4px;

            &__message-preview {
                position: relative;

                &__body {
                    background-color: #fbfbfb;
                    border: 1px solid var(--gray-dark-background);
                    border-radius: 4px;

                    &--overLimit {
                        outline: 1px solid var(--error-100);
                    }
                }

                &__header {
                    margin-bottom: 12px !important;
                }

                &__preview-container {
                    border-radius: 4px;
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 60px;
                    padding: 16px 23px;
                    width: 100%;

                    &--inline {
                        background-color: #fbfbfb;
                        margin-bottom: unset;
                    }

                    .button-photo-upload {
                        &:not(:last-of-type) {
                            margin-right: 24px;
                        }
                    }
                }

                &__textarea {
                    background-color: #fbfbfb !important;
                    border: none !important;
                    width: 100%;
                    max-height: 500px;
                    min-height: 300px;
                    padding-bottom: 80px !important;
                    padding: 24px 145px 24px 24px;
                    resize: none;

                    &--overLimit {
                        background-color: #fdeded !important;
                    }
                }
            }
        }

        &__recipient {
            align-items: center;
            border-bottom: 1px solid var(--gray-light);
            display: flex;
            justify-content: space-between;
            margin-bottom: 32px;
            padding-bottom: 37px;

            .avatar {
                height: 80px;
                font-size: 28px;
                margin-right: 17px;
                width: 80px;
            }

            .city-state-zip {
                display: flex;
            }

            .details {
                display: flex;

                .full-name {
                    margin-bottom: 8px !important;
                }

                .address-line1,
                .address-line2 {
                    margin-bottom: unset;
                }
            }

            .edit-contact-button {
                height: 50px;
                max-width: 230px;
                padding: 0px 24px;
                width: 100%;
            }
        }

        &__user-list {
            margin-bottom: 0 !important;

            .user-list-item-container {
                cursor: default;
            }
        }

        &__user-list-container {
            background-color: var(--dust-100);
            padding: 4px 24px 4px 12px;
        }
    }

    /* LettersComposeMessageImage */

    &-image {
        &-container {
            position: absolute;
            top: 55px;
            left: calc(100% - 124px);
            width: 100px;
            height: 100px;
        }

        &-box {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100px;
            margin-bottom: 15px;
            background-color: white;

            &-icon {
                width: 100px;
                height: auto;
                color: var(--mission-orange-100);
                font-size: 48px;
                transform: translateX(10px);
            }

            &-input {
                @include font-rubik(false, false, 400);
                position: absolute;
                width: 0.1px;
                height: 0.1px;
                opacity: 0;
                overflow: hidden;
                z-index: -1;

                & + label {
                    position: absolute;
                    display: inline-block;
                    width: 100px;
                    height: 100px;
                    font-size: 18px;
                    font-weight: 700;
                    color: var(--mission-orange-100);
                    cursor: pointer;
                }
            }

            &-preview {
                width: 100px;
                height: 100px;
                background-position: center;
                background-size: cover;
                background-repeat: no-repeat;

                &-cancel {
                    position: absolute;
                    top: -10px;
                    left: calc(100% - 10px);
                    display: inline-flex;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    border: 1px solid var(--mission-orange-100);
                    border-radius: 50%;
                    background-color: var(--white);
                    color: var(--mission-orange-100);
                    font-weight: 900;
                    cursor: pointer;

                    &-text {
                        font-size: 12px;
                        font-weight: 600;
                        transform: translateY(-0.5px);
                    }
                }
            }

            &-text {
                color: var(--blue-neutral);
                transform: translateX(5px);
            }

            &-without-image {
                @include font-rubik(18px, var(--mission-orange-100), 400);
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 100px;
                height: 100px;
            }
        }
    }

    /* LettersComposeMessageSearch */

    &-search {
        &-container {
            margin-bottom: 30px;
            font-size: 18px;
        }

        &-header-container {
            margin-bottom: 15px;
        }

        &-new {
            display: flex;
            align-items: center;
            width: 100%;
            height: 72px;
            padding-left: 24px;

            .material-icons {
                width: 40px;
                height: 40px;
                margin-right: 15px;
                font-size: 40px;
                color: var(--mission-orange-100);
            }
        }
    }
}
