@mixin font-rubik($size: false, $color: false, $weight: false) {
    font-family: Rubik, sans-serif;
    @if $size {
        font-size: $size;
    }
    @if $color {
        color: $color;
    }
    @if $weight {
        font-weight: $weight;
    }
}
