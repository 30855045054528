@import "../../../../css/fonts";

.musterChecklist {
    display: flex;
    flex-wrap: wrap;
    padding: 28px;
    margin-right: 0px;
    width: 100%;

    .checklist {
        background-color: var(--dust-100);
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        height: 100%;
        margin-bottom: 32px;
        padding: 24px;
        position: relative;
        width: 100%;
    }

    .loadingContainer {
        width: 100%;
    }
}
