.wallet-gift-cards-purchase-recipient-previous-recipients {
    margin-bottom: 24px;
    border: 1px solid var(--gray-neutral);
    border-radius: 10px;
    padding: 16px;

    &--active {
        border: 2px solid var(--blue-dark-background);
    }

    .header {
        margin-bottom: 16px;
    }

    .recipients {
        display: flex;
        overflow-x: auto;

        &--overflow {
            padding-bottom: 16px;
        }

        .recipient {
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-width: 25%;
            border: 1px solid var(--gray-neutral);
            border-radius: 10px;
            padding: 16px;
            cursor: pointer;

            &:not(:last-child) {
                margin-right: 8px;
            }

            &--active {
                border: 2px solid var(--gray-darkest);
                background-color: var(--gray-lighter);
                box-shadow: 0px 3px 11px rgba(52, 81, 104, 0.35);
            }
        }
    }

    h4 {
        display: flex;
        align-items: center;
    }
}
