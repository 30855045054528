@import "../../../../css/fonts";
@import "../../../../css/responsive";

.supportSquadList {
    display: flex;
    flex-direction: column;
    margin-bottom: 0;

    .body {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        flex: 1;
        overflow-y: auto;

        &--loading {
            overflow: hidden;
        }

        .empty {
            height: 65%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            margin: 24px 0px;

            .header {
                @include font-rubik(16px, var(--midnight-100), 600);
                margin: 26px 40px 5px 40px;
                text-align: center;
            }

            .squadIcon {
                height: 55px;
                width: 55px;
            }

            .text {
                @include font-rubik(12px, var(--gray-darker), 300);
                margin: 0 40px 0 40px;
                text-align: center;
            }
        }

        .squadList {
            display: flex;
            flex-direction: column;
            flex: 1;
            gap: 24px;
            border-radius: 4px;
            padding: 0 !important;
            background-color: var(--dust-100);
            box-shadow: none;
            width: 100%;
            height: 65%;
        }
    }

    .buttons {
        display: flex;
        gap: 16px;
        width: 100%;
        padding-top: 24px;

        @include responsive($max-width: $desktop-small-max-width) {
            flex-direction: column;
            gap: 8px;
        }

        .button {
            flex: 1;
            padding: 8px 8px;
        }
    }

    .tabs {
        display: flex;
        flex-direction: column;
        width: 100%;

        .highlight {
            position: relative;
            width: 100%;
            height: 4px;
            background-color: transparent;

            .bar {
                position: relative;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: var(--blue-darkerer);
                transition: left 0.25s ease-out;
            }
        }

        .items {
            display: flex;
            width: 100%;

            .tab {
                @include font-rubik(14px, var(--blue-darkerer), 500);
                display: flex;
                justify-content: center;
                align-items: center;
                width: 100%;
                padding: 12px 0;
            }
        }
    }
}
