@import "../../../../../../../../css/fonts";

.supportSquadSettingsModalCreateInvite {
    .form {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .body {
            display: flex;
            flex-direction: column;
            justify-content: center;
            position: relative;
            width: 100%;
            margin-bottom: 16px;

            .addInput {
                display: flex;
                align-items: center;
                gap: 8px;
                cursor: pointer;
            }

            .inputs {
                display: flex;
                flex-direction: column;
                gap: 16px;
                margin-bottom: 16px;
                padding-top: 6px;
                overflow-y: auto;

                .input {
                    width: 100%;
                }
            }
        }

        .buttons {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
}
