@import "../../css/fonts";

.card {
    background-color: var(--dust-100);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 160px;
    justify-content: space-between;
    padding: 18px 16px 16px 16px;
    width: 194px;
    cursor: pointer;
}

.card:first-of-type {
    margin-left: 0px;
}

.card:last-of-type {
    margin-right: 0px;
}
