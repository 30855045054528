@import "../../../../css/fonts";

.personModalForm {
    .form {
        display: grid;
        grid-column-gap: 24px;
        grid-template-columns: repeat(4, 1fr);

        .banner {
            grid-column: 1 / span 4;
            margin-bottom: 24px;
            cursor: pointer;
        }

        .buttons {
            grid-column: 1 / span 4;
            display: flex;
            flex-direction: column;
        }

        .header {
            margin-bottom: 16px;
        }

        .toggle {
            grid-column: 1 / span 4;
            margin: 8px 0 24px 0;
            border-bottom: 1px solid var(--gray-dark-background);
            padding-bottom: 24px;
        }

        .input {
            @include font-rubik(18px, var(--midnight-100), 700);
            display: flex;
            justify-content: flex-start !important;
            margin-bottom: 24px;

            &--firstName,
            &--state {
                grid-column: 1 / span 2;
            }

            &--lastName,
            &--zipcode {
                grid-column: 3 / span 2;
            }

            &--city,
            &--country,
            &--line1,
            &--line2,
            &--title {
                grid-column: 1 / span 4;
            }
        }

        .preview {
            grid-column: 1 / span 4;
            display: flex;
            flex-direction: column;
            margin-bottom: 16px;

            &--hidden {
                visibility: hidden;
            }
        }

        .toggle {
            grid-column: 1 / span 4;
            margin-bottom: 16px;

            &--hidden {
                visibility: hidden;
            }
        }
    }
}
